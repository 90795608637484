/**
 * Created by ivan on 11.04.18.
 */

var OrdersToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.ORDERS);

    this.enable();
};

OrdersToolbarItem.prototype = Object.create(ToolbarItem.prototype);
OrdersToolbarItem.prototype.constructor = OrdersToolbarItem;

OrdersToolbarItem.prototype.resetState = function () {
    // Harvested can be created at chat scene to accept gift and currentGame will be undefined
    var ready = Merge.currentMerge && (Map2d.currentMap.customers.findCanCook() || Map2d.currentMap.customers.findReady());

    if (ready) {
        this.mark();
    } else {
        this.unmark();
    }
};

OrdersToolbarItem.prototype.isVisible = function () {
    return Merge.currentMerge && cleverapps.gameLevel && cleverapps.gameLevel.getLevel() >= 2
        && ["main"].indexOf(cleverapps.meta.selectedLocationId()) !== -1;
};

OrdersToolbarItem.prototype.onClick = function () {
    cleverapps.focusManager.display({
        focus: "OrdersIconClicked",
        action: function (f) {
            new OrdersWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};