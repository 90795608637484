/**
 * Created by iamso on 15.11.2021
 */

var RuinsWindow = CleverappsWindow.extend({
    onWindowLoaded: function (ruins) {
        this.ruins = ruins;
        this.expeditionData = cleverapps.skins.getSlot("ruinsWindow");
        this.bundle = bundles[RuinsWindow.GetTargetBundleName()];

        this.unitIcons = [];

        var styles = cleverapps.styles.RuinsWindow;

        this._super({
            title: this.getDictionaryKey("title"),
            name: "RuinsWindow",
            content: this.createContent(),
            noBackground: styles.noBackground,
            openSound: this.bundle.urls.open_sfx
        });

        this.unitsNode = new cc.Node();
        this.window.addChild(this.unitsNode);
        this.unitsNode.setLocalZOrder(1);

        this.unitIcons.forEach(function (icon) {
            icon.replaceParentSamePlace(this.unitsNode);
        }, this);
    },

    getPerson: function () {
        var expeditionData = cleverapps.skins.getSlot("ruinsWindow");
        return expeditionData && expeditionData.person || cleverapps.persons.choose("miner", "dwarf");
    },

    getDictionaryKey: function (key) {
        if (this.expeditionData && this.expeditionData.dictionaryKey) {
            return this.expeditionData.dictionaryKey + key;
        }
        return "RuinsWindow." + key;
    },

    createContent: function () {
        var cta = cleverapps.UI.generateOnlyText(this.getDictionaryKey("cta"), cleverapps.styles.FONTS.RUINS_CTA);

        return new cleverapps.Layout([cta, this.createTimer(), this.createTiers()], {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.RuinsWindow.ctaMargin
        });
    },

    createTimer: function () {
        var styles = cleverapps.styles.RuinsWindow.timer;

        return new cleverapps.CountDownView(new cleverapps.CountDown(this.ruins.getTimeLeft()), {
            font: cleverapps.styles.FONTS.RUINS_TIMER,
            background: {
                frame: this.bundle.frames.timer_substrate,
                width: styles.width,
                height: styles.height
            },
            icon: this.bundle.frames.timer_icon
        });
    },

    createTiers: function () {
        var cheap = this.createTier("cheap");
        var costly = this.createTier("costly");

        return new cleverapps.Layout([cheap, costly], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.RuinsWindow.platesMargin
        });
    },

    createTier: function (tierName) {
        var styles = cleverapps.styles.RuinsWindow.plate;

        var plate = new cc.Node();
        plate.setAnchorPoint(0.5, 0.5);
        plate.setContentSize2(styles);

        var bg = cleverapps.UI.createScale9Sprite(tierName === "cheap" ? this.bundle.frames.tier1_bg : this.bundle.frames.tier2_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize(plate.getContentSize());
        plate.addChild(bg);
        bg.setPositionRound(plate.width / 2, plate.height / 2);

        if (this.bundle.frames.tier_title) {
            var titleBg = cleverapps.UI.createScale9Sprite(this.bundle.frames.tier_title, cleverapps.UI.Scale9Rect.TwoPixelXY);
            titleBg.setContentSize(styles.titleBg);
            plate.addChild(titleBg);
            titleBg.setPositionRound(styles.titleBg);
        }

        var title = cleverapps.UI.generateOnlyText(this.getDictionaryKey("plateName." + tierName), cleverapps.styles.FONTS.RUINS_PLATE_TITLE);
        title.fitTo(styles.title.width);
        plate.addChild(title);
        title.setPositionRound(styles.title);

        var rewardsNode = this.createPlateRewards(tierName);
        plate.addChild(rewardsNode);
        rewardsNode.setPositionRound(styles.units);

        var button = this.button = this.createPlateButton(tierName);
        plate.addChild(button);
        button.setPositionRound(styles.button);

        var tool = new cc.Node();
        tool.addChild(new cc.Sprite(tierName === "cheap" ? this.bundle.frames.tier1_tool : this.bundle.frames.tier2_tool));
        cleverapps.UI.setDimensions(tool, {
            height: styles.toolHeight
        });

        return new cleverapps.Layout([tool, plate], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.toolMargin
        });
    },

    createPlateRewards: function (tierName) {
        var rewards = this.ruins.getRelevantTier(tierName).rewards;
        var rows = [], inRow = 2;
        while (rewards.length > rows.length * inRow) {
            var rowItems = rewards.slice(rows.length * inRow, rows.length * inRow + 2).map(function (unit) {
                return this.createUnitReward(unit);
            }.bind(this));

            rows.push(new cleverapps.Layout(rowItems, {
                direction: cleverapps.UI.HORIZONTAL,
                margin: cleverapps.styles.RuinsWindow.plate.units.marginX
            }));
        }

        return new cleverapps.Layout(rows, {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.RuinsWindow.plate.units.marginY
        });
    },

    createUnitReward: function (unit) {
        var styles = cleverapps.styles.RuinsWindow.unit;

        var unitNode = new cc.Node();
        unitNode.setAnchorPoint(0.5, 0.5);
        unitNode.setContentSize2(styles);

        var icon = UnitView.getUnitImage(unit);
        cleverapps.UI.fitToBox(icon, styles.icon);
        unitNode.addChild(icon);
        icon.setPositionRound(styles.icon);
        this.unitIcons.push(icon);

        var amount = cleverapps.UI.generateImageText("x" + unit.amount, cleverapps.styles.FONTS.RUINS_UNITREWARD_AMOUNT);
        unitNode.addChild(amount);
        amount.setPositionRound(styles.amount);

        return unitNode;
    },

    createPlateButton: function (tierName) {
        var tierData = this.ruins.getRelevantTier(tierName);

        var styles = cleverapps.styles.RuinsWindow;

        return new cleverapps.UI.Button({
            type: tierData.soft ? cleverapps.styles.UI.Button.Images.button_green : cleverapps.styles.UI.Button.Images.button_blue,
            text: tierData.soft ? "@@" + tierData.soft : "$$" + tierData.hard,
            width: styles.plate.button.width,
            height: styles.plate.button.height,
            onClicked: function () {
                if (this.ruins.buy(tierName)) {
                    this.close();
                }
            }.bind(this)
        });
    },

    listBundles: function () {
        return [RuinsWindow.GetTargetBundleName()];
    }
});

RuinsWindow.GetTargetBundleName = function () {
    var expeditionData = cleverapps.skins.getSlot("ruinsWindow");
    if (expeditionData && bundles[expeditionData.bundle]) {
        return expeditionData.bundle;
    }
    return "ruinswindow";
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    RUINS_CTA: {
        size: 45,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    RUINS_PLATE_TITLE: {
        size: 55,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },
    RUINS_UNITREWARD_AMOUNT: {
        name: "nostroke",
        size: 45,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },
    RUINS_TIMER: {
        size: 35,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.RuinsWindow = {
    platesMargin: 20,
    ctaMargin: 10,

    plate: {
        width: 380,
        height: 470,
        toolMargin: 20,
        toolHeight: 180,

        units: {
            marginX: 15,
            marginY: 5,
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: -140 }
        },

        title: {
            width: 320,
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: -25 }
        },

        button: {
            width: 250,
            height: 100,
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 40 }
        }
    },

    unit: {
        width: 160,
        height: 85,

        icon: {
            width: 100,
            height: 85,
            maxScale: 1.3,
            x: { align: "center", dx: -40 },
            y: { align: "center", dy: 0 }
        },

        amount: {
            x: { align: "left", dx: 85 },
            y: { align: "center", dy: 5 }
        }
    },

    timer: {
        width: 275,
        height: 55
    },

    window: {}
};
