/**
 * Created by Vladislav on 17.10.2024.
 */

var Map2dEdges = function (map) {
    this.map = map;

    this.edges = {};

    this.terrainsAreas = {};
    map.map(map.layers[Map2d.LAYER_GROUND], function (cell, x, y) {
        this.setTerrain(x, y);
    }.bind(this));

    Object.keys(this.terrainsAreas).forEach(function (code) {
        BorderRenderer.place(
            this.terrainsAreas[code],
            this.edgesPlacement.bind(this)
        );
    }.bind(this));
};

Map2dEdges.prototype.setTerrain = function (x, y) {
    var terrainCode = this.map.getGroundTerrainCode(x, y);
    if (terrainCode) {
        this.terrainsAreas[terrainCode] = this.terrainsAreas[terrainCode] || [];
        this.terrainsAreas[terrainCode][y] = this.terrainsAreas[terrainCode][y] || [];
        this.terrainsAreas[terrainCode][y][x] = terrainCode;
    }
};

Map2dEdges.prototype.edgesPlacement = function (borders, cell) {
    var map = Map2d.currentMap;
    borders.forEach(function (border) {
        var x = border.borderCell.x;
        var y = border.borderCell.y;

        if (!map.isGround(x, y) && !map.isImpassableGround(x, y)) {
            return;
        }

        if (map.getGroundTerrainCode(cell.x, cell.y) === Map2d.TERRAIN_GREEN && map.getGroundTerrainCode(x, y)) {
            return;
        }

        var name = "edge_" + border.name;

        var amount = 0;
        while (map.getTerrainFrames(cell.x, cell.y)[name + "_" + amount]) {
            amount++;
        }

        var result;

        if (amount) {
            var index = (x + y) % amount;
            result = map.getTerrainFrameData(name + "_" + index, cell.x, cell.y);
        } else {
            result = map.getTerrainFrameData(name, cell.x, cell.y);
        }

        if (!result.res) {
            return;
        }

        result.type = border.name;

        if (!this.edges[y]) {
            this.edges[y] = {};
        }

        this.edges[y][x] = this.edges[y][x] || [];
        this.edges[y][x].push(result);

        this.filterLockedTypes(x, y);
    }.bind(this));
};

Map2dEdges.prototype.filterLockedTypes = function (x, y) {
    var locked = [];
    this.edges[y][x].forEach(function (item) {
        if (Map2dEdges.locks[item.type]) {
            locked = locked.concat(Map2dEdges.locks[item.type]);
        }
    });

    this.edges[y][x] = this.edges[y][x].filter(function (item) {
        return !locked.includes(item.type);
    });
};

Map2dEdges.prototype.getEdges = function (x, y) {
    return this.edges[y] && this.edges[y][x];
};

Map2dEdges.prototype.onEdit = function (x, y) {
    Object.keys(this.terrainsAreas).forEach(function (code) {
        if (this.terrainsAreas[code][y] && this.terrainsAreas[code][y][x]) {
            this.terrainsAreas[code][y][x] = undefined;
        }
    }.bind(this));

    this.setTerrain(x, y);

    var neighbours = BorderRenderer.getNeighbours(x, y).concat({ x: x, y: y });

    neighbours.forEach(function (neighbour) {
        if (this.edges[neighbour.y]) {
            delete this.edges[neighbour.y][neighbour.x];
        }
    }.bind(this));
    var terrainCode = this.map.getGroundTerrainCode(x, y);
    if (terrainCode) {
        BorderRenderer.place(
            this.terrainsAreas[terrainCode],
            this.edgesPlacement.bind(this),
            { part: neighbours }
        );
    }
};

Map2dEdges.prototype.addViews = function (x, y) {
    var edges = this.getEdges(x, y);

    if (!edges) {
        return;
    }

    edges.forEach(function (edge) {
        var view = edge.getView && edge.getView();

        if (!view) {
            view = ViewReader.createTopYAnchorElement(edge);
            edge.getView = view.createListener(function () {
                return view;
            });

            this.map.getMapView().addTile(Map2d.LAYER_GROUND, x, y, view);
        } else {
            view.invisible = false;
            cc.renderer.childrenOrderDirty = true;
        }
    }, this);
};

Map2dEdges.locks = {
    inner_up_left: ["up", "right"],
    inner_up_right: ["up", "left"],
    inner_down_left: ["down", "right"],
    inner_down_right: ["down", "left"]
};
