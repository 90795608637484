/**
 * Created by mac on 12/23/20
 */

var CollectibleView = function (collectible, unitView) {
    this.collectible = collectible;
    this.unitView = unitView;
};

CollectibleView.prototype.feedAnimation = function (targetView, callback) {
    var styles = cleverapps.styles.CollectibleView.feedAnimation;
    var movingNode = cleverapps.scenes.getMovingNode(targetView);

    var localPosition = cc.p(0.3 * targetView.width, 0.6 * targetView.height);
    var targetPosition = movingNode.convertToNodeSpace(targetView.convertToWorldSpace(localPosition));

    var distance = cc.pDistance(targetView, this.unitView);
    var duration = Math.max(0.5, distance / styles.speed);

    this.feedingAction = this.unitView.runAction(
        new cc.Sequence(
            new cc.ReplaceParent(movingNode),
            new cc.Spawn(
                new cc.JumpAnimation(
                    duration,
                    this.unitView,
                    cc.pSub(targetPosition, this.unitView),
                    styles.jumpHeight
                ),
                new cc.ScaleTo(duration, 0.6).easing(cc.easeBackIn())
            ),
            new cc.RemoveSelf()
        )
    ).setFinalize(callback);
};

CollectibleView.prototype.clearAnimations = function () {
    if (this.feedingAction && !this.feedingAction.isDone()) {
        this.unitView.stopAction(this.feedingAction);
        delete this.feedingAction;
    }
};

CollectibleView.prototype.collectionAnimation = function (index, callback) {
    var target;
    var sound;

    switch (this.collectible.unit.getType()) {
        case "fruit":
        case "herofood":
            target = "harvested";
            sound = bundles.fruit.urls.fruit_fly_finish_effect;
            break;
    }

    if (target) {
        target = cleverapps.aims.getTarget(target);
    }

    var actions = [];

    actions.push(new cc.Show());
    actions.push(StandartAnimations.animateCollect(this.unitView, target, {
        path: true,
        adjustToTarget: true,
        collectEffect: true,
        sound: sound
    }));

    actions.push(new cc.Hide());
    actions.push(new cc.CallFunc(callback));
    actions.push(new cc.RemoveSelf());

    this.unitView.stopAllActions();
    this.unitView.runAction(new cc.Sequence(actions));
};

cleverapps.styles.CollectibleView = {
    feedAnimation: {
        speed: 1500,
        jumpHeight: 240
    }
};
