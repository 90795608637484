/**
 * Created by evgeny on 31.07.2024
 */

var ThrowawayView = function (throwaway, unitView) {
    this.throwaway = throwaway;
    this.unitView = unitView;

    this.throwaway.onAnimateDestroy = this.animateDestroy.bind(this);
};

ThrowawayView.prototype.animateDestroy = function (callback) {
    var sprite = this.unitView.sprite;

    if (sprite && sprite.hasAnimation("clean") && sprite.hasAnimation("hide")) {
        new ActionPlayer([
            function (f) {
                sprite.setAnimation(0, "clean", false);
                sprite.setCompleteListener(f);
            },

            function (f) {
                sprite.setAnimation(0, "hide", false);
                sprite.setCompleteListener(f);
            }
        ]).play(callback);
    } else if (this.throwaway.type === Throwaway.TYPE_SWEEPING) {
        this.sweepingAnimation(callback);
        sprite.runAction(StandartAnimations.disappear(sprite, {
            dust: true
        }));
    } else if (this.throwaway.type === Throwaway.TYPE_UPGRADE) {
        sprite.replaceParentSamePlace(Map2d.currentMap.getMapView().animations);
        sprite.runAction(StandartAnimations.upgrade(sprite, undefined, {
            cloud: true
        })).setFinalize(callback);
    } else {
        sprite.runAction(StandartAnimations.disappear(sprite, {
            dust: true
        })).setFinalize(callback);
    }
};

ThrowawayView.prototype.sweepingAnimation = function (callback) {
    var sweeping = new cleverapps.Spine(bundles.homefix_animations.jsons.sweep_json);
    sweeping.setPositionRound({ align: "left" }, { align: "bottom" });
    sweeping.setAnimation(0, "animation", false);
    sweeping.setCompleteListener(callback);
    this.unitView.addChild(sweeping);
};
