/**
 * Created by Vladislav on 07.03.2025.
 */

cleverapps.InitByFeature["clans"] = function () {
    cleverapps.userClan = new UserClan();
    cleverapps.clans = new Clans();
    cleverapps.clanMessages = new ClanMessages();

    cleverapps.clanCupInner = new Cup(CupsConfig.TYPE_CLAN_INNER);
    cleverapps.clanCup = new Cup(CupsConfig.TYPE_CLAN, cleverapps.clanCupInner);
};