/**
 * Created by andrey on 15.12.2020.
 */

var Map2dEditorView = EditorViewBase.extend({
    ctor: function (editor) {
        this._super(editor);

        var map = this.map = new EditorMap2dView({
            width: this.width,
            height: this.height
        });
        this.addChild(map);

        if (cleverapps.git) {
            var gitButtons = new GitButtonsView();
            this.addChild(gitButtons);
        }

        var layersSwitcherView = new LayersSwitcherView(Map2dEditor.LAYERS);
        this.addChild(layersSwitcherView);
        layersSwitcherView.setPositionRound(cleverapps.styles.Map2dEditorView.layers);

        var fogsToggle = new cleverapps.UI.CheckBox({
            label: {
                text: "fogs",
                font: cleverapps.styles.FONTS.EDITOR_BLUE_TEXT
            },
            onChange: function (state) {
                Editor.currentEditor.map.fogs.toggleAlwaysVisible(state);
            }
        });
        this.addChild(fogsToggle);
        fogsToggle.setPositionRound(cleverapps.styles.Map2dEditorView.fogsToggle);
    }
});

cleverapps.styles.Map2dEditorView = {
    layers: {
        x: { align: "left", dx: 60 },
        y: { align: "top", dy: -180 }
    },
    fogsToggle: {
        x: { align: "left", dx: 65 },
        y: { align: "top", dy: -380 }
    }
};

EditorView = Map2dEditorView;
