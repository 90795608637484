/**
 * Created by spepa on 03.03.2023
 */

Landmarks.WORLDS.easter = {
    fog: "fog2",
    force: Forces.LANDMARK_DONOR_SLOT1,
    landmarks: [
        {
            code: "landmark",
            stage: 24,
            targetExpedition: "main",
            donors: "eaunicorn"
        }
    ]
};

Mines.easource = function (level, unit) {
    if (unit.stage === 1) {
        return [
            {
                amount: 5,
                units: [
                    { code: unit.getResource(), stage: 1 }
                ]
            },
            {
                amount: 1,
                probabilities: [0.1],
                units: [
                    { code: "coins", stage: 1 }
                ]
            }
        ];
    }
    if (["easter_eggssource1", "easter_eggssource"].includes(Merge.currentMerge.tutorial.runningTutorialId)) {
        return [
            {
                amount: 2,
                units: [
                    { code: unit.getResource(), stage: 0 }
                ]
            },
            {
                amount: 2,
                units: [
                    { code: unit.getResource(), stage: 1 }
                ]
            }
        ];
    }
    return [
        {
            amount: 4,
            units: [
                { code: unit.getResource(), stage: 0 },
                { code: unit.getResource(), stage: 1 }
            ]
        }
    ];
};

Mines.easourcegenerator = function () {
    return { openedType: "easource" };
};

CustomerRecipes.easter = {
    eaprince_0: [
        {
            ingredients: [
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eaorder",
                    stage: 0,
                    amount: 3
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eaorder",
                    stage: 0,
                    amount: 5
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eaorder",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "eaorder",
                    stage: 1,
                    amount: 3
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eaorder",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "eaorder",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "eaorder",
                    stage: 1,
                    amount: 4
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eaorder",
                    stage: 0,
                    amount: 5
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "earesource",
                    stage: 3,
                    amount: 2
                },
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eaorder",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "eaorder",
                    stage: 1,
                    amount: 3
                }
            ]
        }

    ],
    eathrone_0: [
        {
            ingredients: [
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eahero",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "eahero",
                    stage: 1,
                    amount: 2
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eahero",
                    stage: 0,
                    amount: 1
                },
                {
                    type: "eahero",
                    stage: 1,
                    amount: 3
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eahero",
                    stage: 0,
                    amount: 1
                },
                {
                    type: "eahero",
                    stage: 1,
                    amount: 6
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 2
                }
            ],
            order: [
                {
                    type: "eahero",
                    stage: 0,
                    amount: 1
                },
                {
                    type: "eahero",
                    stage: 1,
                    amount: 6
                }
            ]
        }
    ],
    eacustomerbridge0a_0: [
        {
            ingredients: [
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 2
                }
            ],
            order: [
                {
                    code: "energy",
                    stage: 0,
                    amount: 1
                },
                {
                    code: "crystal",
                    stage: 0,
                    amount: 1
                }
            ]
        }
    ],
    eacustomerbridge0b_0: [
        {
            ingredients: [
                {
                    code: "eaproduct0",
                    stage: 0,
                    amount: 3
                }
            ],
            order: [
                {
                    code: "energy",
                    stage: 1,
                    amount: 1
                },
                {
                    code: "crystal",
                    stage: 0,
                    amount: 1
                }
            ]
        }
    ],
    eacustomermain0a_0: [
        {
            ingredients: [
                {
                    code: "eaproduct0",
                    stage: 0,
                    amount: 3
                }
            ],
            order: [
                {
                    code: "energy",
                    stage: 1,
                    amount: 1
                },
                {
                    code: "coins",
                    stage: 3,
                    amount: 1
                }
            ]
        }
    ],
    eacustomermain0b_0: [
        {
            ingredients: [
                {
                    code: "eaproduct0",
                    stage: 0,
                    amount: 3
                }
            ],
            order: [
                {
                    code: "energy",
                    stage: 1,
                    amount: 1
                },
                {
                    code: "coins",
                    stage: 2,
                    amount: 1
                }
            ]
        }
    ],
    eaproducer0_0: [
        {
            onlyWhenNeeded: true,
            ingredients: [
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 2
                }
            ],
            order: [
                {
                    code: "eaproduct0",
                    stage: 0,
                    amount: 1
                }
            ]
        }
    ]
};

cleverapps.styles.easter = {
    miningAnimation: {
        x: { align: "center", dx: 0, leftDx: 0 },
        y: { align: "center", dy: 0 }
    },
    offerIconYOffset: {
        hustlemerge: -75,
        mergecraft: -40,
        fairy: -25
    },
    fogAsColorForUnits: {
        color: new cc.Color(0, 150, 255, 255),
        opacity: 120
    },
    fogBlockerLock: {
        width: 100,
        height: 100,
        margin: 25,
        content: {
            width: 68,
            height: 68
        },
        position: {
            x: { align: "center" },
            y: { align: "center", dy: 50 }
        },
        text: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        icon: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        cloud: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -39 }
        },
        lock: {
            x: { align: "center" },
            y: { align: "center" }
        }
    }
};