/**
 * Created by andrey on 01.10.2024
 */

Map2dFences.prototype.onEdit = function (x, y, regionName) {
var neighbours = BorderRenderer.getNeighbours(x, y).concat({x: x, y: y});
    neighbours.forEach(function (neighbour) {
        this.clearFences(neighbour.x, neighbour.y, regionName);
    }.bind(this));

    BorderRenderer.place(
        Map2d.currentMap.regions[regionName].positions,
        this.imagePlacement.bind(this),
        {
            regionName: regionName,
            part: neighbours
        }
    );

    neighbours.forEach(function (neighbour) {
        Map2d.currentMap.showTiles(neighbour.x, neighbour.y);
    });
};

Map2dFences.prototype.clearFences = function (x, y, regionName) {
    var fences = this.fences[regionName]
        && this.fences[regionName][x]
        && this.fences[regionName][x][y];

    if (fences) {
        Map2d.currentMap.removeTiles(x, y);
        fences.forEach(function (fence) {
            Map2d.currentMap.decorators.removeDecorator(fence.x, fence.y, fence);
        });
        delete this.fences[regionName][x][y];
    }
};
