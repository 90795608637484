/**
 * Created by mac on 10/21/22
 */

var ExpeditionPage = function (locationId) {
    Object.assign(this, MergeLogic.findPageById(locationId));

    this.slot = this.slot || Meta.SLOT_MAIN;
    this.level = this.level || 0;

    this.attentionEvent = cleverapps.dataLoader.load(DataLoader.TYPES.TRAVEL_BOOK_ATTENTION + this.id) || false;
};

ExpeditionPage.TYPE_SKIP = 0;
ExpeditionPage.TYPE_ACTIVE = 1;
ExpeditionPage.TYPE_UPCOMING = 2;

ExpeditionPage.prototype.isMain = function () {
    return this.id === "main";
};

ExpeditionPage.prototype.getStartTime = function () {
    return cleverapps.meta.logic.getLocationStartTime(this.id);
};

ExpeditionPage.prototype.getTimeLeft = function () {
    return cleverapps.meta.logic.getLocationTimeLeft(this.id);
};

ExpeditionPage.prototype.updateState = function () {
    var prevState = this.state;
    this.state = this.calcState();

    if (this.isPermanent() && this.isActive() && prevState !== this.state) {
        var stored = Merge3Adapter.LoadInfo(this.slot);
        if (!stored || Object.keys(stored).length === 0) {
            cleverapps.travelBook.startNewExpedition(this);
        }
    }

    this.title = "TravelBook.title." + this.id;
    if (this.isUpcoming()) {
        this.title = "TravelBook.soon";
    }

    this.forceText = "TravelBookForce." + this.id;

    this.events = this.listEvents();

    if (this.events[0]) {
        this.message = typeof this.events[0].message === "function" ? this.events[0].message(this) : this.events[0].message;
        var events = cleverapps.dataLoader.load(DataLoader.TYPES.TRAVEL_BOOK_EVENTS + this.id) || {};
        events[this.events[0].type] = undefined;
        cleverapps.dataLoader.save(DataLoader.TYPES.TRAVEL_BOOK_EVENTS + this.id, events);
    } else {
        this.message = undefined;
    }

    if (this.isCompleted()) {
        this.setAttentionEvent(ExpeditionPage.EVENT_FINISH);
    }

    this.attention = !this.isCurrent() && this.events.find(function (event) {
        return event.type === this.attentionEvent;
    }, this);

    this.updateSideBar();
};

ExpeditionPage.prototype.resetEvents = function () {
    cleverapps.dataLoader.save(DataLoader.TYPES.TRAVEL_BOOK_EVENTS + this.id, {});
};

ExpeditionPage.prototype.setNew = function (isNew) {
    this.isNew = isNew;
};

ExpeditionPage.prototype.setAttentionEvent = function (attentionEvent) {
    if (this.attentionEvent === attentionEvent) {
        return;
    }

    this.attentionEvent = attentionEvent;

    cleverapps.dataLoader.save(DataLoader.TYPES.TRAVEL_BOOK_ATTENTION + this.id, attentionEvent);
};

ExpeditionPage.prototype.planEvent = function (type, time) {
    var events = cleverapps.dataLoader.load(DataLoader.TYPES.TRAVEL_BOOK_EVENTS + this.id) || {};

    events[type] = {
        time: time
    };

    cleverapps.dataLoader.save(DataLoader.TYPES.TRAVEL_BOOK_EVENTS + this.id, events);
};

ExpeditionPage.prototype.listEvents = function () {
    if (cleverapps.config.type !== "merge") {
        return [];
    }

    if (!this.isActive()) {
        return [];
    }

    var events = cleverapps.dataLoader.load(DataLoader.TYPES.TRAVEL_BOOK_EVENTS + this.id) || {};

    return ExpeditionPage.EVENTS.filter(function (event) {
        if (event.filter) {
            return event.filter(this);
        }

        return events[event.type] && events[event.type].time && events[event.type].time < Date.now();
    }, this);
};

ExpeditionPage.prototype.isPassed = function () {
    return cleverapps.meta.logic.isLocationPassed(this.id);
};

ExpeditionPage.prototype.calcState = function () {
    if (this.isMain()) {
        return ExpeditionPage.TYPE_ACTIVE;
    }

    if (cleverapps.meta.logic.listActiveLocations().indexOf(this.id) !== -1) {
        return ExpeditionPage.TYPE_ACTIVE;
    }

    if (this.isPermanent()) {
        return ExpeditionPage.TYPE_UPCOMING;
    }

    if (this.isPassed()) {
        return ExpeditionPage.TYPE_SKIP;
    }

    if (!cleverapps.user.checkAvailable(this.available)) {
        return ExpeditionPage.TYPE_SKIP;
    }

    var nextEvent = cleverapps.meta.logic.getNextLocationFeatureEvent(this.id);
    if (!nextEvent || nextEvent.getTimeBeforeStart() > cleverapps.parseInterval("3 days")) {
        return ExpeditionPage.TYPE_SKIP;
    }

    return ExpeditionPage.TYPE_UPCOMING;
};

ExpeditionPage.prototype.isActive = function () {
    return this.state === ExpeditionPage.TYPE_ACTIVE;
};

ExpeditionPage.prototype.isUpcoming = function () {
    return this.state === ExpeditionPage.TYPE_UPCOMING;
};

ExpeditionPage.prototype.isSeasonal = function () {
    return Boolean(this.seasonal);
};

ExpeditionPage.prototype.isPermanent = function () {
    return Boolean(this.permanent);
};

ExpeditionPage.prototype.isCompleted = function () {
    return cleverapps.meta.logic.isLocationCompleted(this.id);
};

ExpeditionPage.prototype.isCurrent = function () {
    return cleverapps.meta.selectedLocationId() === this.id;
};

ExpeditionPage.prototype.withEnergy = function () {
    return this.energy;
};

ExpeditionPage.prototype.withWands = function () {
    return this.wands;
};

ExpeditionPage.prototype.withWorkers = function () {
    return this.workers;
};

ExpeditionPage.prototype.withToolbarMagnet = function () {
    return this.magnet;
};

ExpeditionPage.prototype.withToolbarDynamite = function () {
    return this.dynamite;
};

ExpeditionPage.prototype.getButtonMsg = function () {
    if (this.isUpcoming()) {
        if (this.isPermanent() && this.levelsUntil() > 0) {
            return Messages.get("UnlockOnLevel", { level: Math.floor(this.available.level + 1) });
        }
        return "Soon";
    }
    return "Go";
};

ExpeditionPage.prototype.getTooltipMsg = function () {
    if (this.isPermanent() && this.levelsUntil() > 0) {
        return Messages.get("UnlockOnLevel", { level: Math.floor(this.available.level + 1) });
    }
    return Messages.get("TravelBook.soonTooltip");
};

ExpeditionPage.prototype.levelsUntil = function () {
    return Math.floor(this.available.level) - Math.floor(cleverapps.user.getFloatLevel());
};

ExpeditionPage.prototype.updateSideBar = function () {
    var icon = cleverapps.sideBar.findExpeditionIcon(this.id);
    var isActive = !this.isMain() && this.isActive() && cleverapps.meta.listActiveLocations().indexOf(this.id) !== -1;

    if (icon) {
        if (isActive) {
            cleverapps.sideBar.resetByClassName(ExpeditionIcon);
        } else {
            cleverapps.sideBar.removeTemporaryIcon(icon);
        }
    } else if (isActive) {
        cleverapps.sideBar.addTemporaryIcon(new ExpeditionIcon(this.id));
    }
};

ExpeditionPage.EVENT_START = "start";
ExpeditionPage.EVENT_GOALS_DONE = "done";
ExpeditionPage.EVENT_FINISH = "finish";
ExpeditionPage.EVENT_ENERGY = "energy";
ExpeditionPage.EVENT_KEYS = "keys";
ExpeditionPage.EVENT_BUILT = "built";

ExpeditionPage.EVENTS = [
    {
        type: ExpeditionPage.EVENT_START,
        message: {
            text: "TravelBook.message.welcome"
        },
        filter: function (page) {
            return page.isNew;
        }
    },
    {
        type: ExpeditionPage.EVENT_FINISH,
        message: function () {
            return {
                text: "TravelBook.message.finished",
                icon: bundles.travel_book.frames.icon_finish
            };
        },
        filter: function (page) {
            return page.isCompleted();
        }
    },
    {
        type: ExpeditionPage.EVENT_GOALS_DONE,
        message: {
            text: "TravelBook.message.done"
        },
        filter: function (page) {
            if (page.id === "adventure") {
                return Merge.currentMerge && Merge.currentMerge.landmarks.isLandmarkGathered(Landmarks.WORLDS.adventure.landmarks[0]);
            }
        }
    },
    {
        type: ExpeditionPage.EVENT_BUILT,
        message: function () {
            return {
                text: "TravelBook.message.built",
                icon: bundles.travel_book.frames.icon_built
            };
        }
    },
    {
        type: ExpeditionPage.EVENT_KEYS,
        message: function (page) {
            return {
                icon: bundles.travel_book.frames["icon_key_" + page.id],
                text: "TravelBook.message.keys." + page.id
            };
        }
    },
    {
        type: ExpeditionPage.EVENT_ENERGY,
        message: function (page) {
            return {
                icon: page.isMain() ? bundles.travel_book.frames.icon_energy_main : bundles.travel_book.frames.icon_energy_expedition,
                text: "TravelBook.message.energy"
            };
        },
        filter: function (page) {
            return page.withEnergy() && LivesHelper.GetInstance(page.slot).isFull();
        }
    }
];
