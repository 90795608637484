/**
 * Created by Denis Kuzin on 21 february 2022
 */

var ClansInfoRow = ClansRow.extend({
    createScore: function () {
        var styles = cleverapps.styles.ClansInfoRow;

        var levelBg = new cc.Sprite(bundles.clan_table.frames.level_background);

        var levelAmount = cleverapps.UI.generateImageText(this.row.data.level, cleverapps.styles.FONTS.MENUBAR_LEVEL_TEXT);
        levelAmount.fitTo(styles.text.width);
        levelAmount.setPositionRound(styles.text);
        levelBg.addChild(levelAmount);

        var crown = new cleverapps.Spine(bundles.clan_table.jsons.level_crown);
        crown.setPositionRound(styles.crown);
        crown.setAnimation(0, "idle", true);
        levelBg.addChild(crown);

        cleverapps.UI.fitToBox(levelBg, {
            height: styles.bg.height
        });

        return levelBg;
    }
});

cleverapps.styles.ClansInfoRow = {
    text: {
        x: { align: "center", dx: 1 },
        y: { align: "center", dy: 10 },
        width: 85
    },

    bg: {
        height: 90
    },

    crown: {
        x: { align: "left", dx: -20 },
        y: { align: "top", dy: 20 }
    }
};