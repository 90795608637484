/**
 * Created by razial on 11.03.2025.
 */

var HomefixMap2dAdapter = function (homefix) {
    this.homefix = homefix;
};

HomefixMap2dAdapter.prototype.load = function (key) {
    var cell = Map2d.ParseCellKey(key);
    if (cell) {
        return this.loadUnit(cell.x, cell.y);
    }
    switch (key) {
        case "fogs": return this.loadFogs();
    }
};

HomefixMap2dAdapter.prototype.save = function (key, info) {
    var cell = Map2d.ParseCellKey(key);
    if (cell) {
        this.saveUnit(info);
        return;
    }

    switch (key) {
        case "fogs": this.saveFogs(info); break;
    }
};

HomefixMap2dAdapter.prototype.remove = function (key) {
    var cell = Map2d.ParseCellKey(key);
    if (cell) {
        this.removeUnit(cell.x, cell.y);
    }
};

HomefixMap2dAdapter.prototype.loadUnit = function (x, y) {
    var fakeUnit = this.homefix.map.fogs.getFakeUnit(x, y);

    if (fakeUnit) {
        var saveUnit = this.homefix.storedUnits[fakeUnit.code];
        if (saveUnit) {
            return Object.assign({ code: fakeUnit.code }, saveUnit);
        } if (Families[fakeUnit.code || fakeUnit.head.code].units[0].invisible) {
            return fakeUnit;
        }
    }
};

HomefixMap2dAdapter.prototype.saveUnit = function (info) {
    var data = {};
    data.stage = info.stage;

    if (info.choice !== undefined) {
        data.choice = info.choice;
    }

    this.homefix.storedUnits[info.code] = data;
    this.homefix.storeSave();
};

HomefixMap2dAdapter.prototype.removeUnit = function (x, y) {
    var fakeUnit = this.homefix.map.fogs.getFakeUnit(x, y);

    if (fakeUnit) {
        var saveUnit = this.homefix.storedUnits[fakeUnit.code];
        if (saveUnit) {
            delete this.homefix.storedUnits[fakeUnit.code];
            this.homefix.storeSave();
        }
    }
};

HomefixMap2dAdapter.prototype.loadFogs = function () {
    return this.homefix.storedFogs || [];
};

HomefixMap2dAdapter.prototype.saveFogs = function (info) {
    this.homefix.storedFogs = info;
    this.homefix.storeSave();
};
