/**
 * Created by mac on 12/8/20
 */

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    LEVELUPWINDOW_VALUE_TEXT: {
        size: 220,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: {
            color: new cc.Color(0, 0, 0, 50),
            size: 2
        },
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    PACKWINDOW_TEXT: {
        name: "default",
        size: 60,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.WINDOW_TITLE_STROKE
    },

    ENERGY_LOTTERY_VALUE_TEXT: {
        size: 42,
        color: new cc.Color(255, 239, 57, 255),
        stroke: cleverapps.styles.DECORATORS.ENERGY_LOTTERY_STROKE
    },

    LEVELUPWINDOW_REWARD_TEXT: {
        name: "big_digits",
        size: 74,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    UPGRADABLE_LEVEL_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR,
        stroke: undefined,
        shadow: undefined
    },

    INFO_TIMER_TEXT: {
        name: "nostroke",
        size: 28,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    INFO_BAR_TEXT: {
        size: 28,
        color: cleverapps.styles.COLORS.WHITE
    },

    SNAILFEAST_MISSION_MAIN_TITLE_TEXT: {
        size: 78,
        color: new cc.Color(255, 255, 255, 255),
        name: "nostroke"
    },

    SINGLE_PRODUCT_OFFER_REWARD: {
        name: "big_digits",
        size: 80,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    PASS_REWARD_TEXT: {
        size: 56
    },

    PASS_PROGRESS_POINT: {
        name: "nostroke",
        size: 45,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    ENERGY_OFFER: {
        size: 80,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    PASS_TICKET_TEXT: {
        size: 28,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    },

    PASS_TICKET_SMALL_TEXT: {
        size: 28,
        color: cleverapps.styles.COLORS.COLOR_WINDOW_TEXT
    }
});

cleverapps.overrideStyles(cleverapps.styles.FogTileView, {
    moneyFogUnits: {
        "worker_4": {
            color: new cc.Color(73, 69, 52, 255),
            opacity: 63
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SideBarSlot, {
    margin: 25,
    padding: [
        {
            top: 136
        },
        {
            top: 186
        },
        {
            top: 196
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.UpMenuContainer, {
    padding: [{
        left: 0,
        right: -5
    }, {
        left: 0,
        right: -5
    }, {
        left: 0,
        right: -5
    }]
});

cleverapps.overrideStyles(cleverapps.styles.LevelUpWindow["default"], {
    width: 750,
    height: 1000,

    animation: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -110 }
    },

    title: undefined,

    value: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 25 },

        delay: 0.6,
        duration: 0.5
    },

    rewards: {
        x: { align: "center", dx: 10 },
        y: { align: "bottom", dy: 0 },
        direction: cleverapps.UI.HORIZONTAL,
        margin: 20,
        padding: {
            x: 90
        },

        delay: 1,
        duration: 0.4,
        small: true
    },

    rewardsBackground: {
        dy: -3,

        minWidth: 100,

        delay: 0.5
    }
});

cleverapps.overrideStyles(cleverapps.styles.UnitView, {
    didMerge: {
        delay: 0.3
    },

    mergeAnimation: {
        y: 8,
        delay: 0
    },

    mergeAnimationBelow: {
        delay: 0.45
    }
});

cleverapps.overrideStyles(cleverapps.styles.FogBlockView, {
    openerWindow: {
        button: {
            width: 200,
            height: 70
        }
    },

    opener: {
        text: {
            x: { align: "center", dx: 2 },
            y: { align: "center", dy: 2 }
        },

        icon: {
            y: { align: "center", dy: 4 }
        }
    },

    blocker: {
        position: {
            x: { align: "center", dx: 70 },
            y: { align: "center", dy: 60 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.WorkerView, {
    timer: {
        x: { align: "center", dx: -10 },
        y: { align: "bottom", dy: 0 }
    },

    miningAnimation: {
        x: { align: "center", dx: 0, leftDx: 0 },
        y: { align: "bottom", dy: -25 }
    },

    buildingAnimation: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -25 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UnitsShopTile, {
    height: 770,
    width: 430,

    button: {
        height: 110,
        width: 260,

        y: {
            dy: -275
        }
    },

    title: {
        y: {
            align: "center",
            dy: -60
        },

        width: 460
    },

    description: {
        y: {
            dy: -155
        }
    },

    guideButton: {
        y: {
            dy: 315
        }
    },

    icon: {
        y: {
            align: "bottom",
            dy: 395
        },
        scale: 1.1
    },

    leftText: {
        width: 170,
        y: {
            dy: 305
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.InstantWorkerView, {
    jump: {
        duration: 0.5,
        repeat: false
    },

    progress: {
        x: 0,
        y: -70
    }
});

cleverapps.overrideStyles(cleverapps.styles.WheelLotteryWindow, {
    balloons: {
        y: { align: "top", dy: 0 }
    },

    table: {
        y: { align: "bottom", dy: -290 }
    },

    wheel: {
        y: { align: "center", dy: -10 }
    },

    curtains: {
        left: {
            x: { align: "left", dx: 350 },
            y: { align: "bottom", dy: 250 }
        },

        right: {
            x: { align: "right", dx: -650 },
            y: { align: "bottom", dy: 290 }
        }
    },

    note: {
        y: { align: "bottom", dy: -3 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.OpenChestWindow, {
    watchButton: {
        unit: {
            y: { align: "bottom", dy: 24 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ThirdElementView, {
    timer: {
        width: 154,
        button: {
            y: { align: "bottom", dy: -66 },
            height: 70
        }
    },

    plane: false,

    drop: {
        x: { align: "center", dx: 20 },
        y: { align: "center", dy: 20 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.WorkerComponentTimer, {
    button: {
        y: -55,
        width: 160,
        height: 70
    }
});

cleverapps.overrideStyles(cleverapps.styles.CastleGuideWindow, {
    firstStage: {
        result: {
            x: 25,
            y: 0
        },

        components: [
            {
                x: -107,
                y: -166,
                scale: 0.9
            },
            {
                x: 26,
                y: -216,
                scale: 0.9
            },
            {
                x: 135,
                y: -156,
                scale: 0.9
            }
        ]
    }
});

cleverapps.overrideStyles(cleverapps.styles.InfoView, {
    padding: {
        x: 10,
        top: 14,
        bottom: 22
    },

    pointer: {
        y: -4
    },

    title: {
        iconPadding: {
            left: 10,
            right: 20,
            top: 0,
            bottom: 2
        }
    },

    bar: {
        width: 156,
        padding: {
            x: 20,
            y: 9
        }
    },

    timer: {
        padding: {
            x: 20,
            top: -2,
            bottom: 0
        }
    },

    button: {
        padding: {
            x: 20,
            y: 7
        },
        type: {
            default: cleverapps.styles.UI.Button.Images.small_button_green,
            secondary: cleverapps.styles.UI.Button.Images.small_button_blue
        }
    },

    label: {
        padding: {
            x: 20,
            y: 5
        }
    },

    guide: {
        x: { align: "right", dx: 28 },
        y: { align: "top", dy: 28 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.IngredientsInfoView, {
    padding: {
        x: 28,
        top: 14,
        bottom: 22
    }
});

cleverapps.overrideStyles(cleverapps.styles.FieldInfoView, {
    padding: {
        x: 28,
        top: 14,
        bottom: 22
    }
});

cleverapps.overrideStyles(cleverapps.styles.ConfirmMergeWindow, {
    buttons: {
        height: 110
    }
});

cleverapps.overrideStyles(cleverapps.styles.ComponentTimer, {
    timer: {
        width: 154
    }
});

cleverapps.overrideStyles(cleverapps.styles.BankView, {
    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -75 },
        width: 154
    }
});

cleverapps.overrideStyles(cleverapps.styles.GeneratorGuideWindow, {
    secondStage: {
        container: {
            x: { align: "center", dx: 34 },
            y: { align: "center", dy: -10 }
        },

        unit: {
            scale: 0.8
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SalePassWindow, {
    height: 1048,

    progress: {
        x: { align: "right", dx: 2 },
        y: { align: "center", dy: -70 }
    },

    tickets: {
        x: { align: "left", dx: -2 },
        y: { align: "center", dy: -72 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BuildPassWindow, {
    height: 1072,

    header: {
        y: { align: "top", dy: -16 }
    },

    progress: {
        x: { align: "right", dx: 2 },
        y: { align: "center", dy: -76 }
    },

    tickets: {
        x: { align: "left", dx: -2 },
        y: { align: "center", dy: -78 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ExpeditionPassWindow, {
    height: 1026,
    width: 1726,

    header: {
        x: { align: "center", dx: -1 },
        y: { align: "top", dy: 20 },
        zOrder: -1
    },

    progress: {
        x: { align: "right", dx: 0 },
        y: { align: "bottom", dy: 105 }
    },

    tickets: {
        x: { align: "left", dx: 0 },
        y: { align: "center", dy: -48 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ExpeditionPassHeader, {
    title: {
        x: { align: "center" },
        y: { align: "center", dy: 30 }
    },

    amount: {
        x: { align: "center" },
        y: { align: "center", dy: -45 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassHeader, {
    timer: {
        height: 65,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -20 },
        tooltip: true,

        countdown: {
            x: { align: "center", dx: 15 },
            y: { align: "center", dy: 0 }
        },

        icon: {
            x: { align: "left", dx: -25 },
            y: { align: "center", dy: -2 }
        }
    },

    decor: {
        y: { align: "center", dy: 20 }
    },

    pack: {
        y: { align: "center", dy: -13 }
    },

    amount: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -120 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassHeaderPack, {
    badge: {
        x: { align: "right", dx: 210 },
        y: { align: "center", dy: 0 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassHeaderPack[Offers.TYPES.SALEPASS], {
    badge: {
        x: { align: "right", dx: 195 },
        y: { align: "center", dy: 0 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassHeaderPack[Offers.TYPES.CLPASS], {
    badge: {
        x: { align: "right", dx: 15 },
        y: { align: "center", dy: -14 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassPackBadge, {
    text: {
        y: { align: "center", dy: 8 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassProgress, {
    paddingX: -4,
    background: {
        width: 1765,
        height: 785,
        x: 460,
        y: 325
    },
    scroll: {
        x: { align: "center", dx: 139 },
        y: { align: "center", dy: -40 },
        offsetWidth: -355
    },
    line: {
        first: {
            y: { align: "center", dy: 183 }
        },
        second: {
            y: { align: "center", dy: -230 }
        },
        single: {
            x: { align: "right", dx: 0 },
            y: { align: "center", dy: -45 }
        },
        height: 830,
        scale9: cleverapps.UI.Scale9Rect.TwoPixelXY
    },
    tasks: {
        reward: {
            y: { align: "center", dy: -243 }
        },
        premiumReward: {
            y: { align: "center", dy: 170 }
        },
        bgs: {
            width: 220,
            height: 250
        },

        completedLevelIcon: {
            x: { align: "center", dx: 1 },
            y: { align: "center", dy: 2 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassTickets, {
    free: {
        x: { align: "center", dx: 190 },
        y: { align: "center", dy: -250 }
    },

    premium: {
        x: { align: "center", dx: 190 },
        y: { align: "center", dy: 120 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassTicket, {
    width: 250,
    height: 330,
    margin: -5,

    rings: [
        {
            x: { align: "left", dx: 20 },
            y: { align: "top", dy: 30 }
        },
        {
            x: { align: "right", dx: -20 },
            y: { align: "top", dy: 30 }
        }
    ],

    free: {
        text: 235,
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 2 },
        icon: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }
    },

    text: {
        width: 220,
        height: 90
    },

    icon: {
        height: 160,
        x: { align: "center", dx: -5 },
        y: { align: "top", dy: -60 }
    },

    premium: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -7 },
        icon: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 15 }
        },
        background: {
            x: { align: "bottom" },
            y: { align: "bottom" }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PassRewardIcon, {
    claimButton: {
        width: 170,
        height: 70,
        x: { align: "center" },
        y: { align: "top", dy: 70 }
    },

    icon: {
        free: { scale: 1 },
        y: { align: "center", dy: 0 },
        prem: { scale: 1 }
    },

    attention: {
        x: { align: "right", dx: -7 },
        y: { align: "top", dy: 24 }
    }

});

cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.LIVESFEAST], {
    person: {
        role: "dwarf",
        emotion: "happy"
    },

    title: {
        y: { align: "center", dy: 435 }
    },

    rewards: {
        energy: {
            x: { align: "center", dx: -530 },
            y: { align: "center", dy: -200 },
            rotation: 0
        },

        worker: {
            x: { align: "center", dx: 496 },
            y: { align: "center", dy: -203 },
            rotation: 0
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.KRAKENFEAST], {
    width: 1600,
    height: 850,

    offer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 80 }
    },

    rewards: {
        font: cleverapps.styles.FONTS.SINGLE_PRODUCT_OFFER_REWARD
    },

    button: {
        width: 300,
        height: 125,
        x: { align: "center", dx: -5 },
        y: { align: "bottom", dy: 40 }
    },

    footNote: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -66 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.BUILDPASS], {
    height: 640,
    width: 1550,

    offer: {
        y: { align: "center", dy: 100 }
    },

    badges: [
        {
            x: { align: "center", dx: 355 },
            y: { align: "top", dy: -3 },
            width: 125,
            rotation: 10
        }
    ],

    rewards: {
        unit: {
            bppointsstar: {
                x: { align: "center", dx: -590 },
                y: { align: "center", dy: -120 },
                width: 200
            },
            crystal: {
                x: { align: "center", dx: 570 },
                y: { align: "center", dy: -120 },
                width: 200
            }
        }
    },

    button: {
        y: { align: "bottom", dy: -250 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.SALEPASS], {
    height: 640,
    width: 1550,

    offer: {
        y: { align: "center", dy: 100 }
    },

    badges: [
        {
            x: { align: "center", dx: 355 },
            y: { align: "top", dy: -3 },
            width: 125,
            rotation: 10
        }
    ],

    rewards: {
        unit: {
            bppointscrystal: {
                x: { align: "center", dx: -585 },
                y: { align: "center", dy: -124 },
                width: 200
            }
        },

        soft: {
            x: { align: "center", dx: 575 },
            y: { align: "center", dy: -119 },
            width: 200
        }
    },

    button: {
        y: { align: "bottom", dy: -250 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.SOFTFEAST], {
    height: 900,

    offer: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -30 }
    },

    person: { role: "worker", emotion: "happy" },

    rewards: {
        energy: {
            x: { align: "center", dx: -530 },
            y: { align: "center", dy: -230 },
            width: 180,
            rotation: 0
        },

        soft: {
            x: { align: "center", dx: 490 },
            y: { align: "center", dy: -235 },
            width: 200,
            rotation: 0
        }
    },

    button: {
        x: { align: "center", dx: -5 },
        y: { align: "bottom", dy: -80 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SingleProductOfferWindow[Offers.TYPES.CLPASS], {
    button: {
        label: {
            text: {
                margin: -13,
                height: 120
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.KrakenFeastMissionWindow, {
    offerButton: undefined,

    offer: {
        x: { align: "center", dx: 830 },
        y: { align: "center", dy: -500 }
    },

    person: {
        left: {
            role: "worker",
            emotion: "happy"
        },
        right: undefined
    },

    decors: {
        "aquatic_flower": true
    }
});

cleverapps.overrideStyles(cleverapps.styles.BoostTimeMissionWindow, {
    height: 1000,

    person: "warlock",

    animation: {
        x: { align: "center" },
        y: { align: "center", dy: 350 }
    },

    description: {
        x: { align: "center" },
        y: { align: "center", dy: -460 }
    },

    ribbon: {
        x: { align: "center", dx: 277 },
        y: { align: "center", dy: 310 }
    },

    coinsReward: {
        x: { align: "center", dx: -60 },
        y: { align: "center", dy: -240 }
    },

    buyPackButton: {
        x: { align: "center", dx: 285 },
        y: { align: "center", dy: -270 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackOfferComponent, {
    pack: {
        offsetY: 370,
        offsetX: 255,

        title: {
            x: { align: "center", dx: 135 },
            y: { align: "center", dy: -45 },
            width: 185,
            font: cleverapps.styles.FONTS.LIGHT_PACK_BUTTON_TEXT
        }
    },
    tap: {
        x: { align: "left", dx: -130 },
        y: { align: "center", dy: 405 }
    },
    button: {
        height: 110,
        offsetY: 10
    }

});

cleverapps.overrideStyles(cleverapps.styles.PixelView, {
    collect: {
        x: { align: "center", dx: 10 },
        y: { align: "center", dy: -140 },
        delay: 1350
    }
});

cleverapps.overrideStyles(cleverapps.styles.SpecialEnergyOfferWindow, {
    animation: {
        width: 1000,
        height: 800
    },

    reward: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 85 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BuyFogWindow, {
    width: 1300,

    badge: {
        y: 400
    },

    balloons: {
        y: 300
    },

    island: {
        height: 640,
        y: { align: "center", dy: 200 }
    },

    button: {
        height: 110
    }
});

cleverapps.overrideStyles(cleverapps.styles.InstantWorkerGuideWindow, {
    firstStage: {
        container: {
            x: { align: "center", dx: -20 }
        },

        unit: {
            x: 10,
            y: 10,
            scale: 1.2,
            dy: 54
        },

        background: {
            x: 0,
            y: 0,
            scale: 0.8
        },

        components: [
            {
                x: 114,
                y: -151,
                scale: 1
            },
            {
                x: -139,
                y: -140,
                scale: 1
            },
            {
                x: -12,
                y: -214,
                scale: 1
            }
        ]
    }
});

cleverapps.overrideStyles(cleverapps.styles.SeasonWindow, {
    height: 950,
    width: 980,
    noPadding: undefined,

    scroll: {
        height: 690,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -20 },

        padding: {
            top: 30,
            bottom: 30
        }
    },

    timer: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 15 },

        timerPosition: {
            x: 0,
            y: 5
        }
    },

    top: {
        height: 240,
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -70 },

        description: {
            x: { align: "center", dx: 105 },
            y: { align: "center", dy: 45 }
        }
    }
});

if (cleverapps.styles.SeasonGuideWindow) {
    cleverapps.overrideStyles(cleverapps.styles.SeasonGuideWindow, {
        firstStage: {
            container: {
                x: { align: "center" },
                y: { align: "center", dy: 50 }
            },

            shop: {
                x: 0,
                y: 130,
                scale: 1.4,
                dy: 50
            },

            arrow: {
                x: -20,
                y: -35,
                rotation: 90,
                scale: 1.3
            },

            chests: [
                {
                    x: 87,
                    y: -97
                },
                {
                    x: -87,
                    y: -97
                },
                {
                    x: 0,
                    y: -150
                }
            ]
        },

        secondStage: {
            container: {
                y: { align: "center", dy: 80 }
            },
            bigHat: {
                y: -150,
                dy: 30,
                dx: -10,
                alignAnchorY: true
            },

            hats: {
                dy: 40,
                units: [
                    {
                        x: 0,
                        y: 140,
                        scale: 0.9
                    },
                    {
                        x: 97,
                        y: 87,
                        scale: 0.9
                    },
                    {
                        x: -97,
                        y: 87,
                        scale: 0.9
                    }
                ]
            },

            arrows: {
                y: -30
            }
        }
    });
}

cleverapps.overrideStyles(cleverapps.styles.ThirdElementWindow, {
    infoBlock: {
        icon: {
            x: { align: "right", dx: -25 }
        },

        timer: {
            x: { align: "left", dx: 70 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SellUnitWindow, {
    icon: {
        scale: 1.3
    }
});

cleverapps.overrideStyles(cleverapps.styles.NotEnoughSpaceWindow, {
    icon: {
        scale: 1.3
    }
});

cleverapps.overrideStyles(cleverapps.styles.LockedComponentView, {
    lock: {
        dx: -70,
        dy: 40
    }
});

cleverapps.overrideStyles(cleverapps.styles.BaseClanWindow, {
    width: 1380,
    height: 940,

    banner: {
        x: { align: "left", dx: -288 },
        y: { align: "top", dy: -42 },

        emblem: {
            x: { align: "center", dx: 9 },
            y: { align: "center", dy: 8 }
        },

        title: {
            x: { align: "center", dx: 5 },
            y: { align: "top", dy: 55 },
            width: 480,

            text: {
                x: { align: "center", dx: 0 },
                y: { align: "center", dy: 19 },
                width: 350,
                height: 74
            }
        }
    },

    tabs: {
        x: { align: "left", dx: -391 },
        y: { align: "bottom", dy: 60 },
        height: 160,
        width: 380,
        margin: 0,
        padding: {
            x: 30
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClansJoinTab, {
    padding: {
        x: 6,
        top: 100,
        bottom: 120
    },

    table: {
        padding: {
            left: 30,
            right: 30,
            top: 60,
            bottom: 60
        },
        innerPadding: 20
    },

    createClanButton: {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: 5 },
        height: 80,
        type: cleverapps.styles.UI.Button.Images.small_button_blue
    },

    search: {
        bg: {
            x: { align: "left", dx: 90 },
            y: { align: "top", dy: 4 }
        },

        button: {
            x: { align: "left", dx: 20 },
            y: { align: "top", dy: 18 },
            height: 110,
            width: 110
        },

        editbox: {
            width: 560,
            maxLength: 25,
            x: { align: "center", dx: 10 },
            y: { align: "center", dy: 2 }
        },

        placeholder: {
            width: 560,
            x: { align: "center", dx: 15 },
            y: { align: "center", dy: 2 }
        }
    },

    autofitButton: {
        height: 110,
        x: { align: "center" },
        y: { align: "bottom", dy: -5 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClansTopTab, {
    padding: {
        x: 6,
        top: -10,
        bottom: -10
    },

    table: {
        padding: {
            left: 30,
            right: 30,
            top: 60,
            bottom: 60
        },
        innerPadding: 20
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClansInfoTab, {
    padding: {
        x: 6,
        top: -10,
        bottom: 110
    },

    table: {
        padding: {
            left: 30,
            right: 30,
            top: 60,
            bottom: 60
        },
        innerPadding: 20
    },

    requiredLevel: {
        width: 240,
        height: 88,

        levelBg: {
            x: { align: "right", dx: 45 },
            y: { align: "center", dy: 0 },
            height: 100
        },

        text: {
            width: 160,
            height: 80,
            x: { align: "center", dx: -20 },
            y: { align: "center" }
        },

        amount: {
            x: { align: "center", dx: -1 },
            y: { align: "center", dy: 5 }
        }
    },

    leaveButton: {
        height: 110
    },

    editButton: {

        height: 110
    },

    joinButton: {
        height: 110
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClansChatTab, {
    chatBg: {
        width: 1380,
        height: 830
    },

    requestButton: {
        height: 110,
        y: { align: "bottom", dy: 0 }
    },

    phraseButton: {
        height: 110,
        y: { align: "bottom", dy: 0 }
    },

    scroll: {
        width: 1325,
        height: 800,
        margin: 10,
        padding: {
            y: 40
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClansTopRow, {
    place: {
        background: {
            width: 70,
            height: 70
        }
    },

    data: {
        offsetRight: 10
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClansInfoRow, {
    text: {
        x: { align: "center", dx: -1 },
        y: { align: "center", dy: 0 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClansCupWindow, {
    width: 1380,

    table: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -30 },
        width: 1370,
        height: 820,
        innerPadding: 20,

        padding: {
            x: 0,
            y: 60
        }
    },

    finishText: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 10 }
    },

    tabs: {
        x: { align: "left", dx: 70 },
        y: { align: "top", dy: 61 },
        height: 95,
        padding: {
            top: 5,
            bottom: 15
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.AddClanWindow, {
    width: 1360,
    height: 700,

    footerButton: {
        height: 110,
        x: { align: "center" },
        y: { align: "bottom", dy: 0 }
    },

    emblem: {
        x: { align: "left", dx: 75 },
        y: { align: "bottom", dy: 60 },
        underline: undefined,
        icon: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 15 }
        },
        margin: 20
    },

    name: {
        y: { align: "top", dy: -70 }
    },

    settings: {
        margin: 20,
        x: { align: "right", dx: -90 },
        y: { align: "bottom", dy: 70 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClanMessageRow, {
    width: 1200,

    content: {
        button: {
            type: cleverapps.styles.UI.Button.Images.small_button_green
        }
    },

    teammateHelp: {
        offsetX: 63
    },

    arrow: {
        left: {
            x: { align: "left", dx: -29 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ClanConfirmActionWindow, {
    button: {
        height: 110
    }
});

cleverapps.overrideStyles(cleverapps.styles.DeleteAccountWindow, {
    person: "worker"
});

cleverapps.overrideStyles(cleverapps.styles.TileBadge, {
    tooltip: {
        position: {
            x: { align: "center", dx: -50 },
            y: { align: "top", dy: 45 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.UnitOnCell, {
    ground: {
        x: -5,
        y: -55
    }
});

cleverapps.styles.UnitOnCellExpedition = cleverapps.overrideStyles(cleverapps.styles.UnitOnCell, {
    ground: {
        x: 3,
        y: -24
    }
}, true);

cleverapps.styles.ExpeditionFeastWindow = {
    decor: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 66 }
    }
};

cleverapps.overrideStyles(cleverapps.styles.ChestTimeMissionWindow, {
    contentPadding: [{
        left: 0,
        right: 0
    }, {
        left: 0,
        right: 500
    }, {
        left: 0,
        right: 600
    }],

    pack: {
        x: { align: "center", dx: 900 },
        y: { align: "center", dy: -100 },

        title: {
            x: { align: "center" },
            y: { align: "center", dy: 0 },
            noBg: true,

            text: {
                x: { align: "center" },
                y: { align: "center", dy: 8 },
                width: 400
            }
        },

        energyReward: {
            x: { align: "center" },
            y: { align: "center", dy: -220 }
        },

        buyPackButton: {
            x: { align: "center" },
            y: { align: "center", dy: -350 },
            width: 330,
            height: 110
        }
    },

    description: {
        x: { align: "center" },
        y: { align: "center", dy: -520 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.SubscriptionTile, {
    icon: {
        y: 0
    },

    title: {
        text: {
            y: { align: "center", dy: 3 }
        }
    }
});

if (cleverapps.styles.LandmarkComponent) {
    cleverapps.overrideStyles(cleverapps.styles.LandmarkComponent, {
        type: cleverapps.styles.UI.Button.Images.small_button_green
    });
}

cleverapps.overrideStyles(cleverapps.styles.FixedWidthScene, {
    clearColor: new cc.Color(52, 150, 205, 255)
});

cleverapps.overrideStyles(cleverapps.styles.LoaderSceneBackground, {
    logoAnimation: {
        pos: {
            factorX: 0.5,
            factorY: 0.87
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    animation: {
        "default": {
            dy: -140
        }
    },

    rewards: {
        positions: {
            "starterPack0": {
                "hard": {
                    x: 185,
                    y: -326
                },
                "energy": {
                    x: -190,
                    y: -326
                }
            },

            "starterPack": {
                "energy": {
                    x: -180,
                    y: -44
                },
                "hard": {
                    x: 180,
                    y: -44
                },
                "wands": {
                    x: -180,
                    y: -390
                },
                "worker": {
                    x: 180,
                    y: -388
                }
            },

            "starterPack2": {
                "energy": {
                    x: -250,
                    y: -62
                },
                "hard": {
                    x: 250,
                    y: -62
                },
                "wands": {
                    x: -290,
                    y: -417
                },
                "worker": {
                    x: 290,
                    y: -417
                },
                "soft": {
                    x: 4,
                    y: -294
                }
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.ReceiveWorkerWindow, {
    animation: {
        caption: {
            y: { align: "bottom", dy: -200 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BonusWorkerWindow, {
    animation: {
        margin: 40,
        padding: {
            bottom: 40
        },
        leftWorkers: {
            margin: 0
        }
    },

    button: {
        height: 110
    },

    person: {
        x: { align: "center", dx: -650 },
        y: { align: "bottom", dy: -190 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.DailyTasksCompleteAllView, {
    bg: {
        width: 1100,
        height: 222
    }
});

cleverapps.overrideStyles(cleverapps.styles.LandMarkDonorWindow, {
    tabs: {
        x: { align: "left", dx: -138 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LandmarkDonorTab, {
    donors: {
        padding: {
            x: 55
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.CrystalGuideWindow, {
    firstStage: {
        unit: {
            x: 0,
            y: 0,
            scale: 1.4,
            dy: 50
        },

        arrows: {
            x: -5,
            y: -120
        },

        components: [
            {
                x: -145,
                y: -166,
                scale: 1.2
            },
            {
                x: 1,
                y: -249,
                scale: 1.2
            },
            {
                x: 135,
                y: -166,
                scale: 1.2
            }
        ]
    },

    thirdStage: {
        units: [
            {
                x: 110,
                y: -206,
                dx: 0,
                dy: 25,
                scale: 1.3
            },
            {
                x: -109,
                y: -196,
                dx: 0,
                dy: 10,
                scale: 1.3
            },
            {
                x: 1,
                y: -254,
                dx: 0,
                dy: 10,
                scale: 1.3
            },
            {
                x: 0,
                y: -30,
                dx: 14,
                dy: 25,
                scale: 1.4
            }
        ]
    }
});