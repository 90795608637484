/**
 * Created by Denis Kuzin on 21 february 2022
 */

var ClansRow = cc.Scale9Sprite.extend({
    ctor: function (row, options) {
        options = options || {};

        this.row = row;
        this.options = options;
        this.place = row.data.place;

        row.view = this;

        var styles = cleverapps.styles.ClansRow;

        var background = this.row.isPlayer() ? bundles.clan_table.frames.table_row_you_png : bundles.clan_table.frames.table_row_png;
        this._super(background);
        
        this.setContentSize2(styles.width, styles.height);

        var place, avatar, name, score, button;

        if (this.createPlace) {
            place = this.createPlace();
        }

        avatar = this.createAvatar();

        name = this.createName();
        name.setPositionRound(styles.name);

        if (this.createScore) {
            score = this.createScore();
        }

        if (this.createButton) {
            button = this.createButton();
        }

        var nameWidth = styles.width - 2 * styles.paddingX - [place, avatar, score, button].filter(Boolean).reduce(function (width, item) {
            return width + item.width * item.scaleX + styles.content.margin;
        }, 0);

        cleverapps.UI.fitToBox(name, {
            width: nameWidth
        });

        var nameWrapper = new cc.Node();
        nameWrapper.setContentSize2(nameWidth, styles.height);
        nameWrapper.setAnchorPoint(0.5, 0.5);
        nameWrapper.addChild(name);

        this.content = new cleverapps.Layout([place, avatar, nameWrapper, score, button].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.content.margin
        });
        this.addChild(this.content);
        this.content.setPositionRound(styles.content);
    },

    createName: function () {
        var styles = cleverapps.styles.ClansRow;

        var userName = cleverapps.UI.cutPlayerName(this.row.data.name || this.row.id && "Player_" + this.row.id || "", 12);
        var text = cleverapps.UI.generateOnlyText(userName, cleverapps.styles.FONTS.CLANS_ROW_NAME_TEXT);
        var leaderIcon, leaderText;

        if (this.row.data.leader) {
            leaderIcon = new cc.Sprite(bundles.clan_table.frames.red_flag);
            leaderText = cleverapps.UI.generateOnlyText("ClansRow.teamLeader", cleverapps.styles.FONTS.CLANS_ROW_LEADER_TEXT);
        }

        return new cleverapps.Layout([text, leaderIcon, leaderText].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.content.margin
        });
    },

    createAvatar: function () {
        var styles = cleverapps.styles.ClansRow;

        var avatar = this.row.data.emblem
            ? new cc.Sprite(bundles.tablerow_icons.frames["emblem_" + this.row.emblem])
            : new cleverapps.UI.Avatar(this.row.data.avatar);

        cleverapps.UI.fitToBox(avatar, {
            height: this.row.data.emblem ? styles.emblem.height : styles.content.height
        });

        return avatar;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CLANS_ROW_PLACE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    CLANS_ROW_NAME_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    CLANS_ROW_LEADER_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.GREEN
    },

    CLANS_ROW_SCORE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.ClansRow = {
    width: 1245,
    height: 118,
    paddingX: 15,

    content: {
        height: 62,
        margin: 10,
        x: { align: "center" },
        y: { align: "center", dy: 3 }
    },

    name: {
        x: { align: "left" },
        y: { align: "center" }
    },

    emblem: {
        height: 100
    }
};