/**
 * Created by r4zi4l on 17.06.2021
 */

var FeastMissionWindow = MissionWindow.extend({
    onWindowLoaded: function (mission, options) {
        var bundleName = options.bundleName || mission.getName().toLowerCase() + "_window";
        var offer = this.offer = cleverapps.offerManager.findOffer({ mission: mission.type });

        this._super(mission, {
            name: options.name,
            title: options.name + ".title",
            finishedText: "FeastMissionWindow.finished",
            bundleName: bundleName,
            scoreTooltip: options.name + ".tooltip.amount",
            offerWindowButton: offer ? {
                text: "FeastMissionWindow." + mission.getName() + ".offer",
                offerType: offer.type,
                onClicked: this.onOfferClicked.bind(this),
                bundle: bundleName,
                badgeText: "FeastMissionWindow." + mission.getName() + ".offerBadge"
            } : undefined
        });

        this.mission.updateCompetitionResults();
    },

    onOfferClicked: function () {
        cleverapps.windows.currentWindow().close();
        new SingleProductOfferWindow(this.offer);
    },

    listBundles: function (mission) {
        return [mission.getName().toLowerCase() + "_window"];
    }
});
