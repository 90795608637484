/**
 * Created by vtbelo on 17/03/21
 */

var GrowingsPlanner = function (isNewGame) {
    if (cleverapps.config.editorMode || cleverapps.config.adminMode || cleverapps.config.wysiwygMode) {
        return;
    }

    this.data = GrowingsPlanner.CONFIG[cleverapps.meta.selectedLocationId()];
    if (this.data) {
        if (isNewGame) {
            cleverapps.dataLoader.remove(this.saveId());
        }

        this.load();
        this.planNext(this.getBetweenTimeout());
    }
};

GrowingsPlanner.prototype.getBetweenTimeout = function () {
    return cleverapps.parseInterval(this.data.betweenTimeouts[this.spawnOrder < this.data.betweenTimeouts.length ? this.spawnOrder : this.data.betweenTimeouts.length - 1]);
};

GrowingsPlanner.prototype.destructor = function () {
    this.clearPlanTimeout();
};

GrowingsPlanner.prototype.clearPlanTimeout = function () {
    if (this.planTimeout !== undefined) {
        clearTimeout(this.planTimeout);
        this.planTimeout = undefined;
    }
};

GrowingsPlanner.prototype.getFreeSlots = function () {
    var slots = [];
    var map = Map2d.currentMap;
    for (var y = 0; y < map.getHeight(); y++) {
        for (var x = 0; x < map.getWidth(); x++) {
            if (map.isEmpty(x, y)) {
                slots.push({ x: x, y: y });
            }
        }
    }
    return slots;
};

GrowingsPlanner.prototype.amountGrowings = function () {
    var amountGrowings = 0;
    var map = Map2d.currentMap;
    for (var y = 0; y < map.getHeight(); y++) {
        for (var x = 0; x < map.getWidth(); x++) {
            var unit = map.getUnit(x, y);
            if (unit) {
                for (var t = 0; t < this.data.units.length; t++) {
                    if (this.data.units[t].code === unit.code && this.data.units[t].stage === unit.stage) {
                        amountGrowings++;
                        break;
                    }
                }
            }
        }
    }
    return amountGrowings;
};

GrowingsPlanner.prototype.existFreeSlot = function () {
    var slots = this.getFreeSlots();
    return slots.length > 0;
};

GrowingsPlanner.prototype.getRandomSlot = function () {
    if (this.existFreeSlot() && this.amountGrowings() < this.data.maxAmount) {
        return cleverapps.Random.choose(this.getFreeSlots(), Math.floor(Math.random() * 10000));
    }
};

GrowingsPlanner.prototype.spawn = function () {
    var slot = this.getRandomSlot();
    if (slot) {
        var unitData = cleverapps.Random.choose(this.data.units, Math.floor(Math.random() * 10000));

        var unit = new Unit(unitData);
        unit.findComponent(Growing).spawn(slot);
    }
};

GrowingsPlanner.prototype.saveId = function () {
    return DataLoader.TYPES.GROWINGS_PLANNER + Merge.currentMerge.location.slot;
};

GrowingsPlanner.prototype.save = function () {
    if (!this.isAvailable()) {
        return;
    }

    cleverapps.dataLoader.save(this.saveId(), {
        time: this.spawnTime,
        order: this.spawnOrder
    });
};

GrowingsPlanner.prototype.load = function () {
    var data = cleverapps.dataLoader.load(this.saveId());

    if (data) {
        this.spawnTime = data.time;
        this.spawnOrder = data.order;
    } else {
        this.spawnTime = Date.now();
        this.spawnOrder = 0;
        this.save();
    }
};

GrowingsPlanner.prototype.onRemoveGrowing = function () {
    if (this.planTimeout === undefined) {
        this.spawnTime = Date.now();
        this.spawnOrder = 0;
        this.save();
        this.planNext(this.getBetweenTimeout());
    }
};

GrowingsPlanner.prototype.isAvailable = function () {
    return !this.data.fog || Map2d.currentMap.fogs.isOpened(this.data.fog);
};

GrowingsPlanner.prototype.planNext = function (timeout) {
    this.clearPlanTimeout();

    if (!this.isAvailable()) {
        this.spawnTime = Date.now();
        this.planTimeout = setTimeout(this.planNext.bind(this, timeout), cleverapps.parseInterval(GrowingsPlanner.INIT_TIMEOUT));
        return;
    }

    var nextSpawnTime = this.spawnTime + timeout;

    var waitTime = 0;
    if (nextSpawnTime > Date.now()) {
        waitTime = nextSpawnTime - Date.now();
    }

    this.planTimeout = setTimeout(function () {
        this.clearPlanTimeout();

        if (this.amountGrowings() >= this.data.maxAmount) {
            return;
        }

        if (!this.existFreeSlot()) {
            nextSpawnTime = Date.now();
        } else {
            this.spawn();
        }

        this.spawnTime = nextSpawnTime;
        this.spawnOrder++;
        this.save();

        this.planNext(this.getBetweenTimeout());
    }.bind(this), waitTime);
};

GrowingsPlanner.INIT_TIMEOUT = "30 seconds";

GrowingsPlanner.CONFIG = {};
