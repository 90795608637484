/**
 * Created by Vladislav on 25.09.2024.
 */

Placements.ENTRIES["clanCupWindow"] = {
    type: Placements.FREE_FOCUS_MAIN,
    filter: function () {
        return cleverapps.clanCup.wantsToShow;
    },

    action: function () {
        cleverapps.focusManager.display({
            focus: "ClansCupIcon",
            actions: [
                function (f) {
                    new GuideWindow({ name: "ClanCupGuideWindow", bundles: bundles.clancupguidewindow });
                    cleverapps.focusManager.onceNoWindowsListener = f;
                },

                function (f) {
                    new ClansCupWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            ]
        });
    }
};

Placements.ENTRIES["clanCupReward"] = {
    type: Placements.FREE_FOCUS_MAIN,
    filter: function () {
        return cleverapps.clanCup.getReward();
    },

    action: function () {
        cleverapps.focusManager.display({
            focus: "clanCupReward",
            action: function (f) {
                cleverapps.clanCup.receiveReward(f);
            }
        });
    }
};
