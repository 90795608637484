/**
 * Created by r4zi4l on 04.05.2021
 */

var LivesFeastMissionWindow = FeastMissionWindow.extend({
    onWindowLoaded: function (mission) {
        this._super(mission, {
            name: "LivesFeastMissionWindow",
            notCloseByTouchInShadow: true,
            openSound: bundles.livesfeast_window.urls.feast_window_effect
        });
    },

    getPerson: function () {
        return {
            role: cleverapps.config.name === "hustlemerge" ? "dwarf" : "warlock",
            emotion: "happy"
        };
    },

    getGuideOptions: function () {
        return {
            name: "LivesFeastGuideWindow",
            title: "LivesFeastGuideWindow.title",
            bundle: bundles.livesfeastguidewindow
        };
    }
});