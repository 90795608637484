/**
 * Created by spepa on 21.11.2022
 */

var LandmarkDonorTab = cleverapps.Layout.extend({
    ctor: function (landmark, reward, onUpdateTab, onClose) {
        this.onUpdateTab = onUpdateTab;
        this.onClose = onClose;

        this.landmark = landmark;
        this.reward = reward;

        var items;

        if (Merge.currentMerge.landmarks.withOneDonor(this.landmark)) {
            items = [
                this.createTimer(),
                this.createDesc(),
                this.createLandmark(),
                this.createFindButton()
            ];
        } else {
            items = [
                this.createDesc(),
                this.createLandmark(),
                this.createDonors(),
                this.createTimer()
            ];
        }

        this._super(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: cleverapps.styles.LandmarkDonorTab.margin
        });
    },

    onSelectTab: function () {
        var percent = Merge.currentMerge.landmarks.calcProgress(this.landmark);
        var prevPercent = Merge.currentMerge.landmarks.calcProgress(this.landmark, true);

        Merge.currentMerge.landmarks.clearPendingProgress(this.landmark);

        if (percent === prevPercent) {
            this.bar.setPercentage(percent);
        } else {
            this.bar.setPercentage(prevPercent);

            var progressTime = 0.8;

            this.unit.runAction(new cc.Sequence(
                new cc.DelayTime(0.5),
                new cc.PlaySound(bundles.landmarkdonor_window.urls.progress_effect),
                new cc.CallFunc(function () {
                    this.bar.setPercentage(percent, {
                        time: progressTime,
                        animated: true
                    });
                }.bind(this)),
                new cc.DelayTime(progressTime),
                new cc.CallFunc(function () {
                    if (this.reward) {
                        if (this.unit.hasAnimation && this.unit.hasAnimation("smile")) {
                            this.unit.setAnimationAndIdleAfter("smile", "animation");
                        }

                        var rays = StandartAnimations.rays(this.unit, { skin: "yellow" });
                        rays.setLocalZOrder(0);
                        rays.replaceParentSamePlace(this.unit.parent, { keepScale: true });

                        this.unit.runAction(new cc.Sequence(
                            new cc.ScaleBy(0.382, 1.3),
                            new cc.ScaleBy(0.431, 1 / 1.3),
                            new cc.DelayTime(0.2),
                            new cc.CallFunc(this.onClose)
                        ));
                    }
                }.bind(this))
            ));
        }

        this.onUpdateTab();
    },

    createDesc: function () {
        var styles = cleverapps.styles.LandmarkDonorTab;

        var withOneDonor = Merge.currentMerge.landmarks.withOneDonor(this.landmark);

        var text = withOneDonor ? Messages.get("LandMarkDonorWindow.description", {
            code: cleverapps.unitsLibrary.getFamilyName(this.landmark.donors[0])
        }) : "LandMarkDonorWindow.description.multidonors";

        var desc = this.desc = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.WINDOW_TEXT);

        desc.setDimensions(styles.width, 0);
        desc.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        desc.fitTo(undefined, withOneDonor ? styles.description.oneDonorHeight : styles.description.height);
        return desc;
    },

    createLandmark: function () {
        var styles = cleverapps.styles.LandmarkDonorTab;
        var bg = cleverapps.UI.createScale9Sprite(bundles.landmarkdonor_window.frames.details_bg);
        bg.setContentSize2(styles.width, styles.landmark.height);

        var bar = this.createBar();
        bg.addChild(bar);
        bar.setPositionRound(styles.landmark.bar);

        var help = new cleverapps.UI.HelpButton({
            callback: function () {
                new GuideWindow(Merge.currentMerge.landmarks.getGuideWindowOptions(this.landmark));
            }.bind(this) 
        });
        bg.addChild(help);
        help.setPositionRound(styles.landmark.help);

        var unit = this.unit = UnitView.getUnitImage(this.landmark);
        bg.addChild(unit);
        unit.setLocalZOrder(1);
        unit.setPositionRound(styles.landmark.icon);

        return bg;
    },

    createBar: function () {
        var styles = cleverapps.styles.LandmarkDonorTab.landmark.bar;

        var progressBar = this.bar = new ScaledProgressBar({
            type: ScaledProgressBar.Types.green_large,
            barText: {
                text: "%from%%",
                font: cleverapps.styles.FONTS.LANDMARK_PROGRESS_FONT
            }
        });
        progressBar.setAnchorPoint(0.5, 0.5);
        progressBar.setScale(styles.scale);
        progressBar.setLength(styles.width / styles.scale);

        var barNode = new cc.Node();
        barNode.setAnchorPoint(0.5, 0.5);
        barNode.setContentSize(styles.width, progressBar.height);

        barNode.addChild(progressBar);
        progressBar.setPosition(barNode.width / 2, barNode.height / 2);

        if (Merge.currentMerge.landmarks.withOneDonor(this.landmark)) {
            var unitImage = UnitView.getUnitImage(UnitsLibrary.LastStage(this.landmark.donors[0]), {
                alignAnchorX: true,
                alignAnchorY: true
            });
            cleverapps.UI.fitToBox(unitImage, styles.unit);

            unitImage.setPosition(barNode.width + styles.unit.dx, styles.unit.dy);
            barNode.addChild(unitImage);
        }

        return barNode;
    },

    createDonors: function () {
        var styles = cleverapps.styles.LandmarkDonorTab;

        var icons = this.landmark.donors.map(function (donorCode) {
            var background = new cc.Scale9Sprite(bundles.merge.frames.item_bg_png);
            background.setContentSize2(styles.donors.icon);

            var image = UnitView.getUnitImage(UnitsLibrary.LastStage(donorCode), {
                preferStatic: true
            });
            image.setPositionRound(background.width / 2, background.height / 2);
            cleverapps.UI.fitToBox(image, {
                width: background.width - styles.donors.icon.padding * 2,
                height: background.height - styles.donors.icon.padding * 2
            });
            background.addChild(image);

            if (Merge.currentMerge.landmarks.isDonorOpened(donorCode)) {
                var checkmark = new cc.Sprite(bundles.merge.frames.customer_check_mark);
                checkmark.setPositionRound(styles.donors.checkmark);
                background.addChild(checkmark);
            } else {
                var help = cleverapps.UI.createScale9Sprite(bundles.merge.frames.lupa_mark);
                cleverapps.UI.applyHover(help);
                cleverapps.UI.onClick(help, function () {
                    this.onClose();
                    UnitLocator.find(Map2d.OPEN_UNIT, { code: donorCode }, { allowScrollWithFocus: true });
                }.bind(this));
                help.setPositionRound(styles.donors.help);
                background.addChild(help);
            }
            return background;
        }.bind(this));

        var layout = new cleverapps.Layout(icons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.donors.margin,
            padding: styles.donors.padding
        });

        var scroll = new cleverapps.UI.ScrollView(layout, {
            direction: cleverapps.UI.ScrollView.DIR_HORIZONTAL
        });
        scroll.setContentSize2(styles.width + styles.donors.padding.x * 2, layout.height);
        scroll.scrollToDefault();

        var title = cleverapps.UI.generateOnlyText("LandMarkDonorWindow.items", cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);

        return new cleverapps.Layout([title, scroll], {
            direction: cleverapps.UI.VERTICAL,
            padding: {
                x: -styles.donors.padding.x
            }
        });
    },

    createTimer: function () {
        var timeLeft = Merge.currentMerge.landmarks.getTimeLeft(this.landmark);
        return new Timer(timeLeft, this.onClose);
    },

    createFindButton: function () {
        var styles = cleverapps.styles.LandmarkDonorTab;

        return new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: "Find",
            onClicked: function () {
                this.onClose();
                UnitLocator.find(Map2d.BUILD, { code: this.landmark.donors[0] }, { allowScrollWithFocus: true });
            }.bind(this)
        });
    },

    getLandmarkIcon: function () {
        return this.unit;
    }
});

cleverapps.styles.LandmarkDonorTab = {
    width: 840,
    margin: 20,

    description: {
        height: 120,
        oneDonorHeight: 220
    },

    landmark: {
        height: 540,

        icon: {
            x: { align: "center" },
            y: { align: "center", dy: 55 }
        },

        bar: {
            x: { align: "center" },
            y: { align: "bottom", dy: 30 },
            width: 740,
            scale: 0.7,

            unit: {
                width: 145,
                height: 230,
                dx: -20,
                dy: 15
            }
        },

        help: {
            x: { align: "right", dx: 20 },
            y: { align: "top", dy: 20 }
        }
    },

    donors: {
        margin: 22,

        padding: {
            top: 20,
            bottom: 10,
            x: 100
        },

        icon: {
            width: 150,
            height: 150,
            padding: 14
        },

        checkmark: {
            x: { align: "right", dx: -8 },
            y: { align: "bottom", dy: 10 }
        },

        help: {
            x: { align: "right", dx: 5 },
            y: { align: "bottom", dy: -2 }
        }
    },

    button: {
        width: 250,
        height: 100
    }
};
