/**
 * Created by spepa on 01.12.2022
 */

Map2d.TERRAIN_CONFIG = {
    default: {
        height: 0,
        frames: {
            water1_up_right: { y: 0, anchorY: 0.85 }
        }
    },

    tiles_castle_purple: {
        height: 2,
        frames: {
            water1_up_right: { anchorY: 0.395 }
        }
    },

    tiles_castle_blue: {
        height: 2,
        frames: {
            water1_up_right: { anchorY: 0.395 },
            water1_down_left: { anchorY: 0.355 },
            land2_down_left: { anchorY: 0.33 }
        }
    },

    tiles_castle_green: {
        height: 0,
        frames: {
            water1_down_last: { anchorX: 0 },
            water1_left_last: { anchorX: 1 }
        }
    },

    tiles_cloudislands_purple: {
        height: 0,
        frames: {
            water1_up_right: { y: 0, anchorY: 0.64 }
        }
    },

    tiles_cloudislands_green: {
        height: 0,
        frames: {
            water1_up_right: { y: 0, anchorY: 0.91 }
        }
    },

    tiles_fairyland_green: {
        height: 0,
        frames: {
            water1_up_right: { y: 0, anchorY: 0.85 },
            water1_up: { y: 0, anchorX: 0.51, anchorY: 0.115 }
        }
    },

    tiles_coloredseabottom_purple: {
        height: 0,
        frames: {
            land2_down_left: { y: 0, anchorY: 0.15 },
            water1_down_left: { y: 0, anchorY: 0.45 }
        }
    },

    tiles_coloredseabottom_green: {
        height: 0,
        frames: {
            land2_down_left: { y: 0, anchorY: 0.1 }
        }
    },

    tiles_coloredseabottom_blue: {
        height: 0,
        frames: {
            land2_down_left: { y: 0, anchorY: 0.15 },
            water1_down_left: { y: 0, anchorY: 0.45 }
        }
    },

    tiles_tomb_purple: {
        height: 0,
        frames: {
            land2_down_left: { y: 0, anchorY: 0.14 },
            water1_down_left: { y: 0, anchorY: -0.15 },
            water1_up_right: { y: 0, anchorY: 0.95 }
        }
    },

    tiles_tomb_yellow: {
        height: 0,
        frames: {
            land2_down_left: { y: 0, anchorY: 0.13 },
            water1_down_left: { y: 0, anchorY: -0.15 },
            water1_up_right: { y: 0, anchorY: 0.95 }
        }
    },

    tiles_tomb_green: {
        height: 0,
        frames: {
            land2_down_left: { y: 0, anchorY: 0.12 },
            water1_down_left: { y: 0, anchorY: -0.15 },
            water1_up_right: { y: 0, anchorY: 0.95 }
        }
    },

    tiles_map0_floor4: {
        height: 2,
        frames: {
            water1_up_right: { anchorY: 0.395 },
            water1_down_left: { anchorY: 1 }
        }
    },

    tiles_map0_floor5: {
        height: 2,
        frames: {
            water1_up_right: { anchorY: 0.395 }
        }
    },

    tiles_map1_floor5: {
        height: 2,
        frames: {
            water1_up_right: { anchorY: 0.395 },
            water1_down_left: { anchorY: 1 }
        }
    }
};

(function () {
    var wallBorders = [
        "water1_inner_up_left",
        "water1_right",
        "water1_up"
    ];

    var cornerBorder = [
        "water1_inner_down_left",
        "water1_inner_down_left_last",
        "water1_inner_up_right",
        "water1_inner_up_right_last",
        "water2_inner_down_left",
        "water2_inner_down_left_last",
        "water2_inner_up_right",
        "water2_inner_up_right_last"
    ];

    for (var name in Map2d.TERRAIN_CONFIG) {
        var conf = Map2d.TERRAIN_CONFIG[name];

        cornerBorder.forEach(function (border) {
            if (!conf.frames[border]) {
                conf.frames[border] = { anchorY: 0.5 };
            }
        });

        wallBorders.forEach(function (border) {
            if (!conf.frames[border]) {
                conf.frames[border] = { anchorY: 0 };
            }
        });

        if (!conf.frames.water1_down_right) {
            conf.frames.water1_down_right = { anchorX: 0, anchorY: 0.5 };
        }
        if (!conf.frames.water1_up_left) {
            conf.frames.water1_up_left = { anchorX: 1, anchorY: 0.5 };
        }
    }
}());
