/**
 * Created by razial on 18.03.2025.
 */

var FakeMergeAdapter = function () {

};

FakeMergeAdapter.prototype.isNewGame = function () {
    return true;
};

FakeMergeAdapter.prototype.load = function () {

};

FakeMergeAdapter.prototype.save = function () {

};

FakeMergeAdapter.prototype.remove = function () {

};

FakeMergeAdapter.prototype.removeUnitsInFog = function () {

};

FakeMergeAdapter.prototype.restoreKickOuts = function () {

};

FakeMergeAdapter.prototype.checkReset = function () {

};

FakeMergeAdapter.prototype.checkReset2 = function () {

};
