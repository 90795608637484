/**
 * Created by r4zi4l on 06.09.2022
 */

var ClansInfoTab = cc.Node.extend({
    ctor: function (options, clan) {
        var styles = cleverapps.styles.ClansInfoTab;

        this._super();
        this.clan = clan || cleverapps.userClan;

        this.setContentSize2(options.width, options.height);

        this.createTableBg();
        this.createTable();
        this.createFooter();

        if (cleverapps.userClan.id === undefined) {
            var joinButton = this.createJoinButton();
            joinButton.setPositionRound(styles.joinButton);
            this.addChild(joinButton);
        } else if (cleverapps.userClan.id === this.clan.id) {
            var leaveButton = this.createLeaveButton();
            leaveButton.setPositionRound(styles.leaveButton);
            this.addChild(leaveButton);

            if (this.clan.getOwner() === connector.platform.getUserID()) {
                var editButton = this.createEditButton();
                editButton.setPositionRound(styles.editButton);
                this.addChild(editButton);
            }
        }
    },

    createTableBg: function () {
        var styles = cleverapps.styles.ClansInfoTab;

        var rect = cc.rectSubPadding(cc.rect(0, 0, this.width, this.height), cc.padding(styles.padding));

        var tableBg = this.tableBg = cleverapps.UI.createScale9Sprite(bundles.clan.frames.table_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        tableBg.setContentSize2(rect.width, rect.height);
        tableBg.setAnchorPoint(0, 0);
        tableBg.setPositionRound(rect.x, rect.y);
        this.addChild(tableBg);

        var foreground = cleverapps.UI.createScale9Sprite(bundles.clan.frames.table_fg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        foreground.setContentSize2(tableBg.width, tableBg.height);
        foreground.setAnchorPoint(0, 0);
        foreground.setPositionRound(0, 0);
        tableBg.addChild(foreground, 1);
    },

    createTable: function () {
        var styles = cleverapps.styles.ClansInfoTab.table;

        var rect = cc.rectSubPadding(cc.rect(0, 0, this.tableBg.width, this.tableBg.height), cc.padding(styles.padding));

        var players = this.clan.players.slice();

        players.sort(function (a, b) {
            return Boolean(b.isOwner()) - Boolean(a.isOwner()) || b.level - a.level;
        });

        var results = players.map(function (player, index) {
            var data = player.convertToRowData();
            data.data.place = index + 1;
            return data;
        });

        var table = new Table();
        var tableView = new TableView(table, {
            id: "infoClans",
            data: results,
            height: rect.height,
            bg: false,
            rowConstructor: ClansInfoRow,
            scroll: cleverapps.UI.ScrollView.SCROLLS.UPPER_LEFT,
            innerPadding: styles.innerPadding
        });
        tableView.setPositionRound(rect.x + rect.width / 2, rect.y + rect.height / 2);
        this.tableBg.addChild(tableView);
    },

    createFooter: function () {
        var styles = cleverapps.styles.ClansInfoTab.footer;

        var clanSize = this.createClanSize();
        var requiredLevel = this.createRequiredLevel();

        var footer = new cleverapps.Layout([clanSize, requiredLevel], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        footer.setPositionRound(styles);
        this.addChild(footer);
    },

    createClanSize: function () {
        var styles = cleverapps.styles.ClansInfoTab.clanSize;

        var bg = cleverapps.UI.createScale9Sprite(bundles.clan.frames.clan_info_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(styles);

        var icon = new cc.Sprite(bundles.clan_table.frames.players_icon);
        bg.addChild(icon);
        icon.setPositionRound(styles.icon);

        var text = cleverapps.UI.generateImageText(this.clan.membersCount + "/" + ClansConfig.MAX_SIZE, cleverapps.styles.FONTS.CLAN_LIGHT_TEXT);
        text.fitTo(styles.text.width);
        bg.addChild(text);
        text.setPositionRound(styles.text);

        return bg;
    },

    createRequiredLevel: function () {
        var styles = cleverapps.styles.ClansInfoTab.requiredLevel;

        var bg = cleverapps.UI.createScale9Sprite(bundles.clan.frames.clan_info_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(styles);

        var text = cleverapps.UI.generateOnlyText("AddClanWindow.requiredLevel", cleverapps.styles.FONTS.CLAN_LIGHT_TEXT);
        text.setString(cleverapps.splitHalfByWord(text.getString()).join("\n"));
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.fitTo(undefined, styles.text.height);

        bg.addChild(text);
        text.setPositionRound(styles.text);

        var levelBg = new cc.Scale9Sprite(bundles.clan_table.frames.level_background);

        var crown = new cleverapps.Spine(bundles.clan_table.jsons.level_crown);
        crown.setPositionRound(styles.crown);
        crown.setAnimation(0, "idle", true);

        var amount = cleverapps.UI.generateImageText(this.clan.requiredLevel, cleverapps.styles.FONTS.MENUBAR_LEVEL_TEXT);
        amount.fitTo(styles.amount.width);
        amount.setPositionRound(styles.amount);

        levelBg.addChild(amount);
        levelBg.addChild(crown);

        cleverapps.UI.fitToBox(levelBg, styles.levelBg);

        bg.addChild(levelBg);
        levelBg.setPositionRound(styles.levelBg);

        return bg;
    },

    createLeaveButton: function () {
        var styles = cleverapps.styles.ClansInfoTab.leaveButton;

        return new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            type: cleverapps.styles.UI.Button.Images.button_red,
            text: "ClansInfoTab.leave",
            disabled: !this.clan.isUserClan(),
            onClicked: function () {
                new ClanConfirmActionWindow({
                    title: "ClansConfirmActionWindow.leaveClan",
                    action: function () {
                        cleverapps.clans.leave();
                    }
                });
            }
        });
    },

    createEditButton: function () {
        var styles = cleverapps.styles.ClansInfoTab.editButton;

        return new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            type: cleverapps.styles.UI.Button.Images.button_blue,
            text: "Edit",
            onClicked: function () {
                new AddClanWindow(this.clan);
            }.bind(this)
        });
    },

    createJoinButton: function () {
        var styles = cleverapps.styles.ClansInfoTab.joinButton;

        return new cleverapps.UI.Button({
            width: styles.width,
            height: styles.height,
            type: cleverapps.styles.UI.Button.Images.button_red,
            text: "Join",
            disabled: !this.clan.isAppropriate(),
            onClicked: function () {
                cleverapps.clans.join(this.clan.id);
            }.bind(this)
        });
    }
});

cleverapps.styles.ClansInfoTab = {
    padding: {
        x: -30,
        top: -10,
        bottom: 100
    },

    table: {
        padding: {
            x: 0,
            top: 20,
            bottom: 20
        }
    },

    footer: {
        margin: 30,
        x: { align: "left", dx: 20 },
        y: { align: "bottom", dy: -5 }
    },

    clanSize: {
        width: 250,
        height: 88,

        icon: {
            x: { align: "left", dx: 15 },
            y: { align: "center" }
        },

        text: {
            width: 150,
            x: { align: "right", dx: -20 },
            y: { align: "center" }
        }
    },

    requiredLevel: {
        width: 300,
        height: 88,

        crown: {
            x: { align: "left", dx: -20 },
            y: { align: "top", dy: 20 }
        },

        levelBg: {
            x: { align: "right", dx: -20 },
            y: { align: "center", dy: -2 },
            height: 70
        },

        text: {
            width: 200,
            height: 80,
            x: { align: "center", dx: -35 },
            y: { align: "center" }
        },

        amount: {
            x: { align: "center", dx: 1 },
            y: { align: "center", dy: 10 },
            width: 85
        }
    },

    leaveButton: {
        width: 300,
        height: 100,
        x: { align: "right" },
        y: { align: "bottom", dy: -10 }
    },

    editButton: {
        width: 300,
        height: 100,
        x: { align: "right", dx: -340 },
        y: { align: "bottom", dy: -10 }
    },

    joinButton: {
        width: 300,
        height: 100,
        x: { align: "right", dx: -20 },
        y: { align: "bottom", dy: -10 }
    }
};