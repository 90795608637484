/**
 * Created by razial on 03.02.2025.
 */

Placements.ENTRIES["travelBook"] = {
    type: Placements.FREE_FOCUS,
    priority: 1,

    filter: function () {
        return cleverapps.environment.isMainScene();
    },

    action: function () {
        cleverapps.travelBook.updatePages();
        cleverapps.toolbar.resetByType(ToolbarItem.TRAVEL_BOOK);
    }
};

Placements.ENTRIES["locationStart"] = {
    type: Placements.FREE_FOCUS,
    priority: 2,
    delayFromStart: "1 minutes",

    filter: function () {
        return cleverapps.environment.isMainScene() && cleverapps.meta.nextLocationId() && !cleverapps.config.demoMode;
    },

    action: function () {
        cleverapps.meta.startLocation(cleverapps.meta.nextLocationId());
    }
};

Placements.ENTRIES["locationFinish"] = {
    type: Placements.FREE_FOCUS,
    priority: 5,

    filter: function () {
        return cleverapps.environment.isMainScene() && cleverapps.meta.getSelectedLocation().isCompleted() && !cleverapps.config.demoMode;
    },

    action: function () {
        var location = cleverapps.meta.getSelectedLocation();

        cleverapps.focusManager.display({
            focus: "locationsFinished" + location.locationId,
            action: function (f) {
                location.displayCompleted(f);
            }
        });
    }
};

Placements.ENTRIES["locationRemoveSilents"] = {
    type: Placements.FREE_FOCUS,
    priority: 6,

    filter: function () {
        return cleverapps.environment.isMainScene() && !cleverapps.config.demoMode;
    },

    action: function () {
        cleverapps.meta.removeSilents();
    }
};

Placements.ENTRIES["replaceOldLocation"] = {
    type: Placements.FREE_FOCUS,
    priority: 7,

    action: function () {
        cleverapps.meta.logic.replaceOldLocation();
    }
};