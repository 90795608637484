/**
 * Created by spepa on 03.03.2023
 */

QuestsConfig.easter = {
    eaprince_0: {
        trigger: { fog: "fog0" },
        tutorial: "easter_eggssource",
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "eaprince", stage: 0 }
        }
    },
    eaprince_1: {
        trigger: { unit: { code: "eaprince", stage: 0 } },
        cancelZoomOut: true,
        tutorial: "easter_eggssource1",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eaeggs", stage: 3 }
        }
    },
    eabaskets_3: {
        trigger: { unit: { code: "eabaskets", stage: 0 } },
        tutorial: "eabaskets_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eabaskets", stage: 3 }
        }
    },
    eateaset_3: {
        trigger: { unit: { code: "eateaset", stage: 0 } },
        tutorial: "eateaset_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eateaset", stage: 3 }
        }
    },
    eachick_3: {
        trigger: { unit: { code: "eachick", stage: 0 } },
        tutorial: "eachick_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eachick", stage: 3 }
        }
    },
    eatulips_3: {
        trigger: { unit: { code: "eatulips", stage: 0 } },
        tutorial: "eatulips_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eatulips", stage: 3 }
        }
    },
    earabbit_3: {
        trigger: { unit: { code: "earabbit", stage: 0 } },
        tutorial: "earabbit_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "earabbit", stage: 3 }
        }
    },
    eabread_3: {
        trigger: { unit: { code: "eabread", stage: 0 } },
        tutorial: "eabread_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eabread", stage: 3 }
        }
    },
    eabell_3: {
        trigger: { unit: { code: "eabell", stage: 0 } },
        tutorial: "eabell_3",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eabell", stage: 3 }
        }
    },
    eaunicorn_1: {
        trigger: { unit: { code: "eaunicorn", stage: 0 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eaunicorn", stage: 1 }
        }
    },
    eaunicorn_2: {
        trigger: { unit: { code: "eaunicorn", stage: 1 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eaunicorn", stage: 2 }
        }
    },
    eaunicorn_3: {
        trigger: { unit: { code: "eaunicorn", stage: 2 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eaunicorn", stage: 3 }
        }
    },
    eaunicorn_4: {
        trigger: { unit: { code: "eaunicorn", stage: 3 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eaunicorn", stage: 4 }
        }
    },
    eaunicorn_5: {
        trigger: { unit: { code: "eaunicorn", stage: 4 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eaunicorn", stage: 5 }
        }
    },
    eaunicorn_6: {
        trigger: { unit: { code: "eaunicorn", stage: 5 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eaunicorn", stage: 6 }
        }
    },
    eaunicorn_7: {
        trigger: { unit: { code: "eaunicorn", stage: 6 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eaunicorn", stage: 7 }
        }
    },
    eaunicorn_8: {
        trigger: { unit: { code: "eaunicorn", stage: 7 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eaunicorn", stage: 8 }
        }
    },
    eaunicorn_9: {
        trigger: { unit: { code: "eaprince", stage: 0 } },
        quest: {
            type: Map2d.BUILD,
            unit: { code: "eaunicorn", stage: 9 }
        }
    },
    eacustomerbridge0a_1: {
        trigger: { unit: { code: "eacustomerbridge0a", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "eacustomerbridge0a", stage: 1 }
        }
    },
    eacustomermain0a_1: {
        trigger: { unit: { code: "eacustomermain0a", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "eacustomermain0a", stage: 1 }
        }
    },
    eaproduct0_0: {
        trigger: { unit: { code: "eaproducer0", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "eaproduct0", stage: 0 }
        }
    },
    eacustomerbridge0b_0: {
        trigger: { unit: { code: "eacustomermain0a", stage: 1 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "eacustomerbridge0b", stage: 0 }
        }
    },
    eacustomerbridge0b_1: {
        trigger: { unit: { code: "eacustomerbridge0b", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "eacustomerbridge0b", stage: 1 }
        }
    },
    eacustomermain0b_0: {
        trigger: { unit: { code: "eacustomerbridge0b", stage: 1 } },
        quest: {
            type: Map2d.OPEN_UNIT,
            unit: { code: "eacustomermain0b", stage: 0 }
        }
    },
    eacustomermain0b_1: {
        trigger: { unit: { code: "eacustomermain0b", stage: 0 } },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "eacustomermain0b", stage: 1 }
        }
    }
};