/**
 * Created by vladislav on 31/10/2022
 */

var CustomerView = function (customer, unitView) {
    this.unitView = unitView;
    this.customer = customer;

    this.restoreState();
};

CustomerView.prototype.restoreState = function (animated) {
    var type = this.chooseAdditionalView();
    if (type) {
        this.createAdditionalView(type, animated);
    } else {
        this.removeAdditionalViews(animated);
    }

    if (this.customer.isProcessingCustomOrder()) {
        this.setHangerAnimation();
    } else {
        this.setMainAnimation();
    }

    this.updateVisibility();
};

CustomerView.prototype.chooseAdditionalView = function () {
    if (this.customer.getCookingRecipe() && this.customer.getCookingTimeLeft()) {
        if (this.customer.withOrdersWindow) {
            return "waiting";
        } 
        return "timer";
    } if (!cleverapps.config.demoMode && this.customer.getCurrentRecipe()
        && !((cleverapps.windows.currentWindow() instanceof ExchangeWindow) && cleverapps.windows.currentWindow().customer === this.customer)) {
        if (this.customer.withOrdersWindow) {
            if (this.customer.canCook()) {
                return "exclamation";
            } 
            return "exclamation_off";
        } 
        return "ingredients";
    }
};

CustomerView.prototype.setMoveAnimation = function (direction) {
    this.unitView.hideAdditionalViews();

    var animationName = "walk_" + direction;

    if (this.unitView.sprite.hasAnimation(animationName)) {
        this.unitView.sprite.setAnimation(0, animationName, true);
    }
};

CustomerView.prototype.createAdditionalView = function (type, animated) {
    if (this.unitView.getAdditionalView(type)) {
        return;
    }

    this.removeAdditionalViews();

    var infoView;

    switch (type) {
        case "exclamation":
            infoView = this.createPrizeView({ sprite: bundles.merge.frames.prize_exclamation });
            break;
        case "exclamation_off":
            infoView = this.createPrizeView({ sprite: bundles.merge.frames.prize_exclamation_off });
            break;
        case "waiting":
            infoView = this.createPrizeView({ waiting: true });
            break;
        case "ingredients":
            infoView = this.createIngredientsView();
            break;
        case "timer":
            infoView = this.createTimer();
            break;
    }

    this.unitView.createAdditionalView(type, infoView);

    if (animated) {
        var additionalView = this.unitView.getAdditionalView(type);
        additionalView.animateAppear();
    }
};

CustomerView.prototype.removeAdditionalViews = function (animated) {
    this.unitView.removeAdditionalView("exclamation", !animated);
    this.unitView.removeAdditionalView("exclamation_off", !animated);
    this.unitView.removeAdditionalView("ingredients", !animated);
    this.unitView.removeAdditionalView("timer", !animated);
    this.unitView.removeAdditionalView("waiting", !animated);

    delete this.button;
};

CustomerView.prototype.createIngredientsView = function () {
    var recipe = this.customer.getCurrentRecipe();

    var infoView = new IngredientsInfoView({ recipe: recipe, customer: this.customer });
    infoView.setPositionRound(this.unitView.width / 2, this.unitView.height + cleverapps.styles.InfoView.dy);
    infoView.setAnchorPoint(0.5, 0);

    cleverapps.UI.onClick(infoView, this.unitView.handleClick.bind(this.unitView));

    return infoView;
};

CustomerView.prototype.createPrizeView = function (options) {
    var prizeView = new PrizeView(options, {
        onClick: this.customer.unit.handleClick.bind(this.customer.unit),
        unit: this.customer.unit
    });
    prizeView.setPositionRound(this.unitView.width / 2, this.unitView.height);

    return prizeView;
};

CustomerView.prototype.createTimer = function () {
    var styles = cleverapps.styles.CustomerView;

    var cookingRecipe = this.customer.getCookingRecipe();
    var cookingTimeLeft = this.customer.getCookingTimeLeft();

    var countdown = new cleverapps.CountDown(cookingTimeLeft, {
        onTick: this.updateBtn.bind(this)
    });

    var timer = new cleverapps.CountDownView(countdown, {
        font: cleverapps.styles.FONTS.COMPONENT_TIMER_TEXT,
        icon: bundles.timer_merge.frames.timer_png,
        bar: {
            type: ScaledProgressBar.Types.blue,
            width: styles.timer.width,
            fullValue: cookingRecipe.getCookingDuration(),
            reversed: false
        }
    });
    timer.setPositionRound(styles.timer);

    if (!this.customer.withOrdersWindow) {
        var button = this.button = new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            text: "$$" + SpeedUpWindow.CalcPrice(cookingTimeLeft) + ">>",
            type: cleverapps.styles.UI.Button.Images.button_blue,
            onClicked: this.speedUp.bind(this)
        });
        this.updateBtn(cookingTimeLeft);
        button.setPositionRound(styles.button);
        timer.addChild(button);
    }

    return timer;
};

CustomerView.prototype.speedUp = function () {
    if (this.button) {
        var price = SpeedUpWindow.CalcPrice(this.customer.getCookingTimeLeft());
        if (cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.CUSTOMER, price)) {
            this.customer.enjoy();
        }
    }
};

CustomerView.prototype.updateBtn = function (timeLeft) {
    if (this.button) {
        timeLeft = timeLeft !== undefined ? timeLeft : this.customer.getCooldownTimeLeft();
        var price = SpeedUpWindow.CalcPrice(timeLeft);
        if (this.button.speedPrice !== price) {
            this.button.speedPrice = price;
            this.button.setString((price ? ("$$" + price) : "UseGoldButton.free") + TextWithIcon.ICONS_BY_NAME.speedup);
        }
    }
};

CustomerView.prototype.collectUnitAnimation = function (unit, callback) {
    var collectible = unit.findComponent(Collectible);
    if (collectible) {
        collectible.onFeed(this.unitView, callback);
    } else {
        var unitView = unit.onGetView();
        if (unitView) {
            this.collectAction = unitView.runAction(new cc.Sequence(
                StandartAnimations.animateCollect(unitView, this.unitView, { scale: 0.6 }),
                new cc.RemoveSelf()
            )).setFinalize(callback);
        } else {
            callback();
        }
    }
};

CustomerView.prototype.setHangerAnimation = function () {
    if (this.unitView.sprite instanceof cleverapps.Spine && this.unitView.sprite.hasAnimation("hungry")) {
        this.unitView.sprite.setCompleteListener();
        this.unitView.sprite.setAnimation(0, "hungry", true);
        this.animatingHunger = true;
    }
};

CustomerView.prototype.setMainAnimation = function () {
    var heroTutorial = this.customer.unit.findComponent(HeroTutorial);

    if (this.unitView.sprite instanceof cleverapps.Spine && this.animatingHunger && (!heroTutorial || !heroTutorial.isTutorialRunning)) {
        UnitView.applyDefaultAnimation(this.unitView.sprite, this.customer.unit);
    }
};

CustomerView.prototype.updateVisibility = function () {
    var tutorialTargets = Merge.currentMerge.tutorial.getStepTargets();

    if (Merge.currentMerge.tutorial.isActive() && tutorialTargets && !tutorialTargets.some(function (target) {
        return Unit.Equals(target, this.customer.unit);
    }, this)) {
        this.customer.unit.hideAdditionalViews();
    } else {
        this.customer.unit.showAdditionalViews();
    }
};

CustomerView.prototype.clearAnimations = function () {
    if (this.collectAction && !this.collectAction.isDone()) {
        this.unitView.stopAction(this.collectAction);
        delete this.collectAction;
    }

    if (this.walkingAction && !this.walkingAction.isDone()) {
        this.unitView.stopAction(this.walkingAction);
        delete this.walkingAction;
    }
};

cleverapps.styles.CustomerView = {
    distance: 600,

    button: {
        height: 50,
        width: 130,
        x: { align: "center" },
        y: { align: "bottom", dy: -52 }
    },

    timer: {
        x: { align: "center" },
        y: { align: "bottom", dy: 40 },
        width: 140
    }
};