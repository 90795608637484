/**
 * Created by spepa on 16.11.2022
 */

var Landmarks = function () {
    this.pending = {};
    this.received = [];
};

Landmarks.prototype.process = function () {
    this._giveMissed();
    this.processFresh();
};

Landmarks.prototype.processFresh = function () {
    if (cleverapps.focusManager.isFocused() || !cleverapps.environment.isMainScene() || !Families.landmark) {
        return;
    }

    var location = cleverapps.meta.getSelectedLocation();
    if (location.isMain()) {
        var education;
        var opened = cleverapps.unitsLibrary.listOpened("landmark");
        if (opened.length === 1) {
            education = this.getFirstOneEd();
        }
        if (opened.length === 2) {
            education = this.getReplaceEd();
        }

        if (education) {
            cleverapps.focusManager.display({
                focus: "LandmarksEducation",
                action: education
            });
            return;
        }
    }

    while (this.received.length) {
        var landmarkData = this.received.shift();
        if (landmarkData.dialogue) {
            this.showDialogue(landmarkData.dialogue);
            return;
        }
    }
};

Landmarks.prototype.canDeploy = function (unit) {
    if (this.findLocation()) {
        return this.listDeployable().map(Unit.GetKey).includes(Unit.GetKey(unit));
    }
    return false;
};

Landmarks.prototype.showDialogue = function (steps) {
    cleverapps.focusManager.display({
        focus: "LandmarkReceived",
        action: function (f) {
            var dialogue = new Dialogue(steps, {
                showUp: true,
                autoClose: true
            });

            dialogue.on("afterClose", f);
            new DialogueView(dialogue);
        }
    });
};

Landmarks.prototype.listDeployed = function () {
    var deployed = [];
    var region = Map2d.currentMap.regions.landmarks;
    if (region) {
        for (var i = 0; i < region.positions.length; i++) {
            var landmark = Map2d.currentMap.getUnit(region.positions[i].x, region.positions[i].y);
            if (landmark && landmark.getType() === "landmark") {
                deployed.push(landmark);
            }
        }
    }
    return deployed;
};

Landmarks.prototype.listDeployable = function () {
    var code = cleverapps.unitsLibrary.listCodesByType("landmark")[0];
    var open = cleverapps.unitsLibrary.listOpened(code);
    var deployed = this.listDeployed();
    return cleverapps.substract(open, deployed, function (unit) {
        return Unit.GetKey(unit);
    });
};

Landmarks.prototype.findLocation = function () {
    var locations = Map2d.currentMap.regions.landmarks && Map2d.currentMap.regions.landmarks.positions;
    if (!locations) {
        return;
    }
    locations = locations.filter(function (loc) {
        return !Map2d.currentMap.getFog(loc.x, loc.y);
    });

    var getOrder = function (location) {
        var unit = Map2d.currentMap.getUnit(location.x, location.y);
        if (unit && unit.getType() === "landmarkspot") {
            return 0;
        }
        return !unit ? 1 : 2;
    };

    return locations.sort(function (a, b) {
        return getOrder(a) - getOrder(b);
    })[0];
};

Landmarks.prototype.construct = function (data, location) {
    location = location || this.findLocation();
    var curUnit = Map2d.currentMap.getUnit(location.x, location.y);
    if (curUnit) {
        (curUnit.findComponent(Landmark) || curUnit.findComponent(LandmarkSpot)).onRemove();
        curUnit.remove(true);
    }

    var newUnit = new Unit({
        code: data.code,
        stage: data.stage
    });
    newUnit.setPosition(location.x, location.y);
    Map2d.currentMap.add(Map2d.LAYER_UNITS, newUnit.x, newUnit.y, newUnit);
    Map2d.currentMap.onAddUnit(newUnit.x, newUnit.y, newUnit);
    (newUnit.findComponent(Landmark) || newUnit.findComponent(LandmarkSpot)).onCreate();

    return newUnit;
};

Landmarks.prototype.replaceActions = function (unit) {
    return [
        function (f) {
            if (unit.code === "landmarkspot") {
                var newUnit = this.listDeployable()[0];
                if (newUnit) {
                    unit = this.construct(newUnit, { x: unit.x, y: unit.y });
                }
            }
            f();
        }.bind(this),

        function (f) {
            Map2dScroller.currentScroller.zoomIn(unit, {
                position: cleverapps.UI.DOCK_LEFT | cleverapps.UI.DOCK_RIGHT,
                callback: f
            });
        },

        function (f) {
            var wasShown = cleverapps.dataLoader.load(DataLoader.TYPES.LANDMARK_INFO);
            if (wasShown) {
                f();
                return;
            }
            cleverapps.dataLoader.save(DataLoader.TYPES.LANDMARK_INFO, true);
            new GuideWindow(this.getGuideWindowOptions());
            cleverapps.focusManager.onceNoWindowsListener = f;
        }.bind(this),

        function (f) {
            Map2d.currentMap.highlightUnit(unit, f);
        },

        function (f) {
            new LandMarkWindow(unit.findComponent(Landmark) || unit.findComponent(LandmarkSpot));
            cleverapps.focusManager.onceNoWindowsListener = f;
        },

        function (f) {
            Map2dScroller.currentScroller.zoomOut();
            Map2d.currentMap.unhighlightUnit();
            f();
        }
    ];
};

Landmarks.prototype.getFirstOneEd = function () {
    if (this.listDeployed().length > 0) {
        return;
    }

    var location = this.findLocation();
    var curUnit = location && Map2d.currentMap.getUnit(location.x, location.y);
    if (!curUnit || !curUnit.findComponent(LandmarkSpot)) {
        return;
    }

    return function (f) {
        var landmark = this.construct(cleverapps.unitsLibrary.listOpened("landmark")[0], location);
        cleverapps.focusManager.compound(f, this.replaceActions(landmark));
    }.bind(this);
};

Landmarks.prototype.getReplaceEd = function () {
    if (cleverapps.forces.isShown(Forces.SECOND_LANDMARK.id)) {
        return;
    }
    var deployed = this.listDeployed()[0];
    if (!deployed) {
        cleverapps.forces.saveForce(Forces.SECOND_LANDMARK.id);
        return this.getFirstOneEd();
    }

    var newUnit = this.listDeployable()[0];
    var newLandmark = this.construct(newUnit, this.findLocation());

    return function (f) {
        cleverapps.forces.saveForce(Forces.SECOND_LANDMARK.id);
        cleverapps.focusManager.compound(f, this.replaceActions(newLandmark));
    }.bind(this);
};

Landmarks.prototype.listWindowLandmarks = function () {
    var landmarks = [];

    var location = cleverapps.meta.getSelectedLocation();
    var landmarksOnMap = Landmarks.ONMAP[location.locationId];
    if (landmarksOnMap) {
        landmarks = landmarks.concat(landmarksOnMap);
    }

    Object.keys(Landmarks.WORLDS).forEach(function (locationId) {
        if (cleverapps.meta.isLocationAvailable(locationId)) {
            landmarks = landmarks.concat(Landmarks.WORLDS[locationId].landmarks);
        }
    });

    landmarks = landmarks.concat(Landmarks.LEGACY);

    landmarks = landmarks.filter(function (landmark) {
        return landmark.targetExpedition === location.locationId
            && (!this.isUnattainable(landmark) || cleverapps.unitsLibrary.isOpened(landmark));
    }.bind(this));

    return landmarks.sort(function (landmark1, landmark2) {
        return landmark1.stage < landmark2.stage ? -1 : 1;
    });
};

Landmarks.prototype.listActiveLandmarks = function () {
    var landmarks = [];

    var data = Landmarks.WORLDS[cleverapps.meta.selectedLocationId()];
    if (!data) {
        return landmarks;
    }

    if (data.fog && !Map2d.currentMap.fogs.isOpened(data.fog)) {
        return landmarks;
    }

    if (data.target && !cleverapps.unitsLibrary.isOpened(data.target)) {
        return landmarks;
    }

    for (var i = 0; i < data.landmarks.length; ++i) {
        var landmarkData = data.landmarks[i];

        if (this.isUnattainable(landmarkData)) {
            continue;
        }

        if (landmarkData.feature && !cleverapps.eventManager.isActive(landmarkData.feature)) {
            continue;
        }

        if (this.isLandmarkGathered(landmarkData)) {
            continue;
        }

        landmarks.push(landmarkData);
    }

    return landmarks;
};

Landmarks.prototype.canGiveLandmark = function (landmarkData) {
    if (this.isLandmarkGathered(landmarkData) || this.isUnattainable(landmarkData)) {
        return false;
    }

    return landmarkData.donors.every(function (donor) {
        return Families[donor] && this.isDonorOpened(donor);
    }.bind(this));
};

Landmarks.prototype.isActiveDonor = function (donor) {
    var landmarkData = this.findDonorLandmark(donor);
    if (!landmarkData) {
        return false;
    }

    if (landmarkData.feature && !cleverapps.eventManager.isActive(landmarkData.feature)) {
        return false;
    }

    if (this.isLandmarkGathered(landmarkData)) {
        return false;
    }

    return !this.isDonorOpened(donor.code);
};

Landmarks.prototype.isDonorOpened = function (donorCode) {
    return cleverapps.unitsLibrary.isOpened(UnitsLibrary.LastStage(donorCode));
};

Landmarks.prototype.isLandmarkGathered = function (landmarkData) {
    return UnitsLibraryHelper.GetInstance(landmarkData.targetExpedition).isOpened(landmarkData);
};

Landmarks.prototype.getMergeBonusUnit = function (unit) {
    var donor = { code: "thanksgiving", stage: 0 };

    if (unit.code !== donor.code && unit.stage > 0 && this.isActiveDonor(donor)) {
        return donor;
    }
};

Landmarks.prototype.needToReplaceDonor = function (donor) {
    var landmark = this.findDonorLandmark(donor);
    if (landmark && landmark.feature) {
        return !this.isActiveDonor(donor);
    }
};

Landmarks.prototype.getDonorReplacer = function (donor) {
    var landmark = this.findDonorLandmark(donor);
    if (landmark && this.needToReplaceDonor(donor)) {
        return UnitsLibrary.LastStage(donor.code).stage === donor.stage ? { code: "seasonchest", stage: 2 } : { code: "coins", stage: 0 };
    }
};

Landmarks.prototype.getTimeLeft = function (landmarkData) {
    if (landmarkData.feature) {
        var event = cleverapps.eventManager.getFeatureEvent(landmarkData.feature);
        return Math.max(0, event && event.getTimeLeft() || 0);
    }

    return cleverapps.meta.getSelectedLocation().getTimeLeft();
};

Landmarks.prototype.getPendingProgress = function (landmarkData) {
    var key = Unit.GetKey(landmarkData);
    return this.pending[key] || 0;
};

Landmarks.prototype.getGuideWindowOptions = function (landmarkData) {
    return landmarkData && landmarkData.guide
        || cleverapps.skins.getSlot("landmarkGuideOptions")
        || { name: "LandmarkGuideWindow", bundle: bundles.landmarkguidewindow };
};

Landmarks.prototype.findDonorLandmark = function (donor) {
    var data = Landmarks.WORLDS[cleverapps.meta.selectedLocationId()];
    if (!data) {
        return;
    }

    if (data.fog && !Map2d.currentMap.fogs.isOpened(data.fog)) {
        return;
    }

    if (data.target && !cleverapps.unitsLibrary.isOpened(data.target)) {
        return;
    }

    for (var i = 0; i < data.landmarks.length; ++i) {
        var landmark = data.landmarks[i];
        if (landmark.donors.indexOf(donor.code) !== -1) {
            return landmark;
        }
    }
};

Landmarks.prototype.isOnMap = function (landmarkData) {
    var data = Landmarks.ONMAP[landmarkData.expedition];
    return data && data.some(function (landmark) {
        return Unit.IsApplicable(landmarkData, landmark);
    });
};

Landmarks.prototype.withOneDonor = function (landmarkData) {
    return landmarkData.donors && landmarkData.donors.length === 1;
};

Landmarks.prototype.isUnattainable = function (landmarkData) {
    return landmarkData.donors && landmarkData.donors.length === 0;
};

Landmarks.prototype.calcProgress = function (landmarkData, shown) {
    var pending = shown ? this.pending[Unit.GetKey(landmarkData)] || 0 : 0;

    if (this.withOneDonor(landmarkData)) {
        var donorCode = landmarkData.donors[0];
        var opened = cleverapps.unitsLibrary.getLastOpenStage(donorCode);
        var total = UnitsLibrary.ListByCode(donorCode).length;

        return opened === undefined ? 0 : Math.round((opened - pending + 1) * 100 / total);
    }

    total = landmarkData.donors.length;
    opened = landmarkData.donors.filter(function (donorCode) {
        return this.isDonorOpened(donorCode);
    }.bind(this)).length;

    return Math.round((opened - pending) * 100 / total);
};

Landmarks.prototype.increasePendingProgress = function (donor) {
    var landmark = this.findDonorLandmark(donor);
    if (!landmark) {
        return;
    }

    if (this.withOneDonor(landmark) ? donor.stage === cleverapps.unitsLibrary.getLastOpenStage(donor.code) : donor.stage === UnitsLibrary.LastStage(donor.code).stage) {
        var key = Unit.GetKey(landmark);
        this.pending[key] = (this.pending[key] || 0) + 1;
        cleverapps.sideBar.resetByClassName(LandmarkDonorIcon);
    }
};

Landmarks.prototype.clearPendingProgress = function (landmarkData) {
    this.pending[Unit.GetKey(landmarkData)] = 0;
};

Landmarks.prototype.landmarkReceived = function (landmarkData) {
    this.received.push(landmarkData);
};

Landmarks.prototype._restoreOnMap = function () {
    var currentPage = cleverapps.meta.selectedLocationId();
    if (Landmarks.ONMAP[currentPage]) {
        var onMap = {}, onMapOpen = {};
        Landmarks.ONMAP[currentPage].forEach(function (landmark) {
            onMap[landmark.code] = onMap[landmark.code] || [];
            onMap[landmark.code].push(landmark.stage);
        });

        var fakeUnits = Map2d.currentMap.fogs.fakeUnits;
        for (var x in fakeUnits) {
            for (var y in fakeUnits[x]) {
                var fake = fakeUnits[x][y];
                if (onMap[fake.code] && onMap[fake.code].includes(fake.stage) && !Map2d.currentMap.getFog(fake.x, fake.y)) {
                    onMapOpen[fake.code] = onMapOpen[fake.code] || [];
                    onMapOpen[fake.code].push(fake.stage);
                }
            }
        }

        for (var code in onMapOpen) {
            onMapOpen[code].forEach(function (stage) {
                cleverapps.unitsLibrary.openUnit({ code: code, stage: stage });
            });
        }
    }
};

Landmarks.prototype._restorePreview = function () {
    var fakeUnits = Map2d.currentMap.fogs.fakeUnits;
    for (var x in fakeUnits) {
        for (var y in fakeUnits[x]) {
            var fake = fakeUnits[x][y];
            if (fake.code === "landmarkpreview" && !Map2d.currentMap.getFog(fake.x, fake.y)) {
                var curUnit = Map2d.currentMap.getUnit(fake.x, fake.y);
                if (!curUnit || !Unit.IsApplicable(curUnit, fake)) {
                    var unit = new Unit(fake);
                    unit.move(fake.x, fake.y);
                    Map2d.currentMap.onAddUnit(unit.x, unit.y, unit);
                    Map2d.currentMap.onUnitAvailable(unit);
                }
            }
        }
    }
};

Landmarks.prototype._restore = function () {
    var map = Map2d.currentMap;
    var regions = map.regions.landmarks && map.regions.landmarks.positions;
    if (!regions || !Families.landmarkspot) {
        return;
    }

    map.listAvailableUnits([{ type: "landmark" }, { type: "landmarkspot" }]).forEach(function (unit) {
        var inRegion = regions.some(function (region) {
            return unit.x === region.x && unit.y === region.y;
        });
        if (!inRegion) {
            unit.remove();
        }
    });

    regions.forEach(function (region) {
        if (map.getFog(region.x, region.y)) {
            return;
        }
        cleverapps.unitsLibrary.openUnit({ code: "landmarkspot", stage: 0 });

        var unit = map.getUnit(region.x, region.y);
        if (!unit || ["landmarkspot", "landmark"].indexOf(unit.getType()) === -1) {
            unit = new Unit({ code: "landmarkspot", stage: 0 });
            unit.move(region.x, region.y);
            map.onAddUnit(region.x, region.y, unit);
        }
    });
};

Landmarks.prototype.restoreLandmarks = function () {
    if (!cleverapps.gameModes.skipRestoreLandmarks) {
        this._restoreOnMap();
        this._restorePreview();
        this._restore();
    }
};

Landmarks.prototype._giveMissed = function () {
    if (this.missedGiven || cleverapps.focusManager.isFocused()) {
        return;
    }
    this.missedGiven = true;

    var data = Landmarks.WORLDS[cleverapps.meta.selectedLocationId()];
    if (data) {
        var landmarks = data.landmarks.filter(this.canGiveLandmark.bind(this));
        if (landmarks.length) {
            cleverapps.focusManager.display({
                focus: "LandmarksEducation",
                action: function (f) {
                    new RewardWindow({ units: landmarks }, { toMainWorld: true });
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        }
    }
};

Landmarks.prototype.openLandmark = function (landmarkData) {
    if (!landmarkData.targetExpedition) {
        cleverapps.throwAsync("no target expedition page for landmark " + landmarkData.code + " " + landmarkData.stage);
        return;
    }

    UnitsLibraryHelper.GetInstance(landmarkData.targetExpedition).openUnit(landmarkData);
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.LANDMARKS.OPEN + landmarkData.code + "_" + landmarkData.stage);
};

Landmarks.getForce = function (expedition) {
    var data = Landmarks.WORLDS[expedition];
    return data && data.force;
};

Landmarks.clearForce = function (expedition) {
    var force = Landmarks.getForce(expedition);
    if (force) {
        cleverapps.forces.clearForce(force.id);
    }
};

Landmarks.getLandmarkData = function (stage) {
    for (var expedition in Landmarks.WORLDS) {
        var data = Landmarks.WORLDS[expedition];
        for (var i = 0; i < data.landmarks.length; ++i) {
            var landmark = data.landmarks[i];
            if (landmark.stage === stage) {
                return landmark;
            }
        }
    }
};

Landmarks.initialize = function () {
    for (var expedition in Landmarks.WORLDS) {
        var data = Landmarks.WORLDS[expedition];

        for (var i = 0; i < data.landmarks.length; ++i) {
            var landmark = data.landmarks[i];

            landmark.expedition = expedition;
            landmark.donors = cleverapps.toArray(landmark.donors);
        }
    }

    for (expedition in Landmarks.ONMAP) {
        data = Landmarks.ONMAP[expedition];

        for (i = 0; i < data.length; ++i) {
            landmark = data[i];

            landmark.expedition = expedition;
        }
    }
};

Landmarks.WORLDS = {
    main: {
        target: { code: "landmarkspot", stage: 0 },
        landmarks: [
            {
                code: "landmark",
                stage: 10,
                targetExpedition: "main",
                feature: "thanksgiving",
                donors: "thanksgiving",
                guide: {
                    name: "thanksgivingguidewindow",
                    bundle: bundles.thanksgivingguidewindow
                }
            }
        ]
    },

};

Landmarks.ONMAP = {};
Landmarks.LEGACY = [
    {
        code: "landmark",
        stage: 8,
        targetExpedition: "main",
        donors: []
    },
    {
        code: "landmark",
        stage: 14,
        targetExpedition: "main",
        donors: []
    },
    {
        code: "landmark",
        stage: 3,
        targetExpedition: "main",
        donors: []
    }, {
        code: "landmark",
        stage: 4,
        targetExpedition: "main",
        donors: []
    }, {
        code: "landmark",
        stage: 12,
        targetExpedition: "main",
        donors: []
    },
    {
        code: "landmark",
        stage: 13,
        targetExpedition: "main",
        donors: []
    },
    {
        code: "landmark",
        stage: 9,
        targetExpedition: "main",
        donors: []
    }
];

