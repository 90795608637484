/**
 * Created by r4zi4l on 26.05.2021
 */

var SellUnitWindow = CleverappsWindow.extend({
    onWindowLoaded: function (unit) {
        this.unit = unit;

        this._super({
            name: "SellUnitWindow",
            title: cleverapps.unitsLibrary.getUnitName(unit),
            closeButton: true,
            content: this.createContent()
        });
    },

    createContent: function () {
        var styles = cleverapps.styles.SellUnitWindow;

        var icon = this.createIcon();

        var textMessage, buttonText;
        var price = Unit.CalcSellPrice(this.unit);
        if (price > 0) {
            textMessage = "SellUnitWindow.text.sell";
            buttonText = "%%" + price;
        } else {
            textMessage = "SellUnitWindow.text.remove";
            buttonText = "Remove";
        }

        var sellText = cleverapps.UI.generateOnlyText(textMessage, cleverapps.styles.FONTS.SELLUNITWINDOW_TEXT);
        sellText.setAnchorPoint(0.5, 0.5);
        sellText.setDimensions(styles.text.width, 0);
        sellText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var currency = this.unit.getSellCurrency();

        var sellButton = new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            type: cleverapps.styles.UI.Button.Images.button_blue,
            text: buttonText,
            icons: {
                "%%": RewardTypes[currency].getIcon()
            },
            onClicked: function () {
                this.unit.sell();
                this.close();
            }.bind(this)
        });

        return new cleverapps.Layout([icon, sellText, sellButton], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createIcon: function () {
        var styles = cleverapps.styles.SellUnitWindow.icon;

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize2(styles);

        var icon = new UnitOnCell(this.unit);
        icon.setScale(styles.scale);
        icon.setPositionRound(container.width / 2, container.height / 2);
        container.addChild(icon);

        return container;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SELLUNITWINDOW_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.SellUnitWindow = {
    margin: 30,

    icon: {
        width: 280,
        height: 240,
        scale: 1.8
    },

    text: {
        width: 1000
    },

    button: {
        width: 340,
        height: 110
    }
};
