/**
 * Created by andrey on 12.02.2021.
 */

var QuestIcon = cc.Node.extend({
    ctor: function (quest, options) {
        this._super();

        this.quest = quest;
        this.options = options = options || {};
        this.images = options.images || QuestIcon.Images.default;

        var styles = cleverapps.styles.QuestIcon;

        var background = this.background = new cc.Scale9Sprite(this.images.background);
        if (this.images.backgroundScale) {
            background.setScale(this.images.backgroundScale);
        } else {
            background.setContentSize2(styles.background);
        }
        background.setPositionRound(styles.background);
        this.setContentSize2(background.width * background.scale, background.height * background.scale);
        this.setAnchorPoint(0.5, 0.5);
        this.addChild(background);

        var icon = this.icon = QuestIcon.getIconImage(quest);
        cleverapps.UI.fitToBox(icon, {
            width: this.width * 0.7,
            height: this.height * 0.7,
            maxScale: styles.icon.maxScale
        });
        icon.baseScale = icon.getScale();
        icon.setAnchorPoint(icon.anchorX, 0);

        icon.setPosition(this.width / 2, this.height * 0.2);
        this.addChild(icon);

        var progress = this.progress = this.createProgress();
        progress.setAnchorPoint(0.5, 0.5);
        progress.setPositionRound(styles.progress);
        this.addChild(progress);

        this.updateProgress(true);
    },

    onAddToTable: function () {
        var questsGroupIcon = cleverapps.sideBar.findIconByClassName(QuestsGroupIcon);
        if (questsGroupIcon) {
            var addedIcon = questsGroupIcon.onGetIconViewListener(this.quest);
            if (addedIcon) {
                questsGroupIcon.showInfo(addedIcon, {
                    duration: 0.5,
                    delay: 0.2
                });
            }
        }
    },

    createProgress: function () {
        var styles = cleverapps.styles.QuestIcon.progress;

        var background = cleverapps.UI.createScale9Sprite(this.images.title);
        background.setContentSize2(styles);

        var progressText = background.text = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.QUEST_PROGRESS_TEXT);
        progressText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        progressText.setPositionRound(styles.text);
        background.addChild(progressText);

        return background;
    },

    update: function () {
        this.background.setSpriteFrame(new cc.Sprite(this.images.background).getSpriteFrame());

        var scale = 1;
        var bg = this.images.title;

        this.baseScale = scale;
        this.setScale(scale);

        this.progress.setSpriteFrame(new cc.Sprite(bg).getSpriteFrame());
    },

    updateProgress: function (silent) {
        if (silent === true) {
            this.progress.text.setString(this.quest.progress + "/" + this.quest.goal);
            this.progress.text.fitTo({ width: this.progress.width * 0.9, height: 0 });
            return;
        }

        if (this.quest.progress !== this.quest.goal) {
            this.highlight();
        }

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.CallFunc(this.updateProgress.bind(this, true))
        ));
    },

    highlight: function (duration) {
        if (this.highlightInProgress) {
            return;
        }

        Merge.currentMerge.scrollableIconTable.scrollTo(this.quest);

        var savedBaseScale = this.baseScale;
        this.baseScale *= 1.1;

        this.highlightInProgress = true;
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.ScaleTo(0.2, 0.9 * savedBaseScale),
            new cc.ScaleTo(0.2, 1.1 * savedBaseScale).easing(cc.easeOut(3)),
            new cc.CallFunc(function () {
                var iconLight = new cleverapps.Spine(bundles.questsgroupicon.jsons.sidebar_light_json);
                iconLight.setAnchorPoint(0.5, 0.5);
                iconLight.setPositionRound(this.width / 2, this.height / 2);
                iconLight.setAnimation(0, "animation", false);
                iconLight.setCompleteListenerRemove();
                this.background.addChild(iconLight);

                if (this.quest.progress !== this.quest.goal) {
                    var questsGroupIcon = cleverapps.sideBar.findIconByClassName(QuestsGroupIcon);
                    if (questsGroupIcon) {
                        var addedIcon = questsGroupIcon.onGetIconViewListener(this.quest);
                        if (addedIcon) {
                            questsGroupIcon.showInfo(addedIcon, {
                                compact: this.quest.progress !== 0
                            });
                        }
                    }
                }
            }.bind(this)),
            new cc.DelayTime(duration || 2.2),
            new cc.ScaleTo(0.2, 0.9 * savedBaseScale),
            new cc.ScaleTo(0.2, savedBaseScale).easing(cc.easeOut(3)),
            new cc.CallFunc(function () {
                this.highlightInProgress = false;
                this.baseScale = savedBaseScale;
            }.bind(this))
        ));
    },

    getPreferredBundles: function () {
        if (this.images.preferredBundles) {
            return this.images.preferredBundles;
        }
        return ["main"];
    }
});

QuestIcon.getIconImage = function (quest) {
    return quest.getIcon();
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    QUEST_PROGRESS_TEXT: {
        name: "default",
        size: 38,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR
    }
});

cleverapps.styles.QuestIcon = {
    background: {
        x: { align: "center" },
        y: { align: "center" },
        width: 300,
        height: 300
    },

    icon: {
        maxScale: 1.6
    },

    checkmark: {
        x: { align: "right", dx: 10 },
        y: { align: "top", dy: 5 }
    },

    progress: {
        x: { align: "center" },
        y: { align: "bottom", dy: -28 },

        width: 160,
        height: 52,

        text: {
            x: { align: "center" },
            y: { align: "center", dy: 3 }
        }
    }
};

QuestIcon.Images = cleverapps.overrideTemplates({}, {
    sidebar: {
        preferredBundles: ["game"],
        backgroundScale: 1.3,
        background: bundles.sidebar.frames.icon_bg,
        title: bundles.sidebar.frames.icon_text_bg
    },

    questsgroup: {
        backgroundScale: 1,
        background: bundles.questsgroupicon.frames.quest_icon_bg,
        title: bundles.questsgroupicon.frames.icon_title
    }
});
