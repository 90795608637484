/**
 * Created by Denis Kuzin on 25 december 2024
 */

var Map2dControls = cc.Node.extend({
    ctor: function (map2dView) {
        this._super();

        var dragUnit, templateUnit, touchUnit, hoverUnit, touchFog, touchDecorator, baseTouch;

        this.debugId = "Map2dControls";

        this.setContentSize2(map2dView.width, map2dView.height);
        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound({ align: "center" }, { align: "center" });

        var releaseUnit = function (cancelled) {
            var unit = dragUnit;

            touchUnit = undefined;
            hoverUnit = undefined;
            touchFog = undefined;
            touchDecorator = undefined;
            dragUnit = undefined;
            templateUnit = undefined;
            baseTouch = undefined;

            if (unit) {
                unit.onDragEnd(cancelled);
            }
        };

        var pressHandler = cleverapps.UI.onPressed(this, function (touch) {
            if (touchUnit && InfoView.IsDisplayedFor(touchUnit)) {
                touchUnit.onClickEvent(touch);
                return true;
            }

            InfoView.CloseInfo();
        });

        var pointerHandler = new PointerHandler(this, {
            filter: function (touch) {
                var unitOrDecorator = touch && map2dView.findTouchUnitOrDecorator(touch, {
                    withDecorators: true
                });
                var unit = unitOrDecorator instanceof Unit && unitOrDecorator;
                var decorator = unitOrDecorator instanceof Map2dDecorator && unitOrDecorator;

                if (unit && !unit.isBlocked()) {
                    releaseUnit(true);
                    touchUnit = unit;
                    baseTouch = touch;
                    return true;
                }

                var fog = touch && map2dView.findTouchFog(touch);
                if (fog && fog.touchFilter()) {
                    return false;
                }

                if (fog) {
                    releaseUnit(true);
                    touchFog = fog;
                    baseTouch = touch;
                    return true;
                }

                if (decorator) {
                    releaseUnit(true);
                    touchDecorator = decorator;
                    baseTouch = touch;
                    return true;
                }

                return true;
            },

            onClick: function (touch) {
                var cell = map2dView.getCellByTouch(touch);

                map2dView.map2d.onDebugCellClick(cell);

                var orangerySelection = Map2d.currentMap.orangery && Map2d.currentMap.orangery.selected;
                if (orangerySelection && !cleverapps.focusManager.isFocused()) {
                    map2dView.map2d.createUnitFromOrangery(orangerySelection, cell);
                    return;
                }

                if (baseTouch === touch) {
                    if (touchUnit && map2dView.map2d.isUnitClick(touchUnit) && touchUnit.onClickEvent(touch)) {
                        releaseUnit();
                        return;
                    }

                    if (touchFog && map2dView.map2d.isFogClick(touchFog)) {
                        touchFog.onClickEvent(touch);
                        releaseUnit();
                        return;
                    }

                    if (touchDecorator) {
                        touchDecorator.onClickEvent(touch);
                        releaseUnit();
                        return;
                    }

                    releaseUnit();
                }

                map2dView.onCellClick(cell);
            },

            followPointer: function (touch) {
                if (baseTouch !== touch) {
                    return;
                }

                if (dragUnit) {
                    dragUnit.onFollowPointer(touch);
                }
            },

            onDragStart: function (touch) {
                if (baseTouch !== touch) {
                    return;
                }

                var game = typeof Merge !== "undefined" && Merge.currentMerge;
                var isFocused = cleverapps.focusManager.isFocused();
                var isTutorial = game && game.tutorial && game.tutorial.isActive() || cleverapps.tutorial && cleverapps.tutorial.isActive();

                if (isFocused && !isTutorial) {
                    return;
                }

                var orangery = Map2d.currentMap.orangery;

                if (orangery && orangery.selected && cleverapps.keyboardController.isPressed(cc.KEY.b)) {
                    templateUnit = orangery.selected;
                    return true;
                }

                if (touchUnit && map2dView.map2d.isUnitClick(touchUnit)) {
                    if (touchUnit.onDragStart(touch)) {
                        dragUnit = touchUnit;
                        return true;
                    }
                }
            },

            onDragMove: function (touch) {
                if (baseTouch !== touch) {
                    return;
                }

                if (dragUnit) {
                    dragUnit.onDragMove();
                    return;
                }

                if (templateUnit) {
                    var cell = map2dView.getCellByTouch(touch);
                    map2dView.map2d.createUnitFromOrangery(templateUnit, cell);
                }
            },

            onDragEnd: function (touch) {
                if (baseTouch !== touch) {
                    return;
                }

                releaseUnit();
            },

            onMouseMove: cleverapps.timeredThrottle(200, function (touch) {
                var unit = map2dView.findTouchUnitOrDecorator(touch);
                if (unit && (!hoverUnit || hoverUnit.x !== unit.x || hoverUnit.y !== unit.y)) {
                    if (hoverUnit && hoverUnit.onGetView()) {
                        hoverUnit.onGetView().outline(true);
                    }

                    var unitView = unit.onGetView();
                    if (unitView) {
                        unitView.outline();
                    }
                }

                if (!unit && hoverUnit && hoverUnit.onGetView()) {
                    hoverUnit.onGetView().outline(true);
                }

                hoverUnit = unit;
            })
        });

        map2dView.map2d.stopDragging = this.createListener(function () {
            releaseUnit(true);
        });

        this.toggleControls = this.createListener(function (enabled) {
            pointerHandler.setEnabled(enabled);
            pressHandler.setEnabled(enabled);
            releaseUnit(true);
        });

        if (typeof Merge !== "undefined") {
            Merge.currentMerge.on("stop", this.toggleControls.bind(this, false));
        }

        this.getUnitTouch = function () {
            if (dragUnit) {
                return baseTouch;
            }
        };
        this.forceMoveUnit = function () {
            if (dragUnit) {
                dragUnit.onDragMove(baseTouch);
            }
        };

        cleverapps.focusManager.registerControl("map2d", this.toggleControls);
    }
});