/**
 * Created by Andrey Popov on 09.02.21.
 */

var FakeMerge = function (levelContent) {
    Merge.currentMerge = this;

    this.counter = {
        isActive: function () {
            return true; 
        },
        registerStage: function () {},
        trigger: function () {},
        setTimeout: function () {}
    };

    this.levelContent = levelContent;

    Object.assign(this, {
        location: {},
        tutorial: {
            isActive: function () {}
        },
        orders: {
            updateOrder: function () {},
            findCanCook: function () {
                return true;
            }
        },
        gameLevel: {
            value: 0,
            getLevel: function () {
                return 1;
            },
            nextLevelThreshold: function () {
                return 1000;
            }
        },
        harvested: {
            canTakeList: function () {},
            on: function () {}
        },
        workers: {
            regular: [],
            findAssigned: function () {},
            countRegularFree: function () {
                return 2; 
            },
            countRegularTotal: function () {
                return 2; 
            },
            isBonusWorkerBuyed: function () {
                return false; 
            },
            removeInstant: function () {}
        },
        energyLottery: {
            isAvailable: function () {
                return false; 
            },
            isReady: function () {
                return false; 
            }
        },
        pushes: {
            updateFruits: function () {}
        },
        spawn: function () {
            return [];
        },
        storeSave: function () {
        },
        seasonalOperator: {
        },
        on: function () {
        },
        isExpedition: function () {
        },
        thirdElementPlanner: {
            thirdElements: []
        },
        needToReplaceWithCoins: function () {
        },
        customers: {
            removeCustomer: function () {}
        },
        isMainGame: function () {
            return false;
        },
        friendPixelsPlanner: {
            isAvailable: function () {}
        }
    });
};

FakeMerge.prototype.stop = function () {
    if (this.stopped) {
        return;
    }
    this.stopped = true;

    InfoView.Clear();
};

FakeMerge.prototype.on = function () {

};