/**
 * Created by razial on 18.03.2025.
 */

var Merge3Adapter = function (merge) {
    this.merge = merge;
    this.newGame = false;

    this.unitSaver = cleverapps.unitSavers.getInstance(this.merge.location.slot);
    this.fogSaver = cleverapps.fogSavers.getInstance(this.merge.location.slot);

    this.storedGame = Merge3Adapter.LoadInfo(this.merge.location.slot);

    console.log("Stored", this.storedGame);

    if (this.storedGame.orderIndex) {
        if (this.storedGame.orderIndex < 2) {
            this.newGame = true;
        }
    } else if (this.merge.location.locationId === "main") {
        if (cleverapps.user.level < 1) {
            this.newGame = true;
        }
    } else if (this.storedGame.expedition !== this.merge.location.locationId) {
        this.newGame = true;
    }

    if (this.isNewGame()) {
        this.storedGame = {};

        LivesHelper.Reset(this.merge.location.slot); // keep reset for backward compatability where no lives reset on meta.switchLocation
        Merge3Adapter.Reset(this.merge.location.slot);
        this.unitSaver.reset();
        this.unitSaver.resetKickOuts();
    }

    this.unitSaver.loadMap();
};

Merge3Adapter.prototype.isNewGame = function () {
    return this.newGame;
};

Merge3Adapter.prototype.load = function (key) {
    var cell = Map2d.ParseCellKey(key);
    if (cell) {
        return this.loadUnit(cell.x, cell.y);
    }

    switch (key) {
        case "fogs": return this.loadFogs();
        case "workers": return this.loadWorkers();
        case "harvested": return this.loadHarvested();
        case "quests": return this.loadQuests();
        case "size": return;
    }

    return this.storedGame[key];
};

Merge3Adapter.prototype.save = function (key, info) {
    var cell = Map2d.ParseCellKey(key);
    if (cell) {
        this.saveUnit(cell.x, cell.y, info);
        return;
    }

    switch (key) {
        case "fogs": this.saveFogs(info); return;
        case "workers": this.saveWorkers(info); return;
        case "harvested": this.saveHarvested(info); return;
        case "quests": this.saveQuests(info); return;
        case "size": return;
    }

    this.storedGame[key] = info;

    console.log("storeSave - change");
    console.log(this.storedGame);

    Merge3Adapter.SaveInfo(this.merge.location.slot, this.storedGame);
};

Merge3Adapter.prototype.remove = function (key) {
    var cell = Map2d.ParseCellKey(key);
    if (cell) {
        this.removeUnit(cell.x, cell.y);
        return;
    }

    switch (key) {
        case "fogs": return;
        case "workers": return;
        case "harvested": return;
        case "quests": return;
        case "size": return;
    }

    delete this.storedGame[key];

    console.log("storeSave - remove");
    console.log(this.storedGame);

    Merge3Adapter.SaveInfo(this.merge.location.slot, this.storedGame);
};

Merge3Adapter.prototype.loadUnit = function (x, y) {
    return this.unitSaver.loadUnit(x, y, this.merge.location.families);
};

Merge3Adapter.prototype.saveUnit = function (x, y, data) {
    this.unitSaver.update({
        x: x,
        y: y,
        data: data
    });
};

Merge3Adapter.prototype.removeUnit = function (x, y) {
    this.unitSaver.remove(x, y);
};

Merge3Adapter.prototype.loadFogs = function () {
    var storedFogs = this.fogSaver.load(this.merge.location.locationId);

    if (this.isNewGame()) {
        storedFogs = [];
    }

    return storedFogs;
};

Merge3Adapter.prototype.saveFogs = function (info) {
    this.fogSaver.update(this.merge.location.locationId, info);
};

Merge3Adapter.prototype.loadWorkers = function () {
    var stored = cleverapps.dataLoader.load(DataLoader.TYPES.WORKERS + this.merge.location.slot);

    if (this.isNewGame() || !stored || Array.isArray(stored)) {
        stored = {};
    }

    return stored;
};

Merge3Adapter.prototype.saveWorkers = function (info) {
    cleverapps.dataLoader.save(DataLoader.TYPES.WORKERS + this.merge.location.slot, info);
    cleverapps.synchronizer.addUpdateTask("workers" + this.merge.location.slot);
};

Merge3Adapter.prototype.saveHarvested = function (info) {
    cleverapps.dataLoader.save(DataLoader.TYPES.HARVESTED + this.merge.location.slot, info);
    cleverapps.synchronizer.addUpdateTask("harvested" + this.merge.location.slot);
};

Merge3Adapter.prototype.loadHarvested = function () {
    return cleverapps.dataLoader.load(DataLoader.TYPES.HARVESTED + this.merge.location.slot);
};

Merge3Adapter.prototype.saveQuests = function (info) {
    cleverapps.dataLoader.save(DataLoader.TYPES.QUESTS + this.merge.location.slot, info);

    cleverapps.synchronizer.addUpdateTask("quests" + this.merge.location.slot);
};

Merge3Adapter.prototype.loadQuests = function () {
    var stored = cleverapps.dataLoader.load(DataLoader.TYPES.QUESTS + this.merge.location.slot) || {};

    this.migrateLinear(stored);

    return stored;
};

Merge3Adapter.prototype.migrateLinear = function (stored) {
    var config = QuestsConfig[this.merge.location.locationId];

    var quests = (stored.quests || []);
    quests.forEach(function (quest) {
        if (quest.id === "woodsource_1") {
            quest.id = quest.configIndex === 1 ? "woodsource_1_1" : "woodsource_1_0";
        } else if (quest.id === "wood_9" && quest.configIndex === 1) {
            quest.id = "wheat_4";
        }
    });

    var added = {};
    stored.quests = quests.filter(function (quest) {
        if (added[quest.id]) {
            return false;
        }

        var data = config[quest.id];
        if (data && data.dynamic) {
            if (data.dynamic.filter && !data.dynamic.filter({ unit: quest.unit })) {
                return false;
            }

            if (data.dynamic.getUnit && quest.unit === undefined) {
                return false;
            }
        }

        added[quest.id] = true;
        return true;
    });
};

Merge3Adapter.prototype.removeUnitsInFog = function () {
    var removeUnits = [];
    for (var y = 0; y < this.merge.map.getHeight(); y++) {
        for (var x = 0; x < this.merge.map.getWidth(); x++) {
            var unit = this.merge.map.getUnit(x, y);
            var fog = this.merge.map.getFog(x, y);
            if (unit && fog) {
                if (unit.isMultiCellBody()) {
                    unit = unit.head;
                }

                if (unit.isMovable() && !unit.getData().important) {
                    removeUnits.push({
                        code: unit.code,
                        stage: unit.stage
                    });
                }
                if (unit.code !== "unknown" && !unit.isGrounded()) {
                    unit.remove(true);
                }
            }
        }
    }

    if (removeUnits.length) {
        this.merge.pocket.addUnits(removeUnits);
    }
};

Merge3Adapter.prototype.restoreKickOuts = function () {
    var units = this.unitSaver.loadKickOuts(this.merge.location.families);

    var remains = [];

    units.forEach(function (info) {
        if (info.data.code === "unknown") {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.RESTORE_KICKOUT + "unknown");
            return;
        }

        var empty = this.merge.map.findEmptySlot(info.x, info.y);
        if (!empty) {
            remains.push(info.data);
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.RESTORE_KICKOUT + "fail");
            return;
        }

        info.x = empty.x;
        info.y = empty.y;

        var unit = this.merge.map.loadUnit(info.x, info.y, info.data);
        if (unit) {
            this.merge.map.onUnitAvailable(unit);

            var code = unit.code;
            if (unit.getData().customer || unit.getData().important) {
                code += "_" + unit.stage;
            }

            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.RESTORE_KICKOUT + code);
        }
    }, this);

    if (remains.length > 0) {
        this.merge.pocket.addUnits(remains);
    }

    this.unitSaver.resetKickOuts();
};

Merge3Adapter.prototype.checkReset = function () {
    if (cleverapps.synchronizer._clientChecksumDifferent) {
        return;
    }

    var units = cleverapps.createSet(this.merge.map.listAvailableUnits().map(Unit.GetKey));

    var types = [cleverapps.unitsLibrary.getExpeditionUnitType("hero")];
    if (this.merge.isMainGame()) {
        types.push("resource");
    }

    var codes = cleverapps.unitsLibrary.listCodesByType(types);

    var resetedCodes = codes.filter(function (code) {
        var unit = {
            code: code,
            stage: Families[code].units.length - 1
        };
        return cleverapps.unitsLibrary.isOpened(unit) && !units[Unit.GetKey(unit)];
    });

    var reseted = resetedCodes.length > 0;

    var storeKey = DataLoader.TYPES.RESET_REPORT + this.merge.location.slot;

    if (!reseted) {
        cleverapps.dataLoader.remove(storeKey);
    } else if (!cleverapps.dataLoader.load(storeKey)) {
        cleverapps.dataLoader.save(storeKey, Date.now());

        console.log("save reset event", resetedCodes);

        cleverapps.RestClient.post("/units/saveresetevent/" + encodeURIComponent(connector.platform.getUserID()), {
            source: connector.info.source,
            codes: resetedCodes
        });

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.UNITS_RESET);
        resetedCodes.forEach(function (code) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.UNITS_RESET + "_" + code);
        });
    }
};

Merge3Adapter.prototype.checkReset2 = function () {
    if (cleverapps.synchronizer._clientChecksumDifferent) {
        return;
    }

    var resetFogs = {};

    Object.keys(this.merge.map.fogs.config).forEach(function (fogId) {
        var block = this.merge.map.fogs.blocks[fogId];
        if (block) {
            return;
        }

        var region = this.merge.map.regions[fogId];
        if (!region || !region.positions) {
            return;
        }

        region.positions.forEach(function (cell) {
            if (this.merge.map.getFog(cell.x, cell.y)) {
                return;
            }

            var fakeUnit = this.merge.map.fogs.getFakeUnit(cell.x, cell.y);
            if (cleverapps.config.subtype === "merge2" && fakeUnit && fakeUnit.grounded) {
                return;
            }
            if (fakeUnit && !fakeUnit.head && !cleverapps.unitsLibrary.isOpened(fakeUnit) && !cleverapps.unitsLibrary.isHidden(fakeUnit)) {
                resetFogs[fogId] = true;

                console.error("resets unit", fogId, fakeUnit.code, fakeUnit.stage);
            }
        }.bind(this));
    }.bind(this));

    resetFogs = Object.keys(resetFogs);

    if (resetFogs.length) {
        var locationId = cleverapps.meta.selectedLocationId();

        cleverapps.playSession.set(cleverapps.EVENTS.DEBUG.MERGE.UNITS_RESET2, true, locationId);

        resetFogs.forEach(function (fogId) {
            cleverapps.playSession.set(cleverapps.EVENTS.DEBUG.MERGE.UNITS_RESET2, true, locationId + "_" + fogId);
        });
    }
};

Merge3Adapter.ListAllLocations = function () {
    return Object.values(bundles).filter(function (bundle) {
        return bundle.meta.location;
    }).map(function (bundle) {
        return bundle.meta.location;
    });
};

Merge3Adapter.Reset = function (slot) {
    cleverapps.toArray(slot).forEach(function (slot) {
        Merge3Adapter.SaveInfo(slot, {});

        cleverapps.dataLoader.save(DataLoader.TYPES.WORKERS + slot, {});
        cleverapps.synchronizer.addUpdateTask("workers" + slot);

        cleverapps.dataLoader.save(DataLoader.TYPES.HARVESTED + slot, {});
        cleverapps.synchronizer.addUpdateTask("harvested" + slot);

        cleverapps.dataLoader.save(DataLoader.TYPES.QUESTS + slot, {});
        cleverapps.synchronizer.addUpdateTask("quests" + slot);
    });
};

Merge3Adapter.GetStoreKey = function (slot) {
    return cleverapps.config.name + connector.platform.getLocalStoragePrefix() + "_level_0x" + (slot || 0);
};

Merge3Adapter.LoadInfo = function (slot) {
    return cleverapps.dataLoader.load(Merge3Adapter.GetStoreKey(slot)) || {};
};

Merge3Adapter.SaveInfo = function (slot, info, fromServer) {
    cleverapps.dataLoader.save(Merge3Adapter.GetStoreKey(slot), info);

    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("games" + slot);
    }
};

CustomSyncers.registerBySlots("games", function (slot) {
    var episodeNo, levelNo;

    if (slot === "1" || slot === "2") { // backward compatibility since 29.06.20203
        episodeNo = slot;
    }

    var stored = Merge3Adapter.LoadInfo(slot);
    if (stored && Object.keys(stored).length > 0) {
        stored.episode = episodeNo;
        stored.level = levelNo;
    }

    return stored;
}, function (slot, serverGame) {
    if (serverGame === undefined) {
        return;
    }

    delete serverGame.episode;
    delete serverGame.level;

    Merge3Adapter.SaveInfo(slot, serverGame, true);
});

CustomSyncers.registerBySlots("workers", function (slot) {
    return cleverapps.dataLoader.load(DataLoader.TYPES.WORKERS + slot) || {};
}, function (slot, serverData) {
    cleverapps.dataLoader.save(DataLoader.TYPES.WORKERS + slot, serverData);
});

CustomSyncers.registerBySlots("harvested", function (slot) {
    return cleverapps.dataLoader.load(DataLoader.TYPES.HARVESTED + slot) || {};
}, function (slot, serverData) {
    cleverapps.dataLoader.save(DataLoader.TYPES.HARVESTED + slot, serverData);
});

CustomSyncers.registerBySlots("quests", function (slot) {
    return cleverapps.dataLoader.load(DataLoader.TYPES.QUESTS + slot);
}, function (slot, serverData) {
    cleverapps.dataLoader.save(DataLoader.TYPES.QUESTS + slot, serverData);
});
