/**
 * Created by r4zi4l on 17.06.2021
 */

var SoftFeastMissionWindow = FeastMissionWindow.extend({
    onWindowLoaded: function (mission) {
        this._super(mission, {
            pack: {
                type: "soft",
                price: 250,
                prizes: RewardsConfig.SoftFeast.offer
            },
            name: "SoftFeastMissionWindow",
            notCloseByTouchInShadow: true,
            openSound: bundles.softfeast_window.urls.feast_window_effect
        });
    },

    getPerson: function () {
        var person = {
            role: "elf",
            emotion: "happy"
        };
        if (cleverapps.config.name === "wondermerge") {
            person = "worker";
        }
        return person;
    },

    getGuideOptions: function () {
        return {
            name: "SoftFeastGuideWindow",
            title: "SoftFeastGuideWindow.title",
            bundle: bundles.softfeastguidewindow
        };
    }
});

cleverapps.styles.SoftFeastMissionWindow = {
    pack: {
        x: { align: "center", dx: -78 },
        y: { align: "center", dy: -487 }
    }
};
