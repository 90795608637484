/**
 * Created by Denis Kuzin on 16 november 2022
 */

QuestsConfig.xmas = cleverapps.clone(QuestsConfig.universal, true);

delete QuestsConfig.xmas.rpcustomerbridge4_0;
delete QuestsConfig.xmas.rpcustomerbridge4_1;
delete QuestsConfig.xmas.rpcustomermain4_0;
delete QuestsConfig.xmas.rpcustomermain4_1;
delete QuestsConfig.xmas.rpproducer4;
delete QuestsConfig.xmas.rpproduct4;

CustomerRecipes.xmas = cleverapps.clone(CustomerRecipes.universal, true);

Ruins.TIERS.xmas = cleverapps.clone(Ruins.TIERS.universal, true);

MissionTree.PRIZES.xmas = cleverapps.clone(MissionTree.PRIZES.universal, true);

GrowingsPlanner.CONFIG.xmas = cleverapps.clone(GrowingsPlanner.CONFIG.universal, true);

Landmarks.WORLDS.xmas = {
    fog: "fog3",
    force: Forces.LANDMARK_DONOR_SLOT1,
    landmarks: [
        {
            code: "landmark",
            stage: 21,
            targetExpedition: "main",
            donors: ["rpcustomermain0", "rpcustomermain1", "rpcustomermain2", "rpcustomermain3"]
        }
    ]
};

cleverapps.styles.xmas = {
    fogBlockerLock: {
        width: 100,
        height: 100,
        margin: 25,

        content: {
            width: 68,
            height: 68
        },
        position: {
            x: { align: "center" },
            y: { align: "center", dy: 50 }
        },
        text: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        icon: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        cloud: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -39 }
        },
        lock: {
            x: { align: "center" },
            y: { align: "center" }
        }
    }
};