/**
 * Created by olga on 19.09.2024
 */

var FogSaver = function (slot) {
    this.slot = slot;
};

FogSaver.prototype.load = function (locationId) {
    return FogSaver.Load(this.slot, locationId);
};

FogSaver.prototype.update = function (locationId, info) {
    if (cleverapps.config.adminMode || cleverapps.config.editorMode) {
        return;
    }

    var config = Fogs.ReadConfig(locationId);

    if (locationId !== "main") {
        info = FogSaver.toBit(info, config);
    }

    cleverapps.dataLoader.save(DataLoader.TYPES.FOGS + this.slot, info);

    cleverapps.synchronizer.addUpdateTask("fogs" + this.slot);
};

FogSaver.Load = function (slot, locationId) {
    var fogsConfig = Fogs.ReadConfig(locationId);
    var stored = cleverapps.dataLoader.load(DataLoader.TYPES.FOGS + slot);

    if (typeof stored === "number" || Array.isArray(stored) && stored.length <= FogSaver.SYNCED_NUMBERS && typeof stored[0] === "number") {
        stored = FogSaver.FromBit(stored, fogsConfig);
    }
    return stored;
};

FogSaver.FromBit = function (bit, config) {
    var ids = cleverapps.createSet(cleverapps.fromBit(bit));

    return Object.keys(config).filter(function (code) {
        return ids[config[code].id];
    }, this);
};

FogSaver.toBit = function (fogs, config) {
    var ids = fogs.map(function (fog) {
        return config[fog].id;
    }, this);
    return cleverapps.toBit(ids, FogSaver.SYNCED_BITS);
};

FogSaver.SYNCED_NUMBERS = 2;
FogSaver.SYNCED_BITS = cleverapps.SYNCED_BITS * FogSaver.SYNCED_NUMBERS;

CustomSyncers.registerBySlots("fogs", function (slot) {
    return cleverapps.dataLoader.load(DataLoader.TYPES.FOGS + slot) || [];
}, function (slot, serverData) {
    cleverapps.dataLoader.save(DataLoader.TYPES.FOGS + slot, serverData);
});
