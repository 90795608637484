/**
 * Created by Reda on 24.01.2025
 */

cleverapps.SkinManager.SKINS.spring_expedition = {
    bundle: "expedition_spring",

    slots: Object.assign({}, cleverapps.SkinManager.SKINS.rose_energy.slots, {
        unitIcon: function (unit) {
            return bundles.unit_icons_spring.frames["small_icon_family_" + unit.code];
        },
        fogAsColorForUnits: function () {
            return cleverapps.styles.china.fogAsColorForUnits;
        },
        fogBlocker: function () {
            return cleverapps.styles.spring.fogBlockerLock;
        },
        gameAudio: function () {
            return bundles.expedition_spring.urls.spring_music;
        },
        gameSceneBg: function () {
            return {
            };
        },

        wand_icon_png: bundles.buttons_inlined_icons.frames.spring_wand_icon_png,
        merge_wand_png: bundles.menubar.frames.spring_merge_wand_png,
        reward_wand_png: bundles.reward_icons.frames.spring_reward_wand_png,
        reward_wand_small_png: bundles.reward_icons.frames.spring_reward_wand_small_png,

        feed_icon_png: bundles.buttons_inlined_icons.frames.spring_feed_icon_png,

        pass_star_png: bundles.reward_icons_pass.frames.spring_pass_star_png,
        expedition_buildpass_icon_json: bundles.sidebar.jsons.spring_expedition_buildpass_icon_json,

        snail_feast_icon: bundles.reward_icons.frames.spring_snail_feast_icon,
        snail_feast_icon_json: bundles.sidebar.jsons.spring_snail_feast_icon_json,
        snailhouse_offer_json: bundles.sidebar.jsons.spring_snailhouse_offer_json
    })
};