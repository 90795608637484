/**
 * Created by andrey on 12.02.2021.
 */

var BonusWorkerWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this.state = cleverapps.adsLimits.state(AdsLimits.TYPES.WORKER);

        if (!cleverapps.rewardedAdsManager.isRewardedSupported()) {
            this.state = AdsLimits.STATE_UNAVAILABLE;
        }

        if (Map2d.currentMap.workers.isBonusWorkerBuyed() || Map2d.currentMap.workers.countRegularTotal() > Map2d.currentMap.workers.countRegularLimit()) {
            this.state = BonusWorkerWindow.STATE_BUYED;
        }

        Map2d.currentMap.workers.wantsToShowBonusWorkerWindow = false;

        this._super({
            name: "BonusWorkerWindow",
            title: "BonusWorkerWindow.title",
            content: this.createContent(),
            noBackground: true,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true
        });

        cleverapps.rewardedAdsManager.onRewardedRefresh(function () {
            if (this.watchButton) {
                var watchButton = this.createWatchButton(Product.FormatTimePeriod(Workers.ADS_BONUS_WORKER_PERIOD, true).title);
                watchButton.setPositionRound(this.watchButton);
                this.watchButton.parent.addChild(watchButton);
                this.watchButton.removeFromParent();
                this.watchButton = watchButton;
            }
        }.bind(this), this);

        cleverapps.paymentsLogic.onChangeStatus(this.updateButton.bind(this), this);

        cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");

        Map2d.currentMap.workers.onWorkerBought = this.createListener(this.close.bind(this));
    },

    updateButton: function () {
        if (this.button) {
            if (connector.payments.isConnected() || Map2d.currentMap.workers.getProduct() instanceof VirtualProduct) {
                this.button.enable();
            } else {
                this.button.disable();
            }
        }
    },

    getPerson: function () {
        return {
            role: cleverapps.config.ui === "heroes" ? "worker" : "narrator",
            skin: cleverapps.skins.getSlot("bonusWorkerPersonSkin")
        };
    },

    createInfoBlock: function () {
        var styles = cleverapps.styles.BonusWorkerWindow.infoBlock;

        var infoText;
        switch (this.state) {
            case AdsLimits.STATE_UNAVAILABLE:
            case AdsLimits.STATE_DISABLED:
                infoText = cleverapps.UI.generateOnlyText("BonusWorkerWindow.buy.info.text", cleverapps.styles.FONTS.BONUS_WORKER_WINDOW_INFO_TEXT);
                break;

            case AdsLimits.STATE_LIMITED:
            case AdsLimits.STATE_NOADS:
            case AdsLimits.STATE_READY:
                infoText = cleverapps.UI.generateOnlyText("BonusWorkerWindow.ads.info.watch", cleverapps.styles.FONTS.BONUS_WORKER_WINDOW_INFO_TEXT);
                break;

            case BonusWorkerWindow.STATE_BUYED:
                infoText = cleverapps.UI.generateOnlyText("BonusWorkerWindow.buyed.info.text", cleverapps.styles.FONTS.BONUS_WORKER_WINDOW_INFO_TEXT);
                break;
        }

        infoText.setDimensions(styles.width, 0);
        infoText.fitTo(undefined, styles.height);
        infoText.setDimensions(styles.width, styles.height);
        infoText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        infoText.setVerticalAlignment(cc.VERTICAL_TEXT_ALIGNMENT_CENTER);

        return infoText;
    },

    createTimer: function () {
        var styles = cleverapps.styles.BonusWorkerWindow.timer;

        var countDown = new cleverapps.CountDown(Map2d.currentMap.workers.bonusWorkerLeftTime());

        return new cleverapps.CountDownView(countDown, {
            font: cleverapps.styles.FONTS.BONUS_WORKER_WINDOW_TIMER_TEXT,
            background: {
                frame: bundles.workerwindow.frames.timer_substrate,
                width: styles.width,
                height: styles.height
            },
            icon: {
                frame: bundles.timer.frames.timer_icon_large,
                json: bundles.timer.jsons.timer_icon_large_json,
                animation: "animation"
            }
        });
    },

    createBuyButton: function (title) {
        var styles = cleverapps.styles.BonusWorkerWindow.button;

        this.button = new cleverapps.UI.Button({
            text: Map2d.currentMap.workers.getPriceTag(),
            width: styles.width,
            height: styles.height,
            onClicked: function () {
                Map2d.currentMap.workers.buyBonusWorker();
            }
        });

        this.updateButton();

        if (title) {
            title = cleverapps.UI.generateOnlyText(title, cleverapps.styles.FONTS.BONUS_WORKER_WINDOW_BUTTON_TITLE_TEXT);
            cleverapps.UI.fitToBox(title, {
                width: this.button.width,
                height: title.height
            });
        }

        return new cleverapps.Layout([this.button, title], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createWatchButton: function (text, timeLeft) {
        var styles = cleverapps.styles.BonusWorkerWindow.button;

        var disabled = timeLeft || cleverapps.adsLimits.state(AdsLimits.TYPES.WORKER) === AdsLimits.STATE_NOADS;

        var button = new cleverapps.UI.Button({
            text: "##Watch",
            width: styles.width,
            height: styles.height,
            disabled: disabled,
            mark: !disabled,
            type: cleverapps.styles.UI.Button.Images.button_blue,
            onClicked: function () {
                Map2d.currentMap.workers.watchAdsBonusWorker();
            }
        });

        var title = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.BONUS_WORKER_WINDOW_BUTTON_TITLE_TEXT);

        if (timeLeft) {
            var countdown = new cleverapps.CountDownView(new cleverapps.CountDown(timeLeft, {
                onFinish: this.createListener(function () {
                    new BonusWorkerWindow();
                    this.close();
                }.bind(this))
            }), {
                font: cleverapps.styles.FONTS.BONUS_WORKER_WINDOW_BUTTON_TITLE_TEXT
            });

            title = new cleverapps.Layout([title, countdown], {
                direction: cleverapps.UI.VERTICAL
            });
        }

        cleverapps.UI.fitToBox(title, {
            width: button.width,
            height: title.height
        });

        return new cleverapps.Layout([button, title], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createContent: function () {
        var animation = this.createAnimation();

        var info = this.createInfoBlock();
        var actions = this.createActionBlock();

        return new cleverapps.Layout([animation, info, actions], {
            direction: cleverapps.UI.VERTICAL,
            padding: cleverapps.styles.BonusWorkerWindow.padding
        });
    },

    createAnimation: function () {
        var styles = cleverapps.styles.BonusWorkerWindow.animation;

        var buyed = this.state === BonusWorkerWindow.STATE_BUYED;

        if (!buyed) {
            var plus = cleverapps.UI.generateImageText("+", cleverapps.styles.FONTS.BONUS_WORKER_PLUS_TEXT);
        }

        var leftWorkers = new cleverapps.Layout([this.createOneWorker(0), this.createOneWorker(1)].map(function (worker, index, workers) {
            worker.setScaleX(styles.leftWorkers.reverse ? -1 : 1);
            worker.setLocalZOrder(styles.leftWorkers.reverse ? workers.length - index : 0);
            return worker;
        }), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.leftWorkers.margin
        });

        var rightWorkers = new cleverapps.Layout([this.createOneWorker(2)], {
            direction: cleverapps.UI.HORIZONTAL
        });

        var workersAnimation = new cleverapps.Layout([leftWorkers, plus, rightWorkers].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });

        leftWorkers.children.forEach(function (child) {
            if (child.rays) {
                child.rays.replaceParentSamePlace(workersAnimation);
            }
        });

        rightWorkers.children.forEach(function (child) {
            if (child.rays) {
                child.rays.replaceParentSamePlace(workersAnimation);
            }
        });

        var current = Map2d.currentMap.workers.countRegularTotal();
        var animationDetails = new WorkersGain(current, this.state === BonusWorkerWindow.STATE_BUYED ? current : current + 1);

        return new cleverapps.Layout([workersAnimation, animationDetails], {
            direction: cleverapps.UI.VERTICAL
        });
    },

    createOneWorker: function (index) {
        var animation = new cleverapps.Spine(cleverapps.skins.getSlot("bonusWorkerWindowJson") || bundles.workerwindow.jsons.product);
        var skin = cleverapps.skins.getSlot("bonusWorkerWindowSkin") || "regular";
        if (Array.isArray(skin)) {
            skin = skin[index];
        }
        if (animation.hasSkin(skin)) {
            animation.setSkin(skin);
        }
        animation.setAnimation(0, cleverapps.skins.getSlot("bonusWorkerProductAnimation") || "animation", true);

        animation.rays = StandartAnimations.rays(animation, { skin: "blue" });

        return animation;
    },

    createActionBlock: function () {
        var styles = cleverapps.styles.BonusWorkerWindow.actionBlock;

        var text, timer, buyButton, watchButton;
        switch (this.state) {
            case AdsLimits.STATE_UNAVAILABLE:
            case AdsLimits.STATE_DISABLED:
                buyButton = this.createBuyButton();
                break;

            case AdsLimits.STATE_LIMITED:
                buyButton = this.createBuyButton(Product.FormatTimePeriod(Workers.BONUS_WORKER_PERIOD, true).title);
                buyButton.setAnchorPoint(0.5, 1);
                watchButton = this.createWatchButton("TileShop.tile.leftTime", cleverapps.adsLimits.getLimitLeftTime(AdsLimits.TYPES.WORKER));
                watchButton.setAnchorPoint(0.5, 1);
                break;

            case AdsLimits.STATE_NOADS:
            case AdsLimits.STATE_READY:
                buyButton = this.createBuyButton(Product.FormatTimePeriod(Workers.BONUS_WORKER_PERIOD, true).title);
                watchButton = this.watchButton = this.createWatchButton(Product.FormatTimePeriod(Workers.ADS_BONUS_WORKER_PERIOD, true).title);
                break;

            case BonusWorkerWindow.STATE_BUYED:
                text = cleverapps.UI.generateOnlyText("BonusWorkerWindow.buy.lefttime.text", cleverapps.styles.FONTS.BONUS_WORKER_WINDOW_INFO_TEXT);
                timer = this.createTimer();
                break;
        }

        return new cleverapps.Layout([text, timer, watchButton, buyButton].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
    },

    listBundles: function () {
        return ["workerwindow"];
    }
});

BonusWorkerWindow.STATE_BUYED = 100;

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BONUS_WORKER_WINDOW_BUTTON_TITLE_TEXT: {
        size: 38,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    BONUS_WORKER_WINDOW_TIMER_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR
    },

    BONUS_WORKER_WINDOW_INFO_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    BONUS_WORKER_PLUS_TEXT: {
        name: "big_digits",
        size: 90,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.BonusWorkerWindow = {
    padding: {
        top: 50
    },

    infoBlock: {
        width: 720,
        height: 250
    },

    animation: {
        leftWorkers: {
            reverse: true,
            margin: -40
        }
    },

    actionBlock: {
        margin: 40
    },

    timer: {
        iconOffsetX: 30,

        width: 320,
        height: 85,

        text: {
            x: 15,
            y: 2
        }
    },

    button: {
        margin: 10,
        width: 380,
        height: 130
    }
};
