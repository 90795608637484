/**
 * Created by olga on 04.03.2025
 */

var BorderTile = function (x, y) {
    this.x = x;
    this.y = y;
    this.borders = [];

    Map2d.currentMap.add(Map2d.LAYER_BORDERS, x, y, this);
};

BorderTile.prototype.addBorder = function (border) {
    this.borders.push(border);
    this.filterOverlappingTypes();
};

BorderTile.prototype.filterOverlappingTypes = function () {
    var locked = [];
    this.borders.forEach(function (item) {
        if (BorderTile.borderLocks[item.type]) {
            locked = locked.concat(BorderTile.borderLocks[item.type]);
        }
    });

    this.borders = this.borders.filter(function (item) {
        return !locked.includes(item.type);
    });

    this.borders = this.borders.sort(function (a, b) {
        var priorityA = BorderTile.bordersPriotitis[a.type] || 0;
        var priorityB = BorderTile.bordersPriotitis[b.type] || 0;
        return priorityA - priorityB;
    });


};

BorderTile.bordersPriotitis = {
    right: 1,
    inner_up_right: -1,
    inner_down_right: -1
}

BorderTile.borderLocks = {
    inner_up_left: ["up", "right"],
    inner_up_right: ["left"],
    inner_down_left: ["down"],
    inner_down_right: ["down", "left", "inner_up_right", "inner_down_left"]
};