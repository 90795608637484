/**
 * Created by mac on 12/23/20
 */

var Growing = function (unit) {
    UnitComponent.call(this, unit);
};

Growing.prototype = Object.create(UnitComponent.prototype);
Growing.prototype.constructor = Growing;

Growing.prototype.remove = function () {
    Merge.currentMerge.growingsPlanner.onRemoveGrowing();
};

Growing.prototype.spawn = function (slot) {
    this.unit.setPosition(slot.x, slot.y);
    Map2d.currentMap.add(Map2d.LAYER_UNITS, this.unit.x, this.unit.y, this.unit);
    Map2d.currentMap.onAddUnit(this.unit.x, this.unit.y, this.unit);
    Map2d.currentMap.onUnitAvailable(this.unit);
    this.unit.onAdd();
};