/**
 * Created by andrey on 10.02.2021.
 */

var Quests = function (adapter) {
    cleverapps.EventEmitter.call(this);

    this.adapter = adapter;

    this.quests = [];
    this.lastFinished = 0;

    this.config = QuestsConfig[Merge.currentMerge.location.locationId];

    if (!this.config) {
        Object.assign(this, Quests.FakeQuests);
        return;
    }

    Quests.InitConfig(cleverapps.meta.getSelectedLocation());

    cleverapps.eventBus.on("unitOpened", this.onUnitOpened.bind(this), this);
    cleverapps.eventBus.on("mapEvent", this.onMapEvent.bind(this), this);

    this.load();
};

Quests.prototype = Object.create(cleverapps.EventEmitter.prototype);
Quests.prototype.constructor = Quests;

Quests.prototype.load = function () {
    if (cleverapps.gameModes.skipQuests) {
        return;
    }

    var stored = this.adapter.load("quests") || {};

    this.quests = (stored.quests || []).filter(function (data) {
        return this.config[data.id];
    }, this).map(function (data) {
        var quest = new Quest(data);
        quest.replayTutorial();
        return quest;
    });
};

Quests.prototype.save = function () {
    this.adapter.save("quests", this.getInfo());
};

Quests.prototype.getInfo = function () {
    var quests = this.quests.map(function (quest) {
        return quest.save();
    });

    return {
        quests: quests
    };
};

Quests.prototype.finishHeroChestQuests = function () {
    this.quests.forEach(function (quest) {
        if (quest.id === "r_open_herochest" && quest.progress > 0 && !cleverapps.unitsLibrary.getCurrentHero()) {
            quest.progress = quest.goal;
        }
    }, this);
};

Quests.prototype.finishQuestsByUnit = function (unit, type) {
    var isLast = unit.isLast();
    this.quests.forEach(function (quest) {
        if (quest.unit && quest.unit.code === unit.code && (isLast || quest.unit.stage === unit.stage) && quest.type === type) {
            quest.progress = quest.goal;
        }
    });
};

Quests.prototype.isRunning = function (id) {
    return this.quests.some(function (quest) {
        return id === quest.id;
    });
};

Quests.prototype.isEmpty = function () {
    return this.quests.length === 0;
};

Quests.prototype.count = function () {
    return this.quests.length;
};

Quests.prototype.create = function (trigger) {
    if (cleverapps.gameModes.skipQuests) {
        return;
    }

    var chosenData = [];

    Object.values(this.config).forEach(function (config) {
        if (!config.trigger || this.isRunning(config.id)) {
            return;
        }

        if (config.dynamic && config.dynamic.filter && !config.dynamic.filter({ create: true, unit: trigger.unit })) {
            return;
        }

        if (config.dynamic && config.dynamic.getGoal && !config.dynamic.getGoal()) {
            return;
        }

        if (config.trigger.recipesCodes && trigger.recipe && config.trigger.recipesCodes.some(function (recipeCode) {
            return recipeCode === trigger.recipe.code;
        })) {
            var clone = cleverapps.clone(config, true);
            clone.quest.recipeCode = trigger.recipe.code;
            clone.quest.unit = { code: trigger.unit.code, stage: trigger.unit.stage };
            chosenData.push(clone);
            return;
        }

        if (config.trigger.unit && !trigger.recipe && Unit.Equals(trigger.unit, config.trigger.unit)) {
            chosenData.push(config);
            return;
        }

        if (config.trigger.fog && config.trigger.fog === trigger.fog) {
            chosenData.push(config);
            return;
        }

        if (config.trigger.quest && config.trigger.quest === trigger.quest) {
            chosenData.push(config);
        }
    }.bind(this));

    this.startQuests(chosenData);
};

Quests.prototype.startQuests = function (configs) {
    if (configs.length > 0) {
        configs.forEach(function (config) {
            var quest = new Quest(config.quest, true);
            quest.setFresh(true);
            this.quests.push(quest);
        }.bind(this));

        this.save();
        Merge.currentMerge.counter.trigger(100);
    }
};

Quests.prototype.onUnitOpened = function (unit) {
    this.onMapEvent(Map2d.OPEN_UNIT, { unit: unit });
    this.create({ unit: unit });
};

Quests.prototype.applyDynamicFilter = function () {
    var toRemove = [];
    this.quests.forEach(function (quest, index) {
        var config = this.config[quest.id];
        if (config && config.dynamic && config.dynamic.filter && !config.dynamic.filter()) {
            toRemove.push(index);
        }
    }, this);

    if (toRemove.length > 0) {
        for (var i = toRemove.length - 1; i >= 0; i--) {
            this.quests.splice(toRemove[i], 1);
        }
        this.save();
    }
};

Quests.prototype.finish = function () {
    if (cleverapps.focusManager.isFocused()) {
        return;
    }

    for (var index = 0; index < this.quests.length; index++) {
        var quest = this.quests[index];

        if (quest.dynamicFinished()) {
            quest.progress = quest.goal;
        }

        if (quest.isFinished()) {
            break;
        }
    }

    if (index === this.quests.length) {
        return;
    }

    var callback = function () {
        var quest = this.quests[index];
        this.quests.splice(index, 1);
        this.save();

        this.create({ quest: quest.id });
    }.bind(this);

    cleverapps.eventBus.trigger("taskEvent", DailyTasks.FINISH_QUEST);

    if (!quest.config.prize && cleverapps.config.name !== "hearts") {
        cleverapps.throwAsync("FinishQuest " + quest.id + " empty prize");
    }

    this.lastFinished = Date.now();
    cleverapps.focusManager.display({
        focus: "FinishQuest",
        actions: [

            function (f) {
                this.showFinishInfo(quest, function () {
                    callback();
                    f();
                });
            }.bind(this),

            function (f) {
                Merge.currentMerge.counter.setTimeout(f, 300);
            },

            function (f) {
                quest.showTutorial(f, true);
            }
        ]
    });
};

Quests.prototype.showFinishInfo = function (icon, callback) {
    var isIconEnabled = cleverapps.focusManager.isControlEnabled("QuestsGroupIcon");
    if (!isIconEnabled) {
        cleverapps.focusManager.showControlsWhileFocused("QuestsGroupIcon");
    }

    var questsGroupTable = Merge.currentMerge.scrollableIconTable;
    if (!questsGroupTable.hasIcon(icon)) {
        callback();

        var rewardsList = new RewardsList(icon.config.prize || {}, {
            toMainWorld: icon.config.toMainWorld,
            event: cleverapps.EVENTS.EARN.OTHER
        });
        rewardsList.receiveRewards();
        rewardsList.updateViewAmount();

        return;
    }

    new ActionPlayer([
        function (f) {
            questsGroupTable.scrollTo(icon, false, f);
        },

        function (f) {
            cleverapps.timeouts.setTimeout(f, isIconEnabled ? 0 : 250);
        },

        function (f) {
            var questsGroupIcon = cleverapps.sideBar.findIconByClassName(QuestsGroupIcon);
            if (questsGroupIcon) {
                questsGroupIcon.allowInfoView();
                var addedIcon = questsGroupIcon.onGetIconViewListener(icon);
                if (addedIcon) {
                    questsGroupIcon.showInfo(addedIcon, { callback: f });
                } else {
                    f();
                }
            }
        },

        function (f) {
            questsGroupTable.removeIcon(icon, f);
            callback();
        }
    ]).play();
};

Quests.prototype.getFreshQuestsBatch = function () {
    var quests = this.quests.filter(function (quest) {
        return quest.isFresh();
    });

    var storyQuests = quests.filter(function (quest) {
        return quest.getTutorial() || quest.getDialogue();
    });

    quests = cleverapps.substract(quests, storyQuests, Quest.GetId);

    quests = storyQuests.slice(0, 1).concat(quests);

    return quests.slice(0, 2);
};

Quests.prototype.processFresh = function () {
    if (cleverapps.focusManager.isFocused() || cleverapps.gameModes.skipQuests) {
        return;
    }

    var quests = this.getFreshQuestsBatch();
    var quest = quests[0];

    if (quests.length === 0) {
        return;
    }

    var units = [];

    if (quest.triggerUnit) {
        var center = Map2d.currentMap.getScreenCenterCell();
        units = Map2d.currentMap.listAvailableUnits(quest.triggerUnit).filter(function (unit) {
            return unit.isBuilt();
        }).sort(function (a, b) {
            return cc.getDistanceChebyshev(a, center) - cc.getDistanceChebyshev(b, center);
        }).slice(0, 1);
    }

    if (quest.highlightTargets) {
        units = units.concat(Map2d.currentMap.listAvailableUnits(quest.highlightTargets));
    } else if (quest.highlightTargets === false) {
        units = [];
    }

    var controls = quest.config.controls || ["MenuBarGameLevelItem", "MenuBarCoinsItem"];

    cleverapps.focusManager.display({
        focus: "StartQuest",
        control: controls,
        actions: [
            function (f) {
                Merge.currentMerge.unitStories.showUnitStory(f, quest.getDialogue(), {
                    unit: units,
                    cancelZoomOut: quest.cancelZoomOut
                });
            },

            function (f) {
                cleverapps.focusManager.hideControlsWhileFocused(controls);
                quests.forEach(function (quest) {
                    quest.setFresh(false);
                });
                this.save();

                if (quest.config.silent) {
                    f();
                } else {
                    cleverapps.focusManager.showControlsWhileFocused("QuestsGroupIcon");
                    cleverapps.timeouts.setTimeout(f, 600);
                }
            }.bind(this),

            function (f) {
                if (quest.config.silent) {
                    Merge.currentMerge.scrollableIconTable.addIcon(quest, { silent: true });
                    f();
                } else {
                    quests.forEach(function (quest, index) {
                        cleverapps.timeouts.setTimeout(function () {
                            Merge.currentMerge.scrollableIconTable.addIcon(quest, {
                                callback: function () {
                                    Merge.currentMerge.scrollableIconTable.scrollTo(quest);
                                }
                            });
                        }, index * 1200);
                    });
                    cleverapps.timeouts.setTimeout(f, quests.length * 1600);
                }
            },

            function (f) {
                quest.showTutorial(f);
            }
        ]
    });
};

Quests.prototype.onMapEvent = function (type, options) {
    this.quests.forEach(function (quest) {
        if (quest.isApplicable(type, options)) {
            quest.addProgress(options && options.amount || 1);
        }
    }, this);

    this.finishHeroChestQuests();

    if (type === Map2d.OPENFOG) {
        this.create(options);
    }

    if (type === Map2d.RECIPE_NEW && this.quests.some(function (quest) {
        return quest.recipeCode !== options.source.recipe.code;
    })) {
        this.create(options.source);
    }
};

Quests.prototype.isRepeatedQuestsAvailable = function () {
    return !cleverapps.meta.getSelectedLocation().isExpedition() && cleverapps.gameLevel.getLevel() >= Quests.REPEATED_AVAILABLE.level
        && this.lastFinished + Quests.START_REPEATED_TIMEOUT < Date.now();
};

Quests.prototype.runRepeatedQuests = function () {
    if (!this.isRepeatedQuestsAvailable() || this.quests.length >= 5) {
        return;
    }

    var activeIds = this.quests.map(Quest.GetId);
    var activeRepeatedIds = cleverapps.intersect(Object.keys(RepeatedQuests), activeIds);

    var toStart = Quests.CONCURRENT_REPEATED_QUESTS - activeRepeatedIds.length;
    if (toStart <= 0) {
        return;
    }

    cleverapps.Random.seed(Date.now());
    var candidates = [];
    for (var key in RepeatedQuests) {
        if (RepeatedQuests[key].dynamic && RepeatedQuests[key].dynamic.filter) {
            if (RepeatedQuests[key].dynamic.filter()) {
                candidates.push(key);
            }
            continue;
        }

        candidates.push(key);
    }
    candidates = cleverapps.Random.shuffle(candidates);
    candidates = cleverapps.substract(candidates, activeRepeatedIds);

    var configs = [];
    for (var i = 0; i < candidates.length && configs.length < toStart; i++) {
        var config = RepeatedQuests[candidates[i]];

        if (!config.dynamic || config.dynamic.getUnit && config.dynamic.getUnit()) {
            configs.push(config);
        }
    }

    if (configs.length > 0) {
        this.startQuests(configs);
    }
};

Quests.prototype.destructor = function () {
    runCleaners(this);
};

Quests.FakeQuests = {
    isRepeatedQuestsAvailable: function () {},
    finishHeroChestQuests: function () {},
    finishQuestsByCode: function () {},
    finish: function () {},
    processFresh: function () {},
    destructor: function () {},
    load: function () {},
    startQuests: function () {},
    create: function () {},
    save: function () {}
};

Quests.CONCURRENT_REPEATED_QUESTS = 2;
Quests.REPEATED_AVAILABLE = { episode: 0, level: 5 };
Quests.START_REPEATED_TIMEOUT = cleverapps.parseInterval("1 minute");
