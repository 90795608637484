/**
 * Created by Aleksandr on 27.04.2023
 */

var PawBox = function (adapter) {
    this.adapter = adapter;

    this.load();
};

PawBox.prototype.load = function () {
    this.points = this.adapter.load("paws") || 0;
};

PawBox.prototype.save = function () {
    this.adapter.save("paws", this.points);
};

PawBox.prototype.giveReward = function () {
    if (cleverapps.focusManager.isFocused() || !this.canReward()) {
        return false;
    }

    if (Map2d.currentMap.countEmptySlots() <= 0) {
        cleverapps.centerHint.createTextHint("Spawn.nospace", { left: 1 });
        return false;
    }

    cleverapps.focusManager.display({
        focus: "PawBoxRewardWindow",
        action: function (f) {
            new RewardWindow(PawBox.REWARD);
            cleverapps.focusManager.onceNoWindowsListener = f;
            this.setPoints(0);
        }.bind(this)
    });
};

PawBox.prototype.addPoints = function (points) {
    this.setPoints(this.points + points);
};

PawBox.prototype.setPoints = function (points) {
    this.points = points;
    if (this.canReward()) {
        this.giveReward();
    }
    cleverapps.sideBar.resetByClassName(PawBoxIcon);
    this.save();
};

PawBox.prototype.getPoints = function () {
    return this.points;
};

PawBox.prototype.getPointsLeft = function () {
    return Math.max(0, PawBox.GOAL - this.points);
};

PawBox.prototype.canReward = function () {
    return this.getPointsLeft() <= 0;
};

PawBox.IsIconActive = function () {
    return cleverapps.gameLevel.getLevel() >= 5;
};

PawBox.GOAL = 30;
PawBox.REWARD = {
    unit: { code: "clpawchest", stage: 0, amount: 1 }
};