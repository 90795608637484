/**
 * Created by vladislav on 20/10/2022
 */

var Customer = function (unit, options, data) {
    UnitComponent.call(this, unit);

    if (cleverapps.config.adminMode || cleverapps.config.editorMode || cleverapps.config.wysiwygMode) {
        return;
    }

    this.withOrdersWindow = data.withOrdersWindow;

    this.restore(options);

    Map2d.currentMap.customers.addCustomer(this);
    Map2d.currentMap.customers.on("onRecipesUpdated", this.onUpdateState.bind(this), this);

    Merge.currentMerge.tutorial.on("showTutorialStep", this.onUpdateState.bind(this), this);
    Merge.currentMerge.tutorial.on("finishTutorial", this.onUpdateState.bind(this), this);

    this.onGetView = function () {};
};

Customer.prototype = Object.create(UnitComponent.prototype);
Customer.prototype.constructor = Customer;

Customer.prototype.restore = function (options) {
    var recipeIndex = options.ri;
    var cookingEndTime = cleverapps.expandTime(options.ct, { seconds: true }) || options.timeToReady; // options.timeToReady used for compatibility with old version
    var appearTime = cleverapps.expandTime(options.at, { seconds: true }) || options.appearTime; // options.appearTime used for compatibility with old version
    if (appearTime && cleverapps.gameModes.silentCustomers) {
        appearTime -= Customer.COOLDOWN_DELAY.CUSTOMER;
    }

    var recipe;

    var needsFirstRecipe = recipeIndex === undefined && !options.seed && !options.recipe && !cookingEndTime && !appearTime; // options.seed & options.recipe used for compatibility with old version

    if (recipeIndex !== undefined || options.seed || options.recipe || this.unit.prizesCount || cookingEndTime) { // options.seed & options.recipe &  used for compatibility with old version
        recipe = this.chooseFreeRecipe(recipeIndex);
    }

    if (recipe && this.unit.prizesCount) {
        this._setState(Customer.STATE_READY, recipe);
    } else if (recipe && cookingEndTime) {
        this._setState(Customer.STATE_COOKING, recipe, cookingEndTime);
    } else if (recipe && !appearTime) {
        this._setState(Customer.STATE_RECIPE, recipe);
    } else {
        this._setState(Customer.STATE_EMPTY, undefined, appearTime || Date.now(), needsFirstRecipe);
    }
};

Customer.prototype.onPrizeHarvested = function () {
    Map2d.mapEvent(Map2d.EXCHANGE, { affected: this.unit });

    var climbable = this.unit.findComponent(Climbable);
    if (climbable && !this.unit.isLast()) {
        climbable.gotoNextStage();
        return;
    }

    cleverapps.tutorial.trigger("completeOrderStep");

    this.reset();
};

Customer.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }
};

Customer.prototype.handleClick = function () {
    if (this.getCookingTimeLeft()) {
        cleverapps.focusManager.display({
            focus: "ConfirmSpeedUpWindowCustomer",
            action: function (f) {
                new ConfirmSpeedUpWindow({
                    timeLeft: this.getCookingTimeLeft(),
                    totalDuration: this.getCookingDuration(),
                    eventName: cleverapps.EVENTS.SPENT.CUSTOMER,
                    callback: this.enjoy.bind(this)
                });
                cleverapps.focusManager.onceNoWindowsListener = f;
            }.bind(this)
        });

        return;
    }

    if (!this.recipe) {
        if (this._getRecipeRequiredUnit()) {
            CustomerHintContent.pointToCustomer(this.unit.code, this._getRecipeRequiredUnit());
        }
        return false;
    }

    this.unit.squeeze();

    this.openWindow();

    return true;
};

Customer.prototype.openWindow = function () {
    if (this.withOrdersWindow) {
        cleverapps.focusManager.display({
            stack: Merge.currentMerge.tutorial.checkTargets(this.unit),
            focus: "OrdersWindow",
            action: function (f) {
                new OrdersWindow({
                    maker: this
                });
                cleverapps.focusManager.onceNoWindowsListener = f;
            }.bind(this)
        });
    } else {
        var isExchange = this.getCurrentRecipe().getOrder().length > 0;
        var isTutorialTarget = Merge.currentMerge.tutorial.checkTargets(this.unit) && !cleverapps.windows.isActive();
        if (cleverapps.tutorial.isActive() && cleverapps.tutorial.getCurrentStep().customerUnit === this.unit) {
            cleverapps.tutorial.trigger("completeOrderStep");
        }

        cleverapps.focusManager.display({
            stack: isTutorialTarget,
            focus: "CustomersWindow",
            actions: [
                function (f) {
                    Map2dScroller.currentScroller.zoomIn(this.unit, {
                        position: cleverapps.UI.DOCK_LEFT | cleverapps.UI.DOCK_RIGHT,
                        zoom: 1.33,
                        centerOffset: {
                            x: 0,
                            y: -cleverapps.resolution.getSceneSize().height / (isExchange ? 6 : 12)
                        },
                        callback: function () {
                            var shoppingList = this.getCurrentRecipe().getShoppingList();
                            shoppingList.totalHard && cleverapps.focusManager.showControlsWhileFocused("MenuBarGoldItem");
                            shoppingList.totalSoft && cleverapps.focusManager.showControlsWhileFocused("MenuBarCoinsItem");

                            Map2d.currentMap.highlightUnit(this.unit);

                            if (isExchange) {
                                new ExchangeWindow(this);
                            } else {
                                new UpgradeWindow(this);
                            }

                            cleverapps.focusManager.onceNoWindowsListener = f;
                        }.bind(this)
                    });
                }.bind(this),

                function (f) {
                    Map2d.currentMap.customers.listActive().forEach(function (customer) {
                        customer.onUpdateState();
                    }, this);
                    f();
                    Map2d.currentMap.unhighlightUnit();
                    Map2dScroller.currentScroller.zoomOut();
                }.bind(this)
            ]
        });
    }
};

Customer.prototype.handlePrizes = function () {
    return this.unit.prizes.waiting;
};

Customer.prototype.showWaiting = function () {
    if (this.getState() === Customer.STATE_EMPTY && Boolean(CustomerRecipe.createRecipeTemplate(this)) && !this.needsFirstRecipe && !this.withOrdersWindow) {
        this.unit.setPrizes({ waiting: true });
    }
};

Customer.prototype.exchange = function (callback) {
    this.cook(callback);

    this.unit.squeeze();
};

Customer.prototype.collectUnit = function (unit, callback) {
    var view = this.onGetView() || this.view;
    if (view) {
        view.collectUnitAnimation(unit, callback);
        unit.remove(true);
    } else {
        unit.remove();
        callback();
    }
};

Customer.prototype.cook = function (f) {
    if (this.canCook()) {
        this._setState(Customer.STATE_COOKING, this.recipe, Date.now() + this.recipe.getCookingDuration());
        Map2d.currentMap.customers.takeIngredients(this.recipe.ingredients, {
            customer: this,
            callback: f
        });
        Merge.currentMerge.pushes.updateOrders();
    }
};

Customer.prototype.enjoy = function () {
    if (this.getState() === Customer.STATE_COOKING) {
        this._setState(Customer.STATE_READY, this.recipe);

        Merge.currentMerge.pushes.updateOrders();

        if (this.recipe.customerCode) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.HUNGRY_END);
        }

        this.checkAndHarvestPrize();
    }
};

Customer.prototype.checkAndHarvestPrize = function () {
    if (!this.unit.prizesCount) {
        this.onPrizeHarvested();
    }
};

Customer.prototype.reset = function () {
    this._setState(Customer.STATE_EMPTY, undefined, Date.now());
};

Customer.prototype.order = function () {
    var recipe = this.needsFirstRecipe && this.chooseFreeRecipe(0) || this.chooseFreeRecipe();

    if (recipe) {
        if (Merge.currentMerge.orderManager) {
            Merge.currentMerge.orderManager.inc();
            Merge.currentMerge.orderManager.save();
        }

        this._setState(Customer.STATE_RECIPE, recipe);
        cleverapps.audio.playSound(bundles.merge.urls.new_order_effect);

        if (this.recipe.customerCode) {
            cleverapps.playSession.set(cleverapps.EVENTS.HUNGRY_DAU, true);
        }
    }
};

Customer.prototype.chooseFreeRecipe = function (recipeIndex) {
    var recipeTemplate = CustomerRecipe.createRecipeTemplate(this, recipeIndex);
    if (recipeTemplate) {
        return new CustomerRecipe(recipeTemplate);
    }
};

Customer.prototype.isEnoughIngredientsForRecipe = function () {
    return Boolean(this.recipe && this.recipe.isEnoughIngredients());
};

Customer.prototype.getCurrentRecipe = function () {
    if (this.getState() === Customer.STATE_RECIPE) {
        return this.recipe;
    }
};

Customer.prototype.getCookingRecipe = function () {
    if (this.getState() === Customer.STATE_COOKING) {
        return this.recipe;
    }
};

Customer.prototype.hasSomeRecipes = function () {
    return Boolean(CustomerRecipe.createRecipeTemplate(this, undefined, true));
};

Customer.prototype._getRecipeRequiredUnit = function () {
    var recipe = CustomerRecipe.createRecipeTemplate(this, undefined, true);
    if (recipe && recipe.requiredUnits) {
        return recipe.requiredUnits.filter(function (unit) {
            return !cleverapps.unitsLibrary.isOpened(unit);
        })[0];
    }
};

Customer.prototype.save = function (data) {
    data.ri = this.recipe && this.recipe.index;
    data.at = cleverapps.compactTime(this.appearTime, { seconds: true });
    data.ct = cleverapps.compactTime(this.cookingEndTime, { seconds: true });
};

Customer.prototype.destructor = function () {
    runCleaners(this);
    clearTimeout(this.timeout);
    clearTimeout(this.showWaitingTimeout);
    Map2d.currentMap.customers.removeCustomer(this);
};

Customer.prototype.getCooldownTimeLeft = function () {
    if (!this.appearTime) {
        return 0;
    }

    var cooldownDelay = Customer.COOLDOWN_DELAY.CUSTOMER;
    if (this.withOrdersWindow) {
        cooldownDelay = Merge.currentMerge.tutorial.isActive()
            ? Customer.COOLDOWN_DELAY.ORDERS_WINDOW.TUTORIAL
            : Customer.COOLDOWN_DELAY.ORDERS_WINDOW.DEFAULT;
    }

    return Math.max(0, this.appearTime + cooldownDelay + Customer.REWARD_SPAWN_DELAY - Date.now());
};

Customer.prototype.getCookingTimeLeft = function () {
    if (this.cookingEndTime) {
        return Math.max(0, this.cookingEndTime - Date.now());
    }
    return 0;
};

Customer.prototype.getCookingDuration = function () {
    if (this.cookingEndTime) {
        return this.recipe.getCookingDuration();
    }
    return 0;
};

Customer.prototype.getGuideOptions = function () {
    return this.unit.getData().customer.guide;
};

Customer.prototype.replaceOrderItemsWithCoins = function () {
    if (!this.recipe) {
        return;
    }
    var isReplaced;
    this.recipe.order.forEach(function (item) {
        if (item.unit && Merge.currentMerge.needToReplaceWithCoins(item.unit)) {
            item.unit = Merge.currentMerge.getUnitReplacer(item.unit);
            isReplaced = true;
        }
    });
    if (isReplaced && this.unit.prizesCount) {
        this.unit.setPrizes(this.recipe.listPrize());
    }
};

Customer.prototype._setState = function (state, recipe, time, needsFirstRecipe) {
    if (cleverapps.config.editorMode || cleverapps.config.wysiwygMode) {
        return;
    }

    clearTimeout(this.timeout);
    clearTimeout(this.showWaitingTimeout);
    this.timeout = undefined;

    this.recipe = undefined;
    this.needsFirstRecipe = undefined;
    this.cookingEndTime = undefined;
    this.appearTime = undefined;

    if (recipe) {
        this._canSwitchToCustomRecipe = !recipe.customerCode;
    }

    switch (state) {
        case Customer.STATE_RECIPE:
            this.unit.setPrizes();
            this.recipe = recipe;

            Map2d.mapEvent(Map2d.RECIPE_NEW, { source: this });

            break;

        case Customer.STATE_COOKING:
            this.unit.setPrizes();
            this.recipe = recipe;
            this.recipe.cookRecipe();
            this.cookingEndTime = time;
            this.timeout = new cleverapps.LongTimeout(this.enjoy.bind(this), this.getCookingTimeLeft());

            break;

        case Customer.STATE_READY:
            this.recipe = recipe;

            this.setRecipePrizes();

            Map2d.mapEvent(Map2d.RECIPE_READY, { source: this });

            break;

        default:
            this.unit.setPrizes();
            this.needsFirstRecipe = needsFirstRecipe;
            this.appearTime = time;
            this.timeout = new cleverapps.LongTimeout(this.order.bind(this), this.getCooldownTimeLeft());
    }

    this.unit.save();

    this.onUpdateState();
    
    if (this.withOrdersWindow) {
        cleverapps.toolbar.resetByType(ToolbarItem.ORDERS);
    }

    Map2d.currentMap.customers.onUpdateOrder(this);
    Map2d.currentMap.customers.updateMarks();

    this.showWaitingTimeout = new cleverapps.LongTimeout(this.showWaiting.bind(this), Customer.REWARD_SPAWN_DELAY);
};

Customer.prototype.setRecipePrizes = function () {
    var prizes = this.recipe.listPrize(this.unit.prizesCount);
    this.unit.setPrizes(prizes);
};

Customer.prototype.canCook = function () {
    return this.getState() === Customer.STATE_RECIPE && Map2d.currentMap.customers.canTakeIngredients(this.recipe.ingredients);
};

Customer.prototype.getState = function () {
    if (this.recipe && this.unit.prizesCount) {
        return Customer.STATE_READY;
    } if (this.recipe && this.cookingEndTime) {
        return Customer.STATE_COOKING;
    } if (this.recipe) {
        return Customer.STATE_RECIPE;
    }
    return Customer.STATE_EMPTY;
};

Customer.prototype.isProcessingCustomOrder = function () {
    return [Customer.STATE_READY, Customer.STATE_EMPTY].indexOf(this.getState()) === -1 && this.recipe.customerCode;
};

Customer.prototype.canSwitchToCustomRecipe = function () {
    return cleverapps.unitsLibrary.isOpened(UnitsLibrary.LastStage("king"))
        && this._canSwitchToCustomRecipe !== false
        && !Map2d.currentMap.customers.listCustomers().find(function (customer) {
            return customer.isProcessingCustomOrder();
        });
};

Customer.prototype.hasValidRecipe = function () {
    return Boolean(this.chooseFreeRecipe(this.recipe.index));
};

Customer.STATE_EMPTY = 0;
Customer.STATE_RECIPE = 1;
Customer.STATE_COOKING = 2;
Customer.STATE_READY = 3;

Customer.COOLDOWN_DELAY = {
    ORDERS_WINDOW: {
        DEFAULT: cleverapps.parseInterval("30 seconds"),
        TUTORIAL: cleverapps.parseInterval("120 seconds")
    },
    CUSTOMER: cleverapps.parseInterval("10 seconds")
};

Customer.REWARD_SPAWN_DELAY = 200;

Customer.FIRST_RECIPE_DELAY = cleverapps.parseInterval("2 seconds");