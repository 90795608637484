/**
 * Created by razial on 27.03.2023
 */

MergeLogic.PAGES = [];
MergeLogic.PAGES_BY_ID = {};

MergeLogic.INIT_PAGES = function () {
    MergeLogic.PAGES = [];
    MergeLogic.PAGES_BY_ID = {};
    
    MergeLogic.PAGES = [
        {
            id: "main",
            prefix: "main",
            slot: Meta.SLOT_MAIN,
            location: "0",
            energy: true,
            wands: true,
            workers: true,
            magnet: true
        }
    ];

    MergeLogic.SEASONAL_PAGES = [
        {
            id: "xmas",
            prefix: "xm",
            missionType: Mission.TYPE_XMAS_EXPEDITION,
            name: "XmasExpedition",
            location: "xmas",
            available: {
                level: 7,
                feature: "expedition_xmas"
            },
            energy: true,
            wands: true,
            workers: true,
            magnet: true,
            feature: "xmas_expedition",
            sideBarIcon: bundles.sidebar.jsons.exp_xmas,
            childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_SNAIL_FEAST],
            offers: [{
                id: Offers.TYPES.XMAS,
                base: Offers.TYPES.EXPEDITION,
                reward: RewardsConfig.XmasPack,
                bundle: "xmas_offer",
                hero: { code: "dr2dragonpack", stage: 0 }
            }, {
                id: Offers.TYPES.SNAILFEAST_HALLOWEEN,
                base: Offers.TYPES.SNAILFEAST
            }]
        },
        {
            id: "halloween",
            prefix: "hl",
            missionType: Mission.TYPE_HALLOWEEN_EXPEDITION,
            name: "HalloweenExpedition",
            location: "halloween",
            available: {
                level: 7,
                feature: "expedition_halloween"
            },
            energy: true,
            wands: true,
            workers: true,
            magnet: true,
            feature: "halloween_expedition",
            sideBarIcon: bundles.sidebar.jsons.exp_halloween,
            childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_SNAIL_FEAST],
            offers: [{
                id: Offers.TYPES.HALLOWEEN,
                base: Offers.TYPES.EXPEDITION,
                reward: RewardsConfig.Dragonia2Pack,
                bundle: "halloween_offer",
                hero: { code: "dr2dragonpack", stage: 0 }
            }, {
                id: Offers.TYPES.SNAILFEAST_XMAS,
                base: Offers.TYPES.SNAILFEAST
            }]
        },
        {
            id: "easter",
            prefix: "es",
            missionType: Mission.TYPE_EASTER_EXPEDITION,
            name: "EasterExpedition",
            location: "easter",
            feature: "easter_expedition",
            available: {
                level: 6,
                feature: "expedition_easter",
                projectName: ["mergecraft", "wondermerge", "fairy", "hustlemerge"]
            },
            energy: true,
            magnet: true,
            noOrdersBuyout: true,
            childMissions: [{
                type: Mission.TYPE_EXPEDITION_PASS,
                startFog: "fog3",
                rulesOptions: false
            }],
            offers: [{
                id: Offers.TYPES.EASTER,
                base: Offers.TYPES.EXPEDITION,
                fog: "fog3",
                reward: RewardsConfig.EasterPack,
                bundle: "easter_offer"
            }]
        },
        {
            id: "china",
            prefix: "ch",
            missionType: Mission.TYPE_CHINA_EXPEDITION,
            name: "ChinaExpedition",
            location: "china",
            energy: true,
            wands: true,
            workers: true,
            magnet: true,
            feature: "china_expedition",
            available: {
                level: 6,
                feature: "expedition_china"
            },
            sideBarIcon: bundles.sidebar.jsons.exp_china,
            childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_SNAIL_FEAST],
            offers: [{
                id: Offers.TYPES.CHINA,
                base: Offers.TYPES.EXPEDITION,
                reward: RewardsConfig.ChinaPack,
                bundle: "china_offer",
                hero: { code: "dr2dragonpack", stage: 0 }
            }, {
                id: Offers.TYPES.SNAILFEAST_CHINA,
                base: Offers.TYPES.SNAILFEAST
            }]
        },
        {
            id: "blackfriday",
            prefix: "bl",
            missionType: Mission.TYPE_BLACKFRIDAY_EXPEDITION,
            name: "BlackfridayExpedition",
            location: "blackfriday",
            energy: true,
            wands: true,
            workers: true,
            magnet: true,
            feature: "blackfriday_expedition",
            available: {
                level: 6,
                feature: "expedition_blackfriday"
            },
            sideBarIcon: bundles.sidebar.jsons.exp_blackfriday,
            childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_SNAIL_FEAST],
            offers: [{
                id: Offers.TYPES.BLACKFRIDAY,
                base: Offers.TYPES.EXPEDITION,
                reward: RewardsConfig.BlackFridayPack,
                bundle: "blackfriday_offer",
                hero: { code: "dr2dragonpack", stage: 0 }
            }, {
                id: Offers.TYPES.SNAILFEAST_BLACKFRIDAY,
                base: Offers.TYPES.SNAILFEAST
            }]
        },
        {
            id: "spring",
            prefix: "sp",
            missionType: Mission.TYPE_SPRING_EXPEDITION,
            name: "SpringExpedition",
            location: "spring",
            available: {
                level: 6,
                feature: "expedition_spring"
            },
            energy: true,
            wands: true,
            workers: true,
            magnet: true,
            feature: "spring_expedition",
            sideBarIcon: bundles.sidebar.jsons.exp_spring,
            childMissions: [Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_SNAIL_FEAST],
            offers: [{
                id: Offers.TYPES.SPRING,
                base: Offers.TYPES.EXPEDITION,
                reward: RewardsConfig.SpringPack,
                bundle: "spring_offer",
                hero: { code: "dr2dragonpack", stage: 0 }
            }, {
                id: Offers.TYPES.SNAILFEAST_SPRING,
                base: Offers.TYPES.SNAILFEAST
            }]
        }
    ].map(function (page) {
        return Object.assign({
            slot: Meta.EXPEDITION_SLOT1,
            semaphore: Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT1,
            seasonal: true
        }, page);
    });

    MergeLogic.ONESHOT_PAGES = [
        {
            id: "dragonia",
            prefix: "dr",
            missionType: Mission.TYPE_DRAGONIA_EXPEDITION,
            name: "DragoniaExpedition",
            location: "dragonia",
            available: {
                level: 7,
                feature: "expedition_dragonia",
                projectName: ["mergecraft", "wondermerge", "fairy", "hustlemerge"],

                hustlemerge: {
                    level: 7,
                    debugMode: true
                }
            },
            wands: true,
            workers: true,
            magnet: true,
            noOrdersBuyout: true,
            duration: "20 days",
            sideBarIcon: bundles.sidebar.jsons.exp_dragonia,
            childMissions: [{ type: Mission.TYPE_EXPEDITION_PASS, levels: PassLevelsConfig.difficultexpeditionpass }, Mission.TYPE_EXPEDITION_FEAST],
            offers: [Offers.TYPES.EXPEDITION]
        },
        {
            id: "rapunzel",
            prefix: "rp",
            missionType: Mission.TYPE_RAPUNZEL_EXPEDITION,
            name: "RapunzelExpedition",
            location: "rapunzel",
            available: {
                level: 7,
                feature: "expedition_rapunzel"
            },
            duration: "6 days",
            energy: true,
            magnet: true,
            noOrdersBuyout: true,
            sideBarIcon: bundles.sidebar.jsons.exp_rapunzel,
            childMissions: [{
                type: Mission.TYPE_EXPEDITION_PASS,
                startFog: "fog1",
                rulesOptions: false,
                levels: PassLevelsConfig.rapunzelpass
            }],
            offers: [{
                id: Offers.TYPES.RAPUNZEL,
                base: Offers.TYPES.EXPEDITION,
                fog: "fog2",
                cooldown: cleverapps.config.debugMode ? "2 minutes" : "24 hours",
                reward: RewardsConfig.RapunzelPack,
                bundle: "rapunzel_offer"
            }]
        },
        {
            id: "undersea",
            prefix: "sea",
            missionType: Mission.TYPE_UNDERSEA_EXPEDITION,
            name: "UnderseaExpedition",
            location: "undersea",
            available: {
                level: 7,
                feature: "expedition_undersea"
            },
            wands: true,
            workers: true,
            magnet: true,
            noOrdersBuyout: true,
            duration: "20 days",
            sideBarIcon: bundles.sidebar.jsons.exp_undersea,
            childMissions: [{ type: Mission.TYPE_EXPEDITION_PASS, levels: PassLevelsConfig.difficultexpeditionpass }, Mission.TYPE_EXPEDITION_FEAST],
            offers: [{
                id: Offers.TYPES.UNDERSEA,
                base: Offers.TYPES.EXPEDITION,
                reward: RewardsConfig.UnderseaPack,
                bundle: "undersea_offer",
                hero: { code: "underseapack", stage: 0 }
            }]
        },
        {
            id: "dragonia2",
            prefix: "dr2",
            missionType: Mission.TYPE_DRAGONIA2_EXPEDITION,
            name: "Dragonia2Expedition",
            location: "dragonia2",
            available: {
                level: 7,
                feature: "expedition_dragonia",
                projectName: ["mergecraft", "wondermerge", "fairy"]
            },
            energy: true,
            wands: true,
            workers: true,
            magnet: true,
            noOrdersBuyout: true,
            duration: "20 days",
            sideBarIcon: bundles.sidebar.jsons.exp_dragonia,
            childMissions: [{ type: Mission.TYPE_EXPEDITION_PASS, levels: PassLevelsConfig.difficultexpeditionpass }, Mission.TYPE_SNAIL_FEAST],
            offers: [{
                id: Offers.TYPES.DRAGONIA2,
                base: Offers.TYPES.EXPEDITION,
                reward: RewardsConfig.Dragonia2Pack,
                bundle: "dragonia_offer",
                hero: { code: "dr2dragonpack", stage: 0 }
            }, Offers.TYPES.SNAILFEAST]
        },
        {
            id: "rapunzel2",
            prefix: "rp2",
            missionType: Mission.TYPE_RAPUNZEL2_EXPEDITION,
            name: "Rapunzel2Expedition",
            location: "rapunzel2",
            available: {
                level: 7,
                feature: "expedition_rapunzel"
            },
            energy: true,
            magnet: true,
            noOrdersBuyout: true,
            duration: "8 days",
            sideBarIcon: bundles.sidebar.jsons.exp_rapunzel,
            childMissions: [{
                type: Mission.TYPE_EXPEDITION_PASS,
                startFog: "fog1",
                rulesOptions: false,
                levels: PassLevelsConfig.rapunzelpass
            }],
            offers: [{
                id: Offers.TYPES.RAPUNZEL2,
                base: Offers.TYPES.EXPEDITION,
                fog: "fog2",
                cooldown: cleverapps.config.debugMode ? "2 minutes" : "24 hours",
                reward: RewardsConfig.RapunzelPack,
                bundle: "rapunzel_offer"
            }]
        },
        {
            id: "undersea2",
            prefix: "sea2",
            missionType: Mission.TYPE_UNDERSEA2_EXPEDITION,
            name: "Undersea2Expedition",
            location: "undersea2",
            available: {
                level: 7,
                feature: "expedition_undersea"
            },
            wands: true,
            workers: true,
            magnet: true,
            noOrdersBuyout: true,
            parallelHeroDrop: true,
            duration: "20 days",
            sideBarIcon: bundles.sidebar.jsons.exp_undersea,
            childMissions: [{ type: Mission.TYPE_EXPEDITION_PASS, levels: PassLevelsConfig.difficultexpeditionpass }, Mission.TYPE_SNAIL_FEAST],
            offers: [{
                id: Offers.TYPES.UNDERSEA2,
                base: Offers.TYPES.EXPEDITION,
                reward: RewardsConfig.Undersea2Pack,
                bundle: "undersea_offer",
                hero: { code: "sea2pack", stage: 0 }
            }, {
                id: Offers.TYPES.SNAILFEAST_UNDERSEA2,
                base: Offers.TYPES.SNAILFEAST,
                reward: RewardsConfig.SnailPack
            }]
        },
        {
            id: "dragonia3",
            prefix: "dr3",
            missionType: Mission.TYPE_DRAGONIA3_EXPEDITION,
            name: "Dragonia3Expedition",
            location: "dragonia3",
            available: {
                level: 7,
                feature: "expedition_dragonia",
                projectName: ["mergecraft", "wondermerge", "fairy"]
            },
            duration: "20 days",
            energy: true,
            wands: true,
            workers: true,
            magnet: true,
            sideBarIcon: bundles.sidebar.jsons.exp_dragonia,
            childMissions: [{ type: Mission.TYPE_EXPEDITION_PASS, levels: PassLevelsConfig.difficultexpeditionpass }, Mission.TYPE_SNAIL_FEAST],
            offers: [{
                id: Offers.TYPES.DRAGONIA3,
                base: Offers.TYPES.EXPEDITION,
                reward: RewardsConfig.Dragonia2Pack,
                fog: "fog10",
                bundle: "dragonia_offer",
                hero: { code: "dr2dragonpack", stage: 0 }
            }, {
                id: Offers.TYPES.SNAILFEAST_DRAGONIA3,
                base: Offers.TYPES.SNAILFEAST
            }]
        },
        {
            id: "rapunzel3",
            prefix: "rp3",
            missionType: Mission.TYPE_RAPUNZEL3_EXPEDITION,
            name: "Rapunzel3Expedition",
            location: "rapunzel3",
            duration: "14 days",
            energy: true,
            magnet: true,
            available: {
                level: 7,
                feature: "expedition_rapunzel",
                projectName: ["mergecraft", "wondermerge", "fairy"]
            },
            sideBarIcon: bundles.sidebar.jsons.exp_rapunzel,
            childMissions: [{
                type: Mission.TYPE_EXPEDITION_PASS,
                startUnit: { code: "rpcustomermain0a", stage: 1 },
                rulesOptions: false,
                levels: PassLevelsConfig.rapunzel3pass
            }],
            offers: [{
                id: Offers.TYPES.RAPUNZEL3,
                base: Offers.TYPES.EXPEDITION,
                fog: "fog2",
                cooldown: cleverapps.config.debugMode ? "2 minutes" : "24 hours",
                reward: RewardsConfig.RapunzelPack,
                bundle: "rapunzel_offer"
            }]
        },
        {
            id: "undersea3",
            prefix: "sea3",
            missionType: Mission.TYPE_UNDERSEA3_EXPEDITION,
            name: "Undersea3Expedition",
            location: "undersea3",
            available: {
                level: 7,
                feature: "expedition_undersea",
                projectName: ["mergecraft", "wondermerge"]
            },
            duration: "20 days",
            wands: true,
            workers: true,
            magnet: true,
            sideBarIcon: bundles.sidebar.jsons.exp_undersea,
            childMissions: [{ type: Mission.TYPE_EXPEDITION_PASS, levels: PassLevelsConfig.difficultexpeditionpass }, Mission.TYPE_SNAIL_FEAST],
            offers: [{
                id: Offers.TYPES.UNDERSEA3,
                base: Offers.TYPES.EXPEDITION,
                reward: RewardsConfig.Undersea2Pack,
                bundle: "undersea_offer",
                hero: { code: "sea2pack", stage: 0 }
            }, {
                id: Offers.TYPES.SNAILFEAST_UNDERSEA3,
                base: Offers.TYPES.SNAILFEAST,
                reward: RewardsConfig.SnailPack
            }]
        },
        {
            id: "adventure",
            prefix: "adv",
            missionType: Mission.TYPE_ADVENTURE_EXPEDITION,
            name: "AdventureExpedition",
            location: "adventure",
            available: {
                level: 7.5,
                feature: "expedition_adventure",
                projectName: ["mergecraft", "wondermerge", "fairy", "hustlemerge"]
            },
            duration: "7 days",
            energy: true,
            workers: true,
            dynamite: true,
            slot: Meta.EXPEDITION_SLOT4,
            semaphore: Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT4,
            sideBarIcon: bundles.sidebar.jsons.exp_adventure
        },
        {
            id: "adventure2",
            prefix: "adv2",
            missionType: Mission.TYPE_ADVENTURE2_EXPEDITION,
            name: "Adventure2Expedition",
            location: "adventure2",
            available: {
                level: 7.5,
                feature: "expedition_adventure",
                projectName: ["mergecraft"],
                debugMode: true
            },
            duration: "7 days",
            energy: true,
            workers: true,
            dynamite: true,
            slot: Meta.EXPEDITION_SLOT4,
            semaphore: Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT4,
            sideBarIcon: bundles.sidebar.jsons.exp_adventure
        }
    ].map(function (page) {
        return Object.assign({
            oneShot: true,
            slot: Meta.EXPEDITION_SLOT2,
            semaphore: Missions.SEMAPHORE_CUSTOM_EXPEDITION_SLOT2
        }, page);
    });

    MergeLogic.PERMANENT_PAGES = [
        {
            id: "collections",
            prefix: "cl",
            name: "CollectionsExpedition",
            location: "collections",
            slot: Meta.EXPEDITION_SLOT3,
            permanent: true,
            gameLevel: true,
            wands: true,
            magnet: true,
            noOrdersBuyout: true,
            available: {
                level: 8.1,
                feature: "expedition_collections",
                projectName: ["mergecraft", "fairy", "wondermerge"]
            },
            sideBarIcon: bundles.sidebar.jsons.exp_collections,
            offers: [
                Offers.TYPES.CLPASS,
                Offers.TYPES.CLCHAIN_SALE,
                Offers.TYPES.CLSUPPLIES_SALE,
                Offers.TYPES.CLPROMOTION_SALE
            ]
        }
    ];

    MergeLogic.PAGES = MergeLogic.PAGES.concat(MergeLogic.SEASONAL_PAGES, MergeLogic.ONESHOT_PAGES, MergeLogic.PERMANENT_PAGES);

    MergeLogic.PAGES = MergeLogic.PAGES.filter(function (page) {
        if (!page) {
            return false;
        }

        var available = page.available || {};

        if (available.feature && (cleverapps.config.features || []).indexOf(available.feature) === -1) {
            return false;
        }

        available = available[cleverapps.config.name] || available;

        if (available.projectName && cleverapps.toArray(available.projectName).indexOf(cleverapps.config.name) === -1) {
            return false;
        }

        if (available.debugMode && !cleverapps.config.debugMode) {
            return false;
        }

        return true;
    });

    MergeLogic.PAGES.forEach(function (page) {
        MergeLogic.PAGES_BY_ID[page.id] = page;

        page.version = page.version || 0;

        if (page.missionType !== undefined) {
            page.version = cleverapps.castType(Mission.ParseTypeVersion(page.missionType));

            Mission.MISSION_TYPE_BY_LOCATION_ID[page.id] = page.missionType;
        }
    });
};

MergeLogic.findPageById = function (locationId) {
    return MergeLogic.PAGES_BY_ID[locationId];
};

MergeLogic.INIT_MISSIONS = function () {
    var ExpeditionMissionData = Object.assign({}, {
        level: 0,
        duration: "1 day",
        logic: ExpeditionMissionLogic,
        manualStart: ManualStarters.FakeStarter,
        manualFinish: true,
        sideBarJson: false
    });

    MergeLogic.PAGES.forEach(function (page) {
        page.childMissions = (page.childMissions || []).map(function (missionData) {
            var parentType = page.missionType;
            if (parentType === undefined) {
                throw "can't use child missions without parent mission for now " + page.id;
            }

            var type = missionData.type !== undefined ? missionData.type : missionData;

            var compoundType = Mission.CompoundType(type, parentType);
            var childParams = {
                location: page.id,
                type: compoundType
            };

            if (missionData.startFog) {
                childParams.manualStart = ManualStarters.FogStarter;
                childParams.startFog = missionData.startFog;
            }
            if (missionData.startUnit) {
                childParams.manualStart = ManualStarters.UnitStarter;
                childParams.startUnit = missionData.startUnit;
            }
            if (missionData.rulesOptions !== undefined) {
                childParams.rulesOptions = missionData.rulesOptions;
            }
            if (missionData.levels) {
                childParams.levels = missionData.levels;
            }
            Missions[compoundType] = Object.assign({}, Missions[type], childParams);

            return compoundType;
        });

        if (page.missionType !== undefined) {
            Missions[page.missionType] = Object.assign({}, ExpeditionMissionData, page);
        }
    });
};

MergeLogic.INIT_OFFERS = function () {
    MergeLogic.PAGES.forEach(function (page) {
        page.offers = (page.offers || []).map(function (offerData) {
            if (typeof offerData !== "object") {
                return offerData;
            }

            var id = offerData.id;
            var baseOfferId = offerData.base;

            offerData.type = id;
            delete offerData.id;
            delete offerData.base;

            Offers[id] = Object.assign({}, Offers[baseOfferId], offerData);

            if (baseOfferId === Offers.TYPES.SNAILFEAST) {
                Offers[id].mission = Mission.CompoundTypeFromLocationId(Mission.GetChildType(Offers[baseOfferId].mission), page.id);
                cleverapps.styles.SingleProductOfferWindow[id] = cleverapps.styles.SingleProductOfferWindow[baseOfferId];
                cleverapps.styles.PackOfferComponent[id] = cleverapps.styles.PackOfferComponent[baseOfferId];
            } else if (baseOfferId === Offers.TYPES.EXPEDITION) {
                Offers[id].location = page.id;
                Offers[id].force = page.slot === "1" ? Forces.OFFER_ICON_SLOT1 : Forces.OFFER_ICON_SLOT2;
                Offers[id].sideBarJson = bundles.sidebar.jsons[offerData.bundle];
                Offers[id].name = page.name.replace("Expedition", "Pack");
            }

            return id;
        });
    });
};
