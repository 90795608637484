/**
 * Created by mac on 12/8/20
 */

var ViewReader = {};

ViewReader.calcBorderType = function (map, part) {
    var level1Area = map.map(map.layers[Map2d.LAYER_GROUND], function (tile, x, y) {
        return map.calcGroundLevel(x, y) === Map2d.TILE_GREEN_LEVEL_1;
    });

    var level2Area = map.map(map.layers[Map2d.LAYER_GROUND], function (tile, x, y) {
        return map.calcGroundLevel(x, y) === Map2d.TILE_GREEN_LEVEL_2;
    });
    BorderRenderer.place(
        level2Area,
        ViewReader.bordersPlacement,
        { part: part }
    );

    BorderRenderer.place(
        level1Area,
        ViewReader.bordersPlacement,
        { part: part }
    );
};

ViewReader.onEdit = function (x, y) {
    var neighbours = BorderRenderer.getNeighbours(x, y).concat({ x: x, y: y });

    neighbours.forEach(function (neighbour) {
        Map2d.currentMap.remove(Map2d.LAYER_BORDERS, neighbour.x, neighbour.y);
    });

    ViewReader.calcBorderType(Map2d.currentMap, neighbours);
};

ViewReader.customSkipWall = function (cell, border) {
    var map = Map2d.currentMap;
    var customTypes = ["tiles_castle_purple"];
    if (!customTypes.includes(map.getTerrainType(cell.x, cell.y).name)) {
        return false;
    }

    var borderCellLevel = map.calcGroundLevel(border.borderCell.x, border.borderCell.y);
    if (["up_left", "down_right", "up_right", "down_left"].includes(border.name) && borderCellLevel === Map2d.TILE_WATER) { // https://gyazo.com/a48c44f9b4cfa3004be3127ea88eb9fa
        if (border.noNeighbor.some(function (side) {
            var sideCell = BorderRenderer.getNeighbor(cell.x, cell.y, side);
            return map.calcGroundLevel(sideCell.x, sideCell.y) !== borderCellLevel;
        })) {
            return true;
        }
    }

    if (["right", "up"].includes(border.name)) { // https://gyazo.com/48401a356db80941e3b6aa6d87669a8a, https://gyazo.com/5549179438f4f51e2bbce44a326051ce
        var checked = [cell, BorderRenderer.getNeighbor(cell.x, cell.y, border.name === "right" ? "up" : "right")];
        for (var i = 0; i < checked.length; i++) {
            var checkCell = BorderRenderer.getNeighbor(checked[i].x, checked[i].y, "up_right");
            if (map.getValue(Map2d.LAYER_GROUND, checkCell.x, checkCell.y) && !map.getValue(Map2d.LAYER_BORDERS, checkCell.x, checkCell.y)) {
                return true;
            }
        }
    }
};

ViewReader.bordersPlacement = function (borders, cell) {
    var map = Map2d.currentMap;
    var cellLevel = map.calcGroundLevel(cell.x, cell.y);

    if (map.getValue(Map2d.LAYER_BORDERS, cell.x, cell.y)) {
        return;
    }

    borders.forEach(function (border) {
        var borderCell = border.borderCell;
        var borderCellLevel = map.calcGroundLevel(borderCell.x, borderCell.y);
        if (!map.isValid(borderCell.x, borderCell.y)) {
            return;
        }

        var surface = borderCellLevel === Map2d.TILE_WATER ? "water" : "land";
        var level = cellLevel + "_";
        var direction = border.name;
        var suffix = "";

        if (ViewReader.customSkipWall(cell, border)) {
            return;
        }

        if (["up", "right"].includes(border.name)
            && borders.some(function (border) {
                return border.name === "up_right";
            }) && map.getTerrainFrameData("water1_up_right", cell.x, cell.y).res) {
            return;
        }

        if (["left", "down", "inner_up_right", "inner_down_left"].includes(border.name)) {
            var checkCell = BorderRenderer.getNeighbor(borderCell.x, borderCell.y, "up_right");
            var upperCellLevel = map.calcGroundLevel(checkCell.x, checkCell.y);
            if (cellLevel !== upperCellLevel) {
                suffix = "_last";
            }
        }

        var frameName = surface + level + direction + suffix;
        var frameData = map.getTerrainFrameData(frameName, cell.x, cell.y);

        if (!frameData.res && suffix) {
            suffix = "";
            frameName = surface + level + direction + suffix;
            frameData = map.getTerrainFrameData(frameName, cell.x, cell.y);
        }

        if (!frameData.res) {
            level = "1_";
            frameName = surface + level + direction + suffix;
            frameData = map.getTerrainFrameData(frameName, cell.x, cell.y);
        }

        if (!frameData.res) {
            return;
        }
        
        frameData.type = border.name;

        var borderTile = map.getValue(Map2d.LAYER_BORDERS, borderCell.x, borderCell.y) || new BorderTile(borderCell.x, borderCell.y);
        borderTile.addBorder(frameData);
    });
};

ViewReader.createTopYAnchorElement = function (data) {
    var width = cleverapps.styles.Map2dView.cell.width;
    var height = cleverapps.styles.Map2dView.cell.height;

    var content = new cc.Sprite(data.res);

    var anchorX = data.options.anchorX !== undefined ? data.options.anchorX : 0.5;
    var anchorY = data.options.anchorY !== undefined ? data.options.anchorY : 1;

    var pos = cc.p(0, 0);
    if (data.options.y !== undefined) {
        pos.y = -height / 2 + data.options.y;
    } else if (anchorY === 0) {
        pos.y = -height / 2;
    } else if (anchorY === 1) {
        pos.y = height / 2 + 1;
    }

    if (data.options.x !== undefined) {
        pos.x = -width / 2 + data.options.x;
    } else if (anchorX === 0) {
        pos.x = -width / 2;
    } else if (anchorX === 1) {
        pos.x = width / 2;
    }

    content.setAnchorPoint(anchorX, anchorY);
    content.setPositionRound(pos);
    return content;
};

ViewReader.hasView = function (code) {
    return [Map2d.TILE_WATER, Map2d.TILE_WATER_UNIT, Map2d.TILE_GREEN_LEVEL_1, Map2d.TILE_GREEN_LEVEL_2].indexOf(code) !== -1;
};

ViewReader.parse = function (code, map) {
    var frames = map.getTerrainFrames(Iso.SAME);
    var isOdd = (map.pointer.x + map.pointer.y) % 2;
    var frame;

    switch (code) {
        case Map2d.TILE_WATER:
        case Map2d.TILE_WATER_UNIT:
            var tilesTexture = Map2d.getTilesTexture(Map2d.currentMap && Map2d.currentMap.tilesSkin);
            frame = bundles[tilesTexture] && bundles[tilesTexture].frames.water;
            return frame ? Map2dWaterView.GetFromPool(frame) : undefined;

        case Map2d.TILE_GREEN_LEVEL_1:
        case Map2d.TILE_GREEN_LEVEL_2:
            return Map2dGroundView.GetFromPool(isOdd ? frames.green_ground_1 : frames.green_ground_2);
        case Map2d.TILE_IMPASSABLE_LEVEL_1:
        case Map2d.TILE_IMPASSABLE_LEVEL_2:
            if (frames.green_ground_3) {
                return Map2dGroundView.GetFromPool(frames.green_ground_3);
            }

            return Map2dGroundView.GetFromPool(isOdd ? frames.green_ground_1 : frames.green_ground_2);
        case Map2d.TILE_GROUND_CHESS:
            return Map2dChessGroundView.GetFromPool(isOdd ? frames.chess_1 : frames.chess_2);
        case Map2d.TILE_STARRED_GROUND_CHESS:
            if (cleverapps.config.debugMode) {
                return Map2dChessGroundView.GetFromPool(bundles.dev_resources.frames.chess_debug);
            }
    }
};
