/**
 * Created by andrey on 01.03.2021.
 */

var UnitSyncer = function (unitSaver, families) {
    this.unitSaver = unitSaver;
    this.slot = unitSaver.slot;
    this.syncers = {};
    this.index = {};

    CustomSyncers.importerData["units" + this.slot] = function (serverData) {
        this.updateInfo(serverData, true);
    }.bind(this);

    Object.keys(families).forEach(function (code) {
        this.createFamilySyncer(code);
    }.bind(this));
};

UnitSyncer.prototype.getKey = function (x, y) {
    return x + "_" + y;
};

UnitSyncer.prototype.createFamilySyncer = function (code) {
    if (code === "unknown" || Unit.DISABLED_CODES[code] || Unit.DELETED_CODES[code]) {
        return;
    }

    if (!this.syncers[code]) {
        this.syncers[code] = code === "intact" ? new IntactFamilySyncer(code, this.slot) : new FamilySyncer(code, this.slot);
    }

    return this.syncers[code];
};

UnitSyncer.prototype.reset = function (fromLoad) {
    this.index = {};

    Object.values(this.syncers).forEach(function (syncer) {
        syncer.reset(fromLoad);
    });
};

UnitSyncer.prototype.remove = function (x, y) {
    var key = this.getKey(x, y);
    var code = this.index[key];
    var syncer = this.syncers[code];
    if (syncer) {
        syncer.remove(x, y);
        delete this.index[key];
    }
};

UnitSyncer.prototype.save = function (x, y, data, fromLoad) {
    this.remove(x, y);

    var code = data && data.code;
    var syncer = this.createFamilySyncer(code);
    if (syncer) {
        var key = this.getKey(x, y);
        syncer.save(x, y, data, fromLoad);
        this.index[key] = code;
    }
};

UnitSyncer.prototype.updateInfo = function (serverData) {
    if (serverData === undefined) {
        return;
    }

    Object.keys(serverData).forEach(function (code) {
        this.createFamilySyncer(code);
    }, this);

    Object.values(this.syncers).forEach(function (syncer) {
        syncer.updateInfo(serverData[syncer.code] || {});
    }, this);

    var units = [];
    Object.values(this.syncers).forEach(function (syncer) {
        units = units.concat(syncer.listUnits());
    }, this);

    units = units.sort(UnitSyncer.UNITS_IMPORTANCE_ORDER);

    this.index = {};
    units.forEach(function (unit) {
        var key = this.getKey(unit.x, unit.y);
        if (!this.index[key]) {
            this.index[key] = unit.data.code;
        } else {
            unit.kickout = true;
        }
    }, this);

    this.unitSaver.updateInfo(units);
};

UnitSyncer.UNITS_IMPORTANCE_ORDER = function (unit1, unit2) {
    var data1 = Families[unit1.data.code] && Families[unit1.data.code].units[unit1.data.stage] || {};
    var data2 = Families[unit2.data.code] && Families[unit2.data.code].units[unit2.data.stage] || {};

    if ((data1.type === "hero" || data2.type === "hero") && data1.customer !== data2.customer) {
        return data1.customer ? -1 : 1;
    }

    if (data1.important !== data2.important) {
        return data1.important ? -1 : 1;
    }

    return unit2.data.stage - unit1.data.stage;
};
