/**
 * Created by andrey on 06.09.2021.
 */

var UnitsShop = function () {
    this.tabs = {};

    for (var tab in UnitsShop.PRODUCTS) {
        this.tabs[tab] = new UnitsShopTab(cleverapps.castType(tab));
    }

    this.load();
};

UnitsShop.prototype.getAvailableShops = function () {
    var pageId = cleverapps.meta.selectedLocationId();

    var shops = UnitsShop.SHOPS[pageId];

    if (!shops) {
        return [];
    }

    var payerClass = cleverapps.paymentsHistory.classify();
    var isVip = cleverapps.vipAdsAdapter.getVIPStatus().status;
    var isPaymentsConnected = connector.payments.isConnected();

    return shops.filter(function (shop) {
        if (shop.unit && !cleverapps.unitsLibrary.isOpened(shop.unit)) {
            return false;
        }

        if (shop.level && cleverapps.user.level < shop.level) {
            return false;
        }

        if ([ShopWindow.TABS.UNITS_ADS, ShopWindow.TABS.UNITS_ADS_UNDECIDED, ShopWindow.TABS.UNITS_ADS_VIP].includes(shop.tab)) {
            if (!cleverapps.rewardedAdsManager.isRewardedSupported()) {
                return false;
            }

            if (isVip) {
                return shop.tab === ShopWindow.TABS.UNITS_ADS_VIP;
            }

            if (payerClass === cleverapps.PaymentsHistory.BRACKET_UNDECIDED && isPaymentsConnected) {
                return shop.tab === ShopWindow.TABS.UNITS_ADS_UNDECIDED;
            }

            return shop.tab === ShopWindow.TABS.UNITS_ADS;
        }

        return true;
    });
};

UnitsShop.prototype.reset = function () {
    this.load({});
    this.save();
};

UnitsShop.prototype.isActiveTab = function (tab) {
    var hasAvailableLocation = function (locationIds) {
        return cleverapps.toArray(locationIds).some(function (locationId) {
            return cleverapps.meta.isLocationAvailable(locationId);
        });
    };

    switch (tab) {
        case ShopWindow.TABS.UNITS_EXPEDITION_HALLOWEEN: return hasAvailableLocation("halloween");
        case ShopWindow.TABS.UNITS_EXPEDITION_BLACKFRIDAY: return hasAvailableLocation("blackfriday");
        case ShopWindow.TABS.UNITS_EXPEDITION_XMAS: return hasAvailableLocation("xmas");
        case ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA: return hasAvailableLocation("dragonia");
        case ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA2: return hasAvailableLocation("dragonia2");
        case ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA3: return hasAvailableLocation("dragonia3");
        case ShopWindow.TABS.UNITS_EXPEDITION_UNDERSEA: return hasAvailableLocation(["undersea", "undersea2"]);
        case ShopWindow.TABS.UNITS_EXPEDITION_RAPUNZEL: return hasAvailableLocation(["rapunzel", "rapunzel2", "rapunzel3"]);
        case ShopWindow.TABS.UNITS_EXPEDITION_EASTER: return hasAvailableLocation("easter");
        case ShopWindow.TABS.UNITS_EXPEDITION_CHINA: return hasAvailableLocation("china");
    }

    return true;
};

UnitsShop.prototype.load = function (stored) {
    stored = stored || cleverapps.dataLoader.load(DataLoader.TYPES.UNITS_SHOP) || {};
    var needSave = false;
    for (var id in this.tabs) {
        if (stored[id] && !this.isActiveTab(id)) {
            stored[id] = undefined;
            needSave = true;
        }
        this.tabs[id].load(stored[id] || {});
    }

    if (needSave) {
        this.save();
    }
};

UnitsShop.prototype.save = function (fromServer) {
    cleverapps.dataLoader.save(DataLoader.TYPES.UNITS_SHOP, this.getInfo());

    if (!fromServer) {
        cleverapps.synchronizer.addUpdateTask("unitsshop");
    }
};

UnitsShop.prototype.getInfo = function () {
    var info = {};
    for (var id in this.tabs) {
        info[id] = this.tabs[id].save();
    }
    return info;
};

UnitsShop.prototype.updateInfo = function (serverData) {
    this.load(serverData || {});
    this.save(true);
};

UnitsShop.SHOPS = {
    main: [{
        tab: ShopWindow.TABS.UNITS_FRUIT,
        unit: { code: "fruitshop", stage: 0 }
    }, {
        tab: ShopWindow.TABS.UNITS_RESOURCE,
        unit: { code: "resourceshop", stage: 0 }
    }, {
        tab: ShopWindow.TABS.UNITS_DISCOUNT,
        unit: { code: "discountshop", stage: 0 }
    }, {
        tab: ShopWindow.TABS.UNITS_ADS,
        icon: bundles.tile_shop.frames.tab_cinema,
        level: 7
    }, {
        tab: ShopWindow.TABS.UNITS_ADS_UNDECIDED,
        icon: bundles.tile_shop.frames.tab_cinema,
        level: 7
    }, {
        tab: ShopWindow.TABS.UNITS_ADS_VIP,
        icon: bundles.tile_shop.frames.tab_cinema,
        level: 7
    }],

    dragonia: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA,
        unit: { code: "drshop", stage: 0 }
    }],

    dragonia2: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA2,
        unit: { code: "dr2shop", stage: 0 }
    }],

    dragonia3: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA3,
        unit: { code: "dr2shop", stage: 0 }
    }],

    undersea: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_UNDERSEA,
        unit: { code: "seashop", stage: 0 }
    }],

    undersea2: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_UNDERSEA,
        unit: { code: "seashop", stage: 0 }
    }],

    undersea3: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_UNDERSEA,
        unit: { code: "seashop", stage: 0 }
    }],

    halloween: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_HALLOWEEN,
        unit: { code: "dr2shop", stage: 0 }
    }],

    rapunzel: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_RAPUNZEL,
        unit: { code: "rpshop", stage: 0 }
    }],

    rapunzel2: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_RAPUNZEL,
        unit: { code: "rpshop", stage: 0 }
    }],

    rapunzel3: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_RAPUNZEL,
        unit: { code: "rpshop", stage: 0 }
    }],

    xmas: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_XMAS,
        unit: { code: "dr2shop", stage: 0 }
    }],

    easter: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_EASTER,
        unit: { code: "eashop", stage: 0 }
    }],

    collections: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_COLLECTIONS,
        unit: { code: "clshop", stage: 0 }
    }],

    china: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_CHINA,
        unit: { code: "dr2shop", stage: 0 }
    }],

    spring: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_SPRING,
        unit: { code: "drshop", stage: 0 }
    }],

    blackfriday: [{
        tab: ShopWindow.TABS.UNITS_EXPEDITION_BLACKFRIDAY,
        unit: { code: "dr2shop", stage: 0 }
    }]
};
