/**
 * Created by r4zi4l on 17.06.2021
 */

var KrakenFeastMissionWindow = FeastMissionWindow.extend({
    onWindowLoaded: function (mission) {
        this._super(mission, {
            name: "KrakenFeastMissionWindow",
            showSound: bundles.monster.urls.tentacles_spawn_effect,
            styles: {
                decors: cleverapps.styles.KrakenFeastMissionWindow.decors
            },
            notCloseByTouchInShadow: true,
            openSound: bundles.krakenfeast_window.urls.feast_window_effect
        });
    },

    getPerson: function () {
        return cleverapps.styles.KrakenFeastMissionWindow.person;
    },

    getGuideOptions: function () {
        return {
            name: "KrakenFeastGuideWindow",
            title: "KrakenFeastGuideWindow.title",
            bundle: bundles.krakenfeastguidewindow
        };
    }
});

cleverapps.styles.KrakenFeastMissionWindow = {
    person: {
        left: {
            role: "knight",
            emotion: "happy"
        },
        right: {
            role: "kraken",
            emotion: "idle"
        }
    }
};
