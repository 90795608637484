/**
 * Created by Andrey Popov on 25.04.22
 */

var ThirdElementPlanner = function (isNewGame) {
    this.thirdElements = [];

    if (isNewGame) {
        cleverapps.dataLoader.remove(this.saveId());
    } else {
        this.thirdElements = cleverapps.dataLoader.load(this.saveId()) || [];
    }
};

ThirdElementPlanner.prototype.save = function () {
    cleverapps.dataLoader.save(this.saveId(), this.thirdElements);
};

ThirdElementPlanner.prototype.saveId = function () {
    return DataLoader.TYPES.THIRDELEMENTS_PLANNER + Merge.currentMerge.location.slot;
};

ThirdElementPlanner.prototype.destructor = function () {
    this.clearTimeout();
};

ThirdElementPlanner.prototype.clearTimeout = function () {
    if (this.timeout !== undefined) {
        clearTimeout(this.timeout);
        this.timeout = undefined;
    }
};

ThirdElementPlanner.prototype.spawnPending = function () {
    if (this.thirdElements.length) {
        this.spawnLastOffer();
    }
};

ThirdElementPlanner.prototype.hasOffer = function (type) {
    return this.thirdElements.filter(function (offer) {
        return offer.type === type;
    }).length;
};

ThirdElementPlanner.prototype.planNext = function (options) {
    this.thirdElements.push(options);
    this.save();

    this.clearTimeout();
    this.timeout = setTimeout(this.spawnLastOffer.bind(this), options.delay || 0);
};

ThirdElementPlanner.prototype.spawnLastOffer = function () {
    this.clearTimeout();

    if (cleverapps.focusManager.isFocused() || Merge.currentMerge.counter.isActive()) {
        return;
    }

    var options = this.thirdElements.pop();
    if (!options) {
        return;
    }

    this.save();

    var config = ThirdElement.GetConfig(options.type, options.target);

    var prize = options.target || config.prize;
    var centerCell = Map2d.currentMap.getScreenCenterCell();
    var x = prize.x || centerCell.x;
    var y = prize.y || centerCell.y;

    var slot = Map2d.currentMap.findEmptySlot(x, y);
    if (slot) {
        var offer = new Unit({
            code: "thirdelement",
            stage: config.limit !== undefined ? 0 : 1,
            type: options.type,
            prize: {
                code: prize.code,
                stage: prize.stage
            }
        });
        offer.setPosition(slot.x, slot.y);
        Map2d.currentMap.add(Map2d.LAYER_UNITS, slot.x, slot.y, offer);
        Map2d.currentMap.onAddUnit(slot.x, slot.y, offer);
        offer.findComponent(ThirdElement).onAppearance();
    }

    if (this.thirdElements.length) {
        this.timeout = setTimeout(this.spawnLastOffer.bind(this), 600);
    }
};
