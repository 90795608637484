/**
 * Created by r4zi4l on 18.03.2021
 */

var EnergyLotteryWindow = CleverappsWindow.extend({
    onWindowLoaded: function (lottery) {
        this.lottery = lottery;

        this.lottery.on("play", this.createListener(this.play.bind(this)));
        this.lottery.on("finish", this.createListener(this.finish.bind(this)));
        this.lottery.on("prepareNext", this.createListener(this.prepareAxe.bind(this)));

        this._super({
            name: "EnergyLotteryWindow",
            content: this.createContent(),
            noBackground: true,
            closeButton: true,
            notCloseByTouchInShadow: true
        });

        this.prepareAxe();

        this.lottery.start();
    },

    getPerson: function () {
        return cleverapps.persons.choose("seller") ? {
            role: "seller",
            emotion: "idle"
        } : "worker";
    },

    createContent: function () {
        var styles = cleverapps.styles.EnergyLotteryWindow;

        var background = new cleverapps.Spine(bundles.energylottery.jsons.energy_lottery_json);
        background.setPositionRound(styles.background);
        background.setAnimation(0, "animation", true);

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize2(styles);
        container.addChild(background);

        var field = this.createField();
        field.setPositionRound(styles.field);
        container.addChild(field);

        var title = cleverapps.UI.generateOnlyText("EnergyLotteryWindow.title", cleverapps.styles.FONTS.ENERGY_LOTTERY_TITLE_TEXT);
        title.setPositionRound(styles.title);
        title.fitTo(styles.title.width, styles.title.height);
        container.addChild(title);

        var description = cleverapps.UI.generateOnlyText("EnergyLotteryWindow.description", cleverapps.styles.FONTS.ENERGY_LOTTERY_DESCRIPTION_TEXT);
        description.setPositionRound(styles.description);
        description.fitTo(styles.description.width, styles.description.height);
        container.addChild(description);

        var axe = this.axe = new cleverapps.Spine(bundles.energylottery.jsons.energy_lottery_axe_json);
        axe.setLocalZOrder(10);
        container.addChild(axe);

        return container;
    },

    createField: function () {
        var styles = cleverapps.styles.EnergyLotteryWindow.field;

        this.icons = [];
        for (var i = 0; i < this.lottery.getValues().length; i++) {
            this.icons.push(this.createLotIcon());
        }

        return new cleverapps.GridLayout(this.icons, {
            columns: 3,
            margin: styles.margin,
            padding: styles.padding
        });
    },

    createLotIcon: function () {
        var styles = cleverapps.styles.EnergyLotteryWindow.icon;

        var icon = new cc.Node();
        icon.setAnchorPoint(0.5, 0.5);
        icon.setContentSize2(styles);

        var animation = icon.animation = new cleverapps.Spine(bundles.energylottery.jsons.energy_lottery_icon_json);
        animation.setPositionRound(icon.width / 2, icon.height / 2);
        animation.setSkin("lottery");
        animation.setAnimation(0, "idle", true);
        icon.addChild(animation);

        cleverapps.UI.applyHover(icon);
        cleverapps.UI.onClick(icon, this.lottery.play.bind(this.lottery, { icon: icon }));

        return icon;
    },

    prepareAxe: function () {
        var styles = cleverapps.styles.EnergyLotteryWindow;

        this.axe.setVisible(false);
        this.axe.setPositionRound(styles.axe);
        this.axe.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.Show(),
            new cc.CallFunc(this.axe.setAnimationAndIdleAfter.bind(this.axe, "axe_emergence", "axe_idle"))
        ));
    },

    play: function (options) {
        var icon = options.icon;

        cc.eventManager.removeListeners(icon);

        icon.opened = true;

        this.throwAxe(options);
    },

    finish: function () {
        this.icons.forEach(function (icon) {
            cc.eventManager.removeListeners(icon);
            if (!icon.opened) {
                icon.animation.setAnimationAndIdleAfter("close", "idle_closed");
            }
        });
    },

    throwAxe: function (options) {
        var icon = options.icon;
        var amount = options.amount;

        cleverapps.audio.playSound(bundles.energylottery.urls.open_lot_effect);

        var axe = this.axe;
        axe.stopAllActions();
        axe.setAnimation(0, "axe_crash1", false);
        axe.setCompleteListener(function () {
            var offset = cleverapps.styles.EnergyLotteryWindow.axe.target.offset;
            var iconPosition = axe.getTargetPosition(icon);

            axe.setCompleteListener();
            axe.setAnimation(0, "axe_crash2", false);
            axe.runAction(new cc.Sequence(
                new cc.MoveTo(0.5667, iconPosition.x + offset.x, iconPosition.y + offset.y),
                new cc.CallFunc(function () {
                    this.axe.setVisible(false);
                    icon.animation.setAnimationAndIdleAfter("crash", "idle_crashed");
                    icon.setLocalZOrder(1);

                    var reward = cleverapps.UI.generateImageText(amount, cleverapps.styles.FONTS.ENERGY_LOTTERY_VALUE_TEXT);
                    reward.setPositionRound(cleverapps.styles.EnergyLotteryWindow.icon.reward);
                    icon.addChild(reward);

                    this.lottery.onAnimationFinished();
                }.bind(this))
            ));
        }.bind(this));

        Merge.currentMerge.addReward("energy", amount, icon, {
            delay: (axe.getTimeLeft() + 0.5667) * 1000,
            event: "lottery"
        });
    },

    listBundles: function () {
        return ["energylottery"];
    }
});

cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    ENERGY_LOTTERY_STROKE: {
        color: new cc.Color(95, 29, 17, 255),
        size: 1
    },

    ENERGY_LOTTERY_SHADOW: {
        color: new cc.Color(11, 13, 37, 128),
        direction: cc.size(0, -3),
        blur: 4
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ENERGY_LOTTERY_TITLE_TEXT: {
        size: 78,
        color: new cc.Color(255, 201, 39, 255),
        stroke: cleverapps.styles.DECORATORS.ENERGY_LOTTERY_STROKE,
        shadow: cleverapps.styles.DECORATORS.ENERGY_LOTTERY_SHADOW
    },
    ENERGY_LOTTERY_DESCRIPTION_TEXT: {
        size: 34,
        color: new cc.Color(255, 220, 132, 255),
        stroke: cleverapps.styles.DECORATORS.ENERGY_LOTTERY_STROKE,
        shadow: cleverapps.styles.DECORATORS.ENERGY_LOTTERY_SHADOW
    },
    ENERGY_LOTTERY_VALUE_TEXT: {
        size: 40,
        color: new cc.Color(122, 232, 255, 255),
        stroke: cleverapps.styles.DECORATORS.ENERGY_LOTTERY_STROKE
    }
});

cleverapps.styles.EnergyLotteryWindow = {
    width: 1200,
    height: 1000,

    background: {
        x: { align: "center" },
        y: { align: "center", dy: -45 }
    },

    field: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -28 },

        margin: {
            x: 50,
            y: 50
        },

        padding: {
            x: 30,
            y: 30
        }

    },

    icon: {
        width: 150,
        height: 150,

        reward: {
            x: { align: "center" },
            y: { align: "center", dy: -17 }
        }
    },

    title: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 396 },
        width: 600,
        height: 90
    },

    description: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 306 },
        width: 400,
        height: 50
    },

    axe: {
        x: { align: "right", dx: 170 - 15 },
        y: { align: "bottom", dy: -310 - 50 },
        target: {
            offset: {
                x: 709,
                y: -465
            }
        }
    }
};
