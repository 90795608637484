/**
 * Created by olga on 26.10.2022
 */

var CustomerHintContent = function (options) {
    this.customer = options.customer;
    this.unit = options.unit;

    this.doNotBlock = {
        x: this.unit.x,
        y: this.unit.y
    };
};

CustomerHintContent.isApplicable = function (options) {
    return options.customer !== undefined;
};

CustomerHintContent.prototype.hasSameContent = function (options) {
    return this.customer === options.customer && Unit.Equals(this.unit, options.unit);
};

CustomerHintContent.prototype.getMessages = function () {
    return [
        { text: "Customer.requiredUnit1" },
        { icon: UnitView.getUnitImage(this.unit, { preferStatic: true }) },
        { text: "Customer.requiredUnit2" }
    ];
};

CustomerHintContent.pointToCustomer = function (customerCode, unit) {
    var unitToScroll = Map2d.currentMap.listAvailableUnits({ code: unit.code, stage: unit.stage - 1 })[0];
    if (!unitToScroll) {
        return;
    }

    cleverapps.focusManager.display({
        focus: "PointToCustomer",
        keepControls: true,
        action: function (f) {
            Map2d.currentMap.focusOnUnit(unitToScroll, {
                allowScrollWithFocus: true,
                callback: function () {
                    FingerView.hintTap(unitToScroll, { runOnce: true });
                    cleverapps.centerHint.create({ customer: customerCode, unit: unit });
                    f();
                }
            });
        }
    });
};