/**
 * Created by razial on 14.01.2025.
 */

var MergeLocation = function (locationId) {
    BaseLocation.call(this, "merge", locationId);

    var page = MergeLogic.findPageById(locationId);

    this.slot = page.slot;
    this.prefix = page.prefix;
    this.gameLevel = page.gameLevel;
    this.energy = page.energy;
    this.wands = page.wands;
    this.workers = page.workers;
    this.magnet = page.magnet;
    this.dynamite = page.dynamite;
    this.parallelHeroDrop = page.parallelHeroDrop;
    this.noOrdersBuyout = page.noOrdersBuyout;
    this.childMissions = page.childMissions || [];

    this.families = Families.listExpeditionCodes(locationId);

    var bundle = bundles["location_" + locationId];
    this.meta = bundle.meta;
};

MergeLocation.prototype = Object.create(BaseLocation.prototype);
MergeLocation.prototype.constructor = MergeLocation;

MergeLocation.prototype.load = function () {

};

MergeLocation.prototype.getInfo = function () {
    return {};
};

MergeLocation.prototype.isMain = function () {
    return this.locationId === "main";
};

MergeLocation.prototype.isExpedition = function () {
    return this.locationId !== "main";
};

MergeLocation.prototype.isCompleted = function () {
    return cleverapps.meta.logic.isLocationCompleted(this.locationId);
};

MergeLocation.prototype.getStartTime = function () {
    return cleverapps.meta.logic.getLocationStartTime(this.locationId);
};

MergeLocation.prototype.getTimeLeft = function () {
    return cleverapps.meta.logic.getLocationTimeLeft(this.locationId);
};

MergeLocation.prototype.withEnergy = function () {
    return this.energy;
};

MergeLocation.prototype.withWands = function () {
    return this.wands;
};

MergeLocation.prototype.withWorkers = function () {
    return this.workers;
};

MergeLocation.prototype.withToolbarMagnet = function () {
    return this.magnet;
};

MergeLocation.prototype.withToolbarDynamite = function () {
    return this.dynamite;
};

MergeLocation.prototype.displayCompleted = function (f) {
    cleverapps.focusManager.compound(f, [
        function (f) {
            var steps = [{
                text: Messages.get("ExpeditionFinishWindow.text"),
                person: {
                    role: "king",
                    emotion: "happy"
                }
            }];

            var dialogue = new Dialogue(steps, {
                showUp: true
            });

            var closeDialogue = function () {
                if (!dialogue.typing && dialogue.stage >= dialogue.data.length - 1) {
                    dialogue.close();
                }
            };

            dialogue.on("buttonClicked", closeDialogue);
            dialogue.on("screenClicked", closeDialogue);
            dialogue.on("afterClose", f);

            new DialogueView(dialogue);
        },

        function (f) {
            Merge.currentMerge.collectAndRemoveAllUnits(f);
        },

        function (f) {
            var actions = [];

            this.childMissions.forEach(function (type) {
                var mission = cleverapps.missionManager.findByType(type);
                if (mission) {
                    actions.push(mission.displayCompleted.bind(mission));
                }
            });

            cleverapps.focusManager.compound(f, actions);
        }.bind(this),

        function (f) {
            if (!cleverapps.meta.getSelectedLocation().isExpedition()) {
                f();
                return;
            }

            cleverapps.meta.gotoMainLocation(f);
        },

        function (f) {
            var fogsOpened = FogSaver.Load(this.slot, this.locationId);
            fogsOpened = Array.isArray(fogsOpened) ? cleverapps.createSet(fogsOpened) : {};

            var reward;

            for (var id in MergeLocation.REWARDS) {
                if (fogsOpened[id]) {
                    reward = MergeLocation.REWARDS[id];
                }
            }

            if (reward) {
                new RewardWindow(reward, {
                    shareId: "bonus_world",
                    title: "ExpeditionRewardWindow.title",
                    text: "ExpeditionRewardWindow.description",
                    event: cleverapps.EVENTS.EARN.OTHER
                });
            }

            cleverapps.eventBus.trigger("taskEvent", DailyTasks.FINISH_EXPEDITION);

            cleverapps.meta.finishLocation(this.locationId);

            if (reward) {
                cleverapps.focusManager.onceNoWindowsListener = f;
            } else {
                f();
            }
        }.bind(this)
    ]);
};

MergeLocation.prototype.resetPush = function () {
    if (cleverapps.meta.logic.getLocationTimeLeft(this.locationId) >= MergeLocation.ATTENTION_TIMEOUT) {
        cleverapps.localPushes.sendPush(cleverapps.LocalPushes.TYPES.EXPEDITION, MergeLocation.ATTENTION_TIMEOUT);
    }
};

MergeLocation.prototype.sendPeriodicPush = function () {
    var lastSendTime = cleverapps.dataLoader.load(DataLoader.TYPES.EXPEDITION_PUSH_PLANNING_TIME) || 0;

    if (Date.now() > lastSendTime + MergeLocation.PERIODIC_PUSH_TIMEOUT && cleverapps.meta.logic.getLocationTimeLeft(this.locationId) >= MergeLocation.PERIODIC_PUSH_TIMEOUT) {
        cleverapps.localPushes.sendPush(cleverapps.LocalPushes.TYPES.EXPEDITION_PERIODIC, MergeLocation.PERIODIC_PUSH_TIMEOUT);

        cleverapps.dataLoader.save(DataLoader.TYPES.EXPEDITION_PUSH_PLANNING_TIME, Date.now());
    }
};

MergeLocation.GetMainScene = function () {
    return typeof Merge2Scene !== "undefined" ? Merge2Scene : MergeScene;
};

MergeLocation.ATTENTION_TIMEOUT = cleverapps.parseInterval("2 days");
MergeLocation.PERIODIC_PUSH_TIMEOUT = cleverapps.parseInterval("3 days");

if (cleverapps.config.debugMode) {
    MergeLocation.ATTENTION_TIMEOUT = cleverapps.parseInterval("10 minutes");
}

MergeLocation.REWARDS = {
    fog1: {
        soft: 10
    },
    fog6: {
        soft: 10,
        lives: 10
    },
    fog16: {
        soft: 20,
        lives: 20
    },
    fog26: {
        soft: 30,
        lives: 30
    },
    fog36: {
        soft: 50,
        lives: 50
    }
};