/**
 * Created by andrey on 26.12.2020.
 */

var EditorFogs = function () {
    this.alwaysVisible = false;

    this.blocks = {};
    this.fakeUnits = Fogs.ReadDefaults(Map2d.currentMap.field);
    this.loadConfig();
};

EditorFogs.prototype = Object.create(Fogs.prototype);
EditorFogs.prototype.constructor = EditorFogs;

EditorFogs.prototype.loadConfig = Fogs.prototype.loadConfig;
EditorFogs.prototype.add = function (x, y, type) {
    var regions = Map2d.currentMap.regions;
    var region = regions[type];
    var block = this.blocks[type];
    var head = !region.head;
    var balloons = block.isMoney() && !region.balloons;

    if (head) {
        region.head = cc.p(x, y);
    }
    if (balloons) {
        region.balloons = cc.p(x, y);
    }

    block.add(x, y, { head: head });
    balloons && block.addBalloon(x, y);

    region.positions.push(cc.p(x, y));
    if (type.startsWith("fence_")) {
        Map2d.currentMap.fences.onEdit(x, y, type);
    }

    var points = region.positions.filter(function (p) {
        return cc.pointEqualToPoint(p, cc.p(x, y));
    });
    if (points.length > 1) {
        cleverapps.throwAsync("Region " + type + " points non unique " + x + ", " + y);
    }

    Map2d.currentMap.saveLevel();
};

EditorFogs.prototype.remove = function (x, y, type) {
    var regions = Map2d.currentMap.regions;
    var region = regions[type];
    var block = this.blocks[type];
    var point = cc.p(x, y);

    block.remove(x, y);

    region.positions = region.positions.filter(function (position) {
        return !cc.pointEqualToPoint(position, point);
    });

    if (cc.pointEqualToPoint(region.head, point)) {
        delete region.head;
        if (region.positions.length > 0) {
            block.addHead(region.positions[0].x, region.positions[0].y);
            region.head = region.positions[0];
        }
    }

    if (cc.pointEqualToPoint(region.balloons, point)) {
        delete region.balloons;
        this.addBlockBalloon(block, region);
    }

    if (region.positions.length === 0) {
        delete regions[type];

        if (region.balloons) {
            block.remove(region.balloons.x, region.balloons.y);
        }
    }

    if (type.startsWith("fence_")) {
        Map2d.currentMap.fences.onEdit(x, y, type);
    }

    if (region.positions.length === 0) {
        delete regions[type];
    }

    Map2d.currentMap.saveLevel();
};

EditorFogs.prototype.produce = function (x, y, unit) {
    var regions = Map2d.currentMap.regions;

    var tile = Map2d.currentMap.getFog(x, y);
    var tileCode = tile && tile.fogBlock.id;

    var code = unit.code;
    var region = regions[code];
    if (!region) {
        region = regions[code] = {
            positions: []
        };
    }

    if (tile) {
        this.remove(x, y, tile.fogBlock.id);
    }

    if (tileCode !== unit.code) {
        this.add(x, y, unit.code);
    }
};

EditorFogs.prototype.createRegion = function (code) {
    Map2d.currentMap.regions[code] = {
        positions: []
    };
    Map2d.currentMap.saveLevel();
};

EditorFogs.prototype.renameRegion = function (oldCode, code) {
    Map2d.currentMap.regions[code] = Map2d.currentMap.regions[oldCode];
    delete Map2d.currentMap.regions[oldCode];

    this.blocks[oldCode].rename(code);
    this.blocks[code] = this.blocks[oldCode];
    delete this.blocks[oldCode];

    Map2d.currentMap.saveLevel();
};

EditorFogs.prototype.deleteRegion = function (code) {
    delete Map2d.currentMap.regions[code];
    this.hideBlock(code);
    Map2d.currentMap.saveLevel();
};

EditorFogs.prototype.clearRegion = function (unit) {
    var regions = Map2d.currentMap.regions;

    var code = unit.code;

    regions[code].positions.forEach(function (p) {
        this.blocks[code].remove(p.x, p.y);
    }, this);

    regions[code] = {
        positions: []
    };

    Map2d.currentMap.saveLevel();
};

EditorFogs.prototype.showBlock = function (code) {
    this.blocks[code] = new EditorFogBlock(code, this);

    var regions = Map2d.currentMap.regions;

    var region = regions[code] || {
        positions: []
    };

    if (!region.head && this.config[code] && region.positions.length > 0) {
        if (cleverapps.config.debugMode) {
            console.error("Error: head is not specified for region '" + code + "'");
        }
        region.head = cleverapps.Random.choose(region.positions);
    }

    if (!region.positions.length) {
        return;
    }

    this.addBlockTiles(this.blocks[code], region);
};

EditorFogs.prototype.hideBlock = function (code) {
    var block = this.blocks[code];
    block.tiles.forEach(function (tile) {
        tile.open();
        tile.animateOpen();
    });

    if (block.balloonTile) {
        block.balloonTile.open();
        block.balloonTile.animateOpen();
    }

    delete this.blocks[code];
};

EditorFogs.prototype.toggleAlwaysVisible = function (visible) {
    if (visible === this.alwaysVisible) {
        return;
    }

    this.alwaysVisible = visible;

    if (this.alwaysVisible) {
        cleverapps.substract(Object.keys(this.config), Object.keys(this.blocks)).forEach(this.showBlock.bind(this));
    } else {
        var toHide = Object.keys(this.config);

        if (this.selected) {
            toHide = cleverapps.substract(toHide, this.getShowCodes(this.selected));
        }

        cleverapps.intersect(Object.keys(this.blocks), toHide).forEach(this.hideBlock.bind(this));
    }
};

EditorFogs.prototype.getShowCodes = function (unit) {
    if (unit.isFog()) {
        return Object.keys(this.config);
    }

    var fencesNames = Map2d.currentMap.fences.getFencesNames();

    if (fencesNames && fencesNames.length > 0) {
        return fencesNames;
    }

    return [unit.code];
};

EditorFogs.prototype.select = function (unit) {
    if (this.selected === unit) {
        return;
    }

    this.selected = unit;

    var codes = this.getShowCodes(unit);
    if (!this.alwaysVisible) {
        cleverapps.substract(Object.keys(this.blocks), codes).forEach(this.hideBlock.bind(this));
    }

    cleverapps.substract(codes, Object.keys(this.blocks)).forEach(this.showBlock.bind(this));

    this.blocks[unit.code].tiles.forEach(function (tile) {
        tile.onUpdateState();
    });

    this.scrollTo(this.blocks[unit.code]);
};

EditorFogs.prototype.scrollTo = function (fogBlock) {
    if (!fogBlock.tiles.length) {
        return;
    }
    var targetTile = fogBlock.head || fogBlock.tiles[0];
    Map2d.currentMap.focusOnUnit(targetTile);
};

EditorFogs.prototype.getSelectedCode = function () {
    return this.selected && this.selected.code;
};

EditorFogs.prototype.unselect = function (unit) {
    if (this.selected === unit) {
        delete this.selected;

        var toHide = this.getShowCodes(unit);

        if (this.alwaysVisible) {
            toHide = cleverapps.substract(toHide, Object.keys(this.config));

            if (unit.isFog()) {
                this.blocks[unit.code].tiles.forEach(function (tile) {
                    tile.onUpdateState();
                });
            }
        }

        toHide.forEach(this.hideBlock.bind(this));
    }
};
