/**
 * Created by razial on 02.05.2024.
 */

var WarehouseIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.storage_icon_json,
        targets: "warehouse",
        control: "WarehouseIcon"
    });
};

WarehouseIcon.prototype = Object.create(SideBarIcon.prototype);
WarehouseIcon.prototype.constructor = WarehouseIcon;

WarehouseIcon.prototype.onPressed = function () {
    cleverapps.focusManager.display({
        stack: Merge.currentMerge && Merge.currentMerge.tutorial.isActive(),
        focus: "WarehouseIconClicked",
        action: function (f) {
            new WarehouseWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};

WarehouseIcon.prototype.resetState = function () {
    this.available = Merge.currentMerge && Merge.currentMerge.harvested && Merge.currentMerge.harvested.getCapacity();

    if (this.available) {
        if (Merge.currentMerge.harvested.isAlmostFull()) {
            var capacity = Merge.currentMerge.harvested.getCapacity();
            var amount = Merge.currentMerge.harvested.getItemsAmount();
            this.setTitle(amount + "/" + capacity);
        } else {
            this.setTitle(undefined);
        }
    }
};
