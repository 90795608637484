/**
 * Created by dmitry on 12.12.2024
 */

var IngredientsInfoView = cc.Node.extend({
    ctor: function (info) {
        this._super();

        var styles = cleverapps.styles.IngredientsInfoView;
        this.recipe = info.recipe;
        this.customer = info.customer;
        var ingredients = info.recipe.getIngredients();

        this.hasButton = cleverapps.config.name === "hearts";

        this.customerIngredientViews = ingredients.map(function (ingredient) {
            return new CustomerIngredientView(ingredient, CustomerIngredientView.TYPE_INFOVIEW);
        });

        var content = new cleverapps.Layout(this.customerIngredientViews, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });

        if (this.hasButton) {
            this.button = this.createButton();

            var rewardsBg = cleverapps.UI.createScale9Sprite(bundles.merge.frames.infoview_reward_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
            rewardsBg.setContentSize2(styles.rewards);
            var rewardsList = this.rewardsList = new RewardsListComponent(this.recipe.prizesToRewards(), {
                small: true,
                iconWrap: styles.rewards.iconWrap,
                prefix: "+",
                textDirection: cleverapps.UI.HORIZONTAL,
                textMargin: styles.rewards.margin,
                margin: styles.rewards.rewardsMargin,
                fitToBox: { width: rewardsBg.width, height: rewardsBg.height },
                font: cleverapps.styles.FONTS.INFOVIEW_REWARD_TEXT,
                event: cleverapps.EVENTS.EARN.OTHER
            });
            rewardsBg.addChild(rewardsList);
            rewardsList.setPositionRound(rewardsBg.width / 2, rewardsBg.height / 2);

            content = new cleverapps.Layout(
                [rewardsBg, content, this.button],
                {
                    direction: cleverapps.UI.VERTICAL,
                    padding: styles.paddingWithButton
                }
            );
        }

        this.setContentSize2(content.getContentSize());

        var background = InfoView.CreateInfoBackground(content.getContentSize());
        this.addChild(background);

        content.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(content);

        Map2d.currentMap.customers.on("onRecipesUpdated", this.onRecipesUpdated.bind(this), this);
    },

    onRecipesUpdated: function () {
        if (this.recipe.wasCooking()) {
            return;
        }
        var justGotReady = false;
        this.customerIngredientViews.forEach(function (ingredientView) {
            if (ingredientView.onAmountChanged()) {
                justGotReady = true;
            }
        });

        if (justGotReady) {
            this.runAction(new cc.Sequence(
                new cc.ScaleTo(0.26, 0.93, 0.85).easing(cc.easeInOut(1)),
                new cc.ScaleTo(0.2, 1.05).easing(cc.easeInOut(1)),
                new cc.ScaleTo(0.067, 1).easing(cc.easeInOut(1))
            ));
        }

        this.updateButton();
    },

    updateButton: function () {
        if (!this.hasButton) {
            return;
        }

        var newButton = this.createButton();
        this.button.parent.addChild(newButton);
        newButton.setPositionRound(this.button.getPosition());
        this.button.removeFromParent();
        this.button = newButton;
    },

    createButton: function () {
        var button = this.createBuyButton();
        if (!button) {
            button = new cleverapps.UI.Button({
                text: "Customer.Exchange",
                width: cleverapps.styles.IngredientsInfoView.button.width,
                height: cleverapps.styles.IngredientsInfoView.button.height,
                type: cleverapps.styles.UI.Button.Images.small_button_green,
                onClicked: this.exchange.bind(this)
            });
            button.adviceTarget = Map2d.SPAWN;

            if (this.customer.isEnoughIngredientsForRecipe()) {
                button.enable();
            } else {
                button.disable();
            }
        }
        button.setCascadeOpacityEnabledRecursively(true);
        return button;
    },

    createBuyButton: function () {
        var shoppingList = this.recipe.getShoppingList();
        if (!shoppingList.totalHard && !shoppingList.totalSoft) {
            return;
        }
        var totalHard = shoppingList.totalHard, totalSoft = shoppingList.totalSoft;

        var styles = cleverapps.styles.IngredientsInfoView.button;
        var msg = (totalSoft ? " @@" + totalSoft : "") + (totalHard ? " $$" + totalHard : "");

        return new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.small_button_blue,
            content: new TextWithIcon(msg),
            width: totalHard && totalSoft ? styles.buyWidthPair : styles.buyWidth,
            height: styles.height,
            onClicked: function () {
                if (!Map2d.currentMap.customers.canSpawnIngredients(shoppingList.hardItems.concat(shoppingList.softItems))) {
                    return;
                }

                if (totalHard) {
                    if (!cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.RECIPE_INGREDIENT_ALL, totalHard)) {
                        return;
                    }

                    Map2d.currentMap.customers.spawnIngredients(shoppingList.hardItems);
                }

                if (totalSoft && cleverapps.user.spendSoft(cleverapps.EVENTS.SPENT.RECIPE_INGREDIENT_ALL, totalSoft)) {
                    Map2d.currentMap.customers.spawnIngredients(shoppingList.softItems);
                }

                if (this.customer.isEnoughIngredientsForRecipe()) {
                    this.button.removeFromParent();
                    this.exchange();
                }
            }.bind(this)
        });
    },

    exchange: function () {
        var recipe = this.customer.getCurrentRecipe();
        var spawned = recipe.listPrize().length;
        var removed = recipe.getIngredients().reduce(function (total, ingredient) {
            return total + ingredient.amount;
        }, 0);
        var notEnough = spawned - removed - Map2d.currentMap.countEmptySlots();
        if (notEnough > 0) {
            cleverapps.centerHint.createTextHint("Spawn.nospace", { left: notEnough });
        } else {
            var upgradable = this.customer.getUpgradableTarget("castle");
            if (upgradable) {
                var starIcon = this.rewardsList.targets[0].icon;
                starIcon.runAction(new cc.Sequence(
                    StandartAnimations.animateCollect(starIcon, upgradable.view),
                    new cc.RemoveSelf(),
                    new cc.CallFunc(this.customer.exchange.bind(this.customer, undefined))
                ));
            } else {
                this.customer.exchange();
            }
        }
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    INFOVIEW_REWARD_TEXT: {
        size: 41,
        name: "lightstroke"
    }
});

cleverapps.styles.IngredientsInfoView = {
    margin: 24,

    padding: {
        x: 12,
        top: 12,
        bottom: 16
    },

    button: {
        width: 200,
        height: 90,
        buyWidth: 240,
        buyWidthPair: 300
    }
};