/**
 * Created by mac on 12/23/20
 */

var PulsingView = function (pulsing, unitView) {
    this.pulsing = pulsing;
    this.unitView = unitView;

    this.restoreState();
};

PulsingView.prototype.restoreState = function () {
    if (this.pulsing.isPulsing()) {
        this.createFlickering();
        this.startJumping();
    } else {
        this.removeFlickering();
        this.stopJumping();
    }
};

PulsingView.prototype.createFlickering = function () {
    if (this.flickering) {
        return;
    }

    var flickering = this.flickering = new cleverapps.Spine(bundles.merge.jsons.flickering_json);
    var anchorPoint = this.unitView.getAnchorPoint();
    flickering.setPositionRound(this.unitView.width * anchorPoint.x + cleverapps.styles.PulsingView.flickering.x, this.unitView.height * anchorPoint.y + cleverapps.styles.PulsingView.flickering.y);
    flickering.setLocalZOrder(-1);

    var animationName = this.getFlickeringAnimation();

    flickering.setAnimation(0, animationName, true);
    this.unitView.addChild(flickering);
};

PulsingView.prototype.getFlickeringAnimation = function () {
    var animationName = "yellow";
    if (cleverapps.config.name === "mergecraft" && Map2d.currentMap.getTerrainCode(this.unitView.unit.x, this.unitView.unit.y) === "s") {
        animationName = "blue";
    }
    return animationName;
};

PulsingView.prototype.delayFlickering = function () {
    if (!this.flickering) {
        return;
    }

    var animationName = this.getFlickeringAnimation();

    this.flickering.setVisible(false);
    this.flickering.runAction(new cc.Sequence(
        new cc.DelayTime(0.6),
        new cc.Show(),
        new cc.CallFunc(function () {
            this.setAnimation(0, animationName, true);
        }.bind(this.flickering))
    ));
};

PulsingView.prototype.removeFlickering = function () {
    if (!this.flickering) {
        return;
    }

    if (this.pulsing.dragging || this.pulsing.removed) {
        var mapView = Map2d.currentMap.getMapView();
        mapView.addTile(Map2d.LAYER_UNITS, this.unitView.unit.x, this.unitView.unit.y, this.flickering);
    }

    if (!this.flickering.visible) {
        this.flickering.removeFromParent();
    } else {
        this.flickering.setCompleteListenerRemove();
    }

    this.flickering = undefined;
};

PulsingView.prototype.startJumping = function () {
    if (this.jumpAction) {
        return;
    }

    this.jumpAction = this.unitView.sprite.runAction(new cc.Sequence(
        new cc.Repeat(new cc.Sequence(
            new cc.ScaleTo(1, 1.05, 0.95),
            new cc.ScaleTo(1, 1, 1)
        ), cleverapps.Random.random(1, 3)),
        StandartAnimations.jump(),
        new cc.CallFunc(function () {
            this.jumpAction = undefined;
            this.startJumping();
        }.bind(this))
    ));
};

PulsingView.prototype.stopJumping = function () {
    if (this.jumpAction) {
        this.unitView.sprite.stopAction(this.jumpAction);
        this.unitView.sprite.setScale(1);

        if (this.unitView.sprite.alignment) {
            this.unitView.sprite.doAlignment();
        }

        this.jumpAction = undefined;
    }
};

cleverapps.styles.PulsingView = {
    flickering: {
        x: 2,
        y: 4
    }
};