/**
 * Created by mac on 2/25/20
 */

var ChestListTemplates = {
    heroItems: function (type, config) {
        return Object.assign({
            hero: type,
            noHeroes: {
                coins: config
            }
        }, config);
    },

    extendResourceChest: function (type, stages) {
        return stages.map(function (config) {
            config = Object.assign({}, config);
            config[type] = config.resource;
            delete config.resource;
            return config;
        });
    }
};
    
var SourceChestBonues = [{
    heroitem: {
        hero: "hero",
        basic: [0, 0, 0],
        bonus: [1, 1],
        itemsProb: [0.58, 0.29, 0.13],
        prob: 0.5,
        noHeroes: {
            coins: {
                basic: [0, 0, 0],
                bonus: [1, 1],
                itemsProb: [0.58, 0.29, 0.13],
                prob: 0.5
            }
        }
    },
    fruit: {
        family: true,
        basic: [0],
        bonus: [1, 1],
        prob: 0.5
    }
}, {
    coins: {
        basic: [0],
        bonus: [2, 2],
        prob: 0.3
    },
    worker: {
        basic: [0],
        bonus: [1, 1],
        prob: 0.2
    },
    heroitem: {
        hero: "hero",
        basic: [0, 0, 0],
        bonus: [2, 2],
        itemsProb: [0.58, 0.29, 0.13],
        prob: 0.8,
        noHeroes: {
            coins: {
                basic: [1, 0, 0],
                bonus: [2, 2],
                itemsProb: [0.58, 0.29, 0.13],
                prob: 0.8
            }
        }
    },
    fruit: {
        family: true,
        basic: [0, -1, -1, -1, 0],
        bonus: [1, 3]
    }
}, {
    rubies: {
        basic: [0],
        bonus: [2, 2],
        prob: 0.3
    },
    coins: {
        basic: [0],
        bonus: [2, 2],
        prob: 0.3
    },
    crystal: {
        basic: [0],
        bonus: [2, 2],
        prob: 0.3
    },
    worker: {
        basic: [0],
        bonus: [0, 1]
    },
    heroitem: {
        hero: "hero",
        basic: [0, 0, 0],
        bonus: [2, 2],
        itemsProb: [0.58, 0.29, 0.13],
        prob: 0.8,
        noHeroes: {
            coins: {
                basic: [1, 0, 0],
                bonus: [2, 2],
                itemsProb: [0.58, 0.29, 0.13],
                prob: 0.8
            }
        }
    },
    fruit: {
        family: true,
        basic: [0, -1, -1, -1, 0],
        bonus: [2, 4]
    }
}];

var ChestListPrize = {
    treasure: [{
        rubies: {
            basic: [0, 0],
            bonus: [2, 3]
        },
        coins: {
            basic: [0, 0],
            bonus: [1, 2]
        }
    }, {
        rubies: {
            basic: [0, 1],
            bonus: [1, 2]
        },
        coins: {
            basic: [0, 2, 0],
            bonus: [2, 3]
        }
    }, {
        rubies: {
            basic: [0, 1, 0],
            bonus: [2, 3]
        },
        coins: {
            basic: [0, 2, 1],
            bonus: [4, 5]
        },
        crystal: {
            basic: [1],
            bonus: [0, 1]
        }
    }],

    freechest: [{
        fruit: {
            family: true,
            basic: [1, -1, -1, -1, 0],
            bonus: [1, 1],
            itemsProb: [0.85, 0, 0, 0, 0.15],
            prob: 0.3
        },
        coins: {
            basic: [0, 0],
            bonus: [2, 2],
            itemsProb: [0.66, 0.34],
            prob: 0.5
        },
        worker: {
            basic: [0],
            bonus: [2, 2],
            prob: 0.2
        },
        heroitem: {
            hero: "hero",
            basic: [1, 0],
            bonus: [1, 1],
            itemsProb: [0.66, 0.34],
            prob: 0.8,
            noHeroes: {
                coins: {
                    basic: [2, 0],
                    bonus: [1, 1],
                    itemsProb: [0.66, 0.34],
                    prob: 0.8
                }
            }
        }
    }, {
        fruit: {
            family: true,
            basic: [2, 0, -1, -1, 0],
            bonus: [2, 2],
            itemsProb: [0.6, 0.3, 0, 0, 0.1],
            prob: 0.5
        },
        coins: {
            basic: [1, 0],
            bonus: [3, 3],
            itemsProb: [0.66, 0.34],
            prob: 0.5
        },
        worker: {
            basic: [0],
            bonus: [2, 2],
            prob: 0.5
        },
        heroitem: {
            hero: "hero",
            basic: [0, 1, 0],
            bonus: [2, 2],
            itemsProb: [0.6, 0.3, 0.1],
            prob: 0.75,
            noHeroes: {
                coins: {
                    basic: [2, 1, 0],
                    bonus: [2, 2],
                    itemsProb: [0.6, 0.3, 0.1],
                    prob: 0.75
                }
            }
        },
        crystal: {
            basic: [0],
            bonus: [2, 2],
            prob: 0.4
        }
    }, {
        rubies: {
            basic: [0],
            bonus: [2, 2],
            prob: 0.25
        },
        fruit: {
            family: true,
            basic: [0, 2, 0, -1, 0],
            bonus: [3, 3],
            itemsProb: [0.5, 0.25, 0.15, 0, 0.1],
            prob: 0.65
        },
        coins: {
            basic: [1, 1, 0],
            bonus: [4, 4],
            itemsProb: [0.6, 0.25, 0.15],
            prob: 0.55
        },
        worker: {
            basic: [1, 0],
            bonus: [3, 3],
            itemsProb: [0.8, 0.2],
            prob: 0.5
        },
        heroitem: {
            hero: "hero",
            basic: [1, 0, 1],
            bonus: [2, 2],
            itemsProb: [0.6, 0.3, 0.1],
            prob: 0.75,
            noHeroes: {
                coins: {
                    basic: [4, 2, 1],
                    bonus: [2, 2],
                    itemsProb: [0.6, 0.3, 0.1],
                    prob: 0.75
                }
            }
        },
        crystal: {
            basic: [1, 0],
            bonus: [2, 2],
            itemsProb: [0.66, 0.34],
            prob: 0.5
        }
    }],

    herochest: [{
        coins: {
            basic: [0],
            bonus: [1, 1],
            prob: 0.8
        },
        worker: {
            basic: [0],
            bonus: [1, 2],
            prob: 0.3
        },
        heroitem: {
            hero: "hero",
            basic: [1, 0],
            bonus: [2, 4],
            prob: 0.6,
            noHeroes: {
                coins: {
                    basic: [2, 0],
                    bonus: [2, 4],
                    prob: 0.6
                }
            }
        }
    }, {
        coins: {
            basic: [1],
            bonus: [2, 2],
            prob: 0.8
        },
        worker: {
            basic: [0],
            bonus: [3, 3],
            prob: 0.3
        },
        heroitem: {
            hero: "hero",
            basic: [1, 1, 0],
            bonus: [3, 6],
            itemsProb: [0.25, 0.50, 0.25],
            prob: 0.6,
            noHeroes: {
                coins: {
                    basic: [3, 1, 0],
                    bonus: [3, 6],
                    itemsProb: [0.25, 0.50, 0.25],
                    prob: 0.6
                }
            }
        }
    }, {
        coins: {
            basic: [1, 0],
            bonus: [3, 3],
            itemsProb: [0.66, 0.34],
            prob: 0.5
        },
        worker: {
            basic: [1, 0],
            bonus: [3, 3],
            itemsProb: [0.66, 0.34],
            prob: 0.3
        },
        heroitem: {
            hero: "hero",
            basic: [1, 1, 1],
            bonus: [4, 6],
            itemsProb: [0.34, 0.33, 0.33],
            prob: 0.6,
            noHeroes: {
                coins: {
                    basic: [3, 2, 1],
                    bonus: [4, 6],
                    itemsProb: [0.34, 0.33, 0.33],
                    prob: 0.6
                }
            }
        }
    }],

    dailytaskchest: [{
        coins: {
            basic: [-1, 2, 0],
            bonus: [2, 2],
            itemsProb: [0, 0.66, 0.34],
            prob: 0.8
        },
        rubies: {
            basic: [-1, 1],
            bonus: [2, 2],
            prob: 0.6
        }
    }, {
        coins: {
            basic: [-1, 1, 1, 0],
            bonus: [4, 4],
            itemsProb: [0, 0.42, 0.42, 0.16],
            prob: 0.8
        },
        rubies: {
            basic: [-1, 1, 1],
            bonus: [3, 3],
            itemsProb: [0, 0.66, 0.34],
            prob: 0.6
        },
        worker: {
            basic: [1, 0],
            bonus: [2, 2],
            itemsProb: [0.66, 0.34],
            prob: 0.5
        }
    }, {
        coins: {
            basic: [-1, 1, 1, 1],
            bonus: [5, 5],
            itemsProb: [0, 0.34, 0.33, 0.33],
            prob: 0.8
        },
        rubies: {
            basic: [-1, 1, 2],
            bonus: [4, 4],
            itemsProb: [0, 0.66, 0.34],
            prob: 0.6
        },
        worker: {
            basic: [1, 0],
            bonus: [2, 2],
            itemsProb: [0.66, 0.34],
            prob: 0.5
        },
        crystal: {
            basic: [1, 0],
            bonus: [3, 3],
            itemsProb: [0.75, 0.25],
            prob: 0.5
        }
    }],

    woodchest: [
        Object.assign({
            wood: {
                basic: [3],
                resource: true
            }
        }, SourceChestBonues[0]),

        Object.assign({
            wood: {
                basic: [1, 1],
                bonus: [2, 4],
                itemsProb: [0.6, 0.4],
                resource: true
            }
        }, SourceChestBonues[1]),

        Object.assign({
            wood: {
                basic: [0, 0, 0],
                bonus: [7, 9],
                itemsProb: [0.2, 0.4, 0.4],
                resource: true
            }
        }, SourceChestBonues[2])
    ],

    stonechest: [
        Object.assign({
            stone: {
                basic: [1, 0],
                bonus: [2, 2],
                itemsProb: [0.7, 0.3],
                resource: true
            }
        }, SourceChestBonues[0]),

        Object.assign({
            stone: {
                basic: [0, 0, 0],
                bonus: [3, 5],
                itemsProb: [0.25, 0.5, 0.25],
                resource: true
            }
        }, SourceChestBonues[1]),

        Object.assign({
            stone: {
                basic: [0, 0, 0],
                bonus: [7, 9],
                itemsProb: [0.2, 0.4, 0.4],
                resource: true
            }
        }, SourceChestBonues[2])
    ],

    wagonchest: [
        Object.assign({
            wagon: {
                basic: [1, 0],
                bonus: [2, 2],
                itemsProb: [0.7, 0.3],
                resource: true
            }
        }, SourceChestBonues[0]),

        Object.assign({
            wagon: {
                basic: [0, 0, 0],
                bonus: [3, 5],
                itemsProb: [0.25, 0.5, 0.25],
                resource: true
            }
        }, SourceChestBonues[1]),

        Object.assign({
            wagon: {
                basic: [0, 0, 0],
                bonus: [7, 9],
                itemsProb: [0.2, 0.4, 0.4],
                resource: true
            }
        }, SourceChestBonues[2])
    ],

    bamboochest: [
        Object.assign({
            bamboo: {
                basic: [1, 0],
                bonus: [2, 2],
                itemsProb: [0.7, 0.3],
                resource: true
            }
        }, SourceChestBonues[0]),

        Object.assign({
            bamboo: {
                basic: [0, 0, 0],
                bonus: [3, 5],
                itemsProb: [0.25, 0.5, 0.25],
                resource: true
            }
        }, SourceChestBonues[1]),

        Object.assign({
            bamboo: {
                basic: [0, 0, 0],
                bonus: [7, 9],
                itemsProb: [0.2, 0.4, 0.4],
                resource: true
            }
        }, SourceChestBonues[2])
    ],

    sapphirechest: [
        Object.assign({
            sapphire: {
                basic: [1, 0],
                bonus: [2, 2],
                itemsProb: [0.7, 0.3],
                resource: true
            }
        }, SourceChestBonues[0]),

        Object.assign({
            sapphire: {
                basic: [0, 0, 0],
                bonus: [3, 5],
                itemsProb: [0.25, 0.5, 0.25],
                resource: true
            }
        }, SourceChestBonues[1]),

        Object.assign({
            sapphire: {
                basic: [0, 0, 0],
                bonus: [7, 9],
                itemsProb: [0.2, 0.4, 0.4],
                resource: true
            }
        }, SourceChestBonues[2])
    ],

    snowchest: [
        Object.assign({
            snow: {
                basic: [1, 0],
                bonus: [2, 2],
                itemsProb: [0.7, 0.3],
                resource: true
            }
        }, SourceChestBonues[0]),

        Object.assign({
            snow: {
                basic: [0, 0, 0],
                bonus: [3, 5],
                itemsProb: [0.25, 0.5, 0.25],
                resource: true
            }
        }, SourceChestBonues[1]),

        Object.assign({
            snow: {
                basic: [0, 0, 0],
                bonus: [7, 9],
                itemsProb: [0.2, 0.4, 0.4],
                resource: true
            }
        }, SourceChestBonues[2])
    ],

    lavachest: [
        Object.assign({
            lava: {
                basic: [1, 0],
                bonus: [2, 2],
                itemsProb: [0.7, 0.3],
                resource: true
            }
        }, SourceChestBonues[0]),

        Object.assign({
            lava: {
                basic: [0, 0, 0],
                bonus: [3, 5],
                itemsProb: [0.25, 0.5, 0.25],
                resource: true
            }
        }, SourceChestBonues[1]),

        Object.assign({
            lava: {
                basic: [0, 0, 0],
                bonus: [7, 9],
                itemsProb: [0.2, 0.4, 0.4],
                resource: true
            }
        }, SourceChestBonues[2])
    ],

    sandchest: [
        Object.assign({
            sand: {
                basic: [1, 0],
                bonus: [2, 2],
                itemsProb: [0.7, 0.3],
                resource: true
            }
        }, SourceChestBonues[0]),

        Object.assign({
            sand: {
                basic: [0, 0, 0],
                bonus: [3, 5],
                itemsProb: [0.25, 0.5, 0.25],
                resource: true
            }
        }, SourceChestBonues[1]),

        Object.assign({
            sand: {
                basic: [0, 0, 0],
                bonus: [7, 9],
                itemsProb: [0.2, 0.4, 0.4],
                resource: true
            }
        }, SourceChestBonues[2])
    ],

    asiachest: [
        Object.assign({
            asia: {
                basic: [1, 0],
                bonus: [2, 2],
                itemsProb: [0.7, 0.3],
                resource: true
            }
        }, SourceChestBonues[0]),

        Object.assign({
            asia: {
                basic: [0, 0, 0],
                bonus: [3, 5],
                itemsProb: [0.25, 0.5, 0.25],
                resource: true
            }
        }, SourceChestBonues[1]),

        Object.assign({
            asia: {
                basic: [0, 0, 0],
                bonus: [7, 9],
                itemsProb: [0.2, 0.4, 0.4],
                resource: true
            }
        }, SourceChestBonues[2])
    ],

    warcraftchest: [
        Object.assign({
            warcraft: {
                basic: [1, 0],
                bonus: [2, 2],
                itemsProb: [0.7, 0.3],
                resource: true
            }
        }, SourceChestBonues[0]),

        Object.assign({
            warcraft: {
                basic: [0, 0, 0],
                bonus: [3, 5],
                itemsProb: [0.25, 0.5, 0.25],
                resource: true
            }
        }, SourceChestBonues[1]),

        Object.assign({
            warcraft: {
                basic: [0, 0, 0],
                bonus: [7, 9],
                itemsProb: [0.2, 0.4, 0.4],
                resource: true
            }
        }, SourceChestBonues[2])
    ],

    steampunkchest: [
        Object.assign({
            steampunk: {
                basic: [1, 0],
                bonus: [2, 2],
                itemsProb: [0.7, 0.3],
                resource: true
            }
        }, SourceChestBonues[0]),

        Object.assign({
            steampunk: {
                basic: [0, 0, 0],
                bonus: [3, 5],
                itemsProb: [0.25, 0.5, 0.25],
                resource: true
            }
        }, SourceChestBonues[1]),

        Object.assign({
            steampunk: {
                basic: [0, 0, 0],
                bonus: [7, 9],
                itemsProb: [0.2, 0.4, 0.4],
                resource: true
            }
        }, SourceChestBonues[2])
    ],

    moonstonechest: [
        Object.assign({
            moonstone: {
                basic: [1, 0],
                bonus: [2, 2],
                itemsProb: [0.7, 0.3],
                resource: true
            }
        }, SourceChestBonues[0]),

        Object.assign({
            moonstone: {
                basic: [0, 0, 0],
                bonus: [3, 5],
                itemsProb: [0.25, 0.5, 0.25],
                resource: true
            }
        }, SourceChestBonues[1]),

        Object.assign({
            moonstone: {
                basic: [0, 0, 0],
                bonus: [7, 9],
                itemsProb: [0.2, 0.4, 0.4],
                resource: true
            }
        }, SourceChestBonues[2])
    ],

    sacks: [{
        fruit: {
            family: true,
            basic: [4, -1, -1, -1, 0],
            bonus: [0, 1]
        }
    }, {
        fruit: {
            family: true,
            basic: [7, -1, -1, -1, 1],
            bonus: [0, 2]
        }
    }, {
        fruit: {
            family: true,
            basic: [11, -1, -1, -1, 3],
            bonus: [0, 2]
        }
    }],

    seasonchest: [{
        seasonitem: {
            basic: [2],
            bonus: [1, 1],
            prob: 0.5
        }
    }, {
        seasonitem: {
            basic: [3, 1],
            bonus: [1, 1],
            itemsProb: [0.01, 0.99],
            prob: 0.5
        },
        coins: {
            basic: [-1, 2, 0],
            bonus: [2, 2],
            itemsProb: [0, 0.6, 0.4],
            prob: 0.5
        }
    }, {
        seasonitem: {
            basic: [6, 3, 1],
            bonus: [2, 2],
            itemsProb: [0.01, 0.59, 0.4],
            prob: 0.5
        },
        coins: {
            basic: [-1, -1, 2, 0],
            bonus: [2, 2],
            itemsProb: [0, 0, 0.67, 0.33],
            prob: 0.5
        },
        rubies: {
            basic: [-1, 1, 0],
            bonus: [2, 2],
            itemsProb: [0, 0.67, 0.33],
            prob: 0.5
        }
    }],

    drresourcechest: [{
        resource: {
            basic: [3],
            bonus: [1, 1],
            prob: 0.2
        },

        heroitem: ChestListTemplates.heroItems("drhero", {
            basic: [2, 1],
            bonus: [3, 3],
            itemsProb: [0.7, 0.3],
            prob: 0.55
        })
    }, {
        resource: {
            basic: [2, 2],
            bonus: [2, 2],
            itemsProb: [0.7, 0.3],
            prob: 0.4
        },

        heroitem: ChestListTemplates.heroItems("drhero", {
            basic: [3, 2, 1],
            bonus: [4, 4],
            itemsProb: [0.7, 0.25, 0.05],
            prob: 0.6
        }),

        coins: {
            basic: [1, 0, 0],
            bonus: [2, 2],
            itemsProb: [0.7, 0.25, 0.05],
            prob: 0.45
        }
    }, {
        resource: {
            basic: [1, 1, 2],
            bonus: [4, 4],
            itemsProb: [0.7, 0.25, 0.05],
            prob: 0.55
        },

        heroitem: ChestListTemplates.heroItems("drhero", {
            basic: [0, 4, 4],
            bonus: [4, 4],
            itemsProb: [0.7, 0.25, 0.05],
            prob: 0.6
        }),

        rubies: {
            basic: [0],
            bonus: [2, 2],
            prob: 0.20
        },

        coins: {
            basic: [0, 1, 0],
            bonus: [2, 2],
            itemsProb: [0.7, 0.25, 0.05],
            prob: 0.6
        }
    }],

    drshopchest: [{
        drresource: {
            family: true,
            basic: [4, 1],
            bonus: [4, 4],
            itemsProb: [0.85, 0.15],
            prob: 0.6
        },

        heroitem: ChestListTemplates.heroItems("drhero", {
            basic: [2, 3],
            bonus: [2, 2],
            itemsProb: [0.7, 0.3],
            prob: 0.5
        }),

        coins: {
            basic: [1, 0, 0],
            bonus: [2, 2],
            itemsProb: [0.6, 0.3, 0.1],
            prob: 0.5
        }
    }, {
        drresource: {
            family: true,
            basic: [2, 4, 1],
            bonus: [4, 4],
            itemsProb: [0.70, 0.25, 0.05],
            prob: 0.6
        },

        heroitem: ChestListTemplates.heroItems("drhero", {
            basic: [0, 4, 2],
            bonus: [5, 5],
            itemsProb: [0.7, 0.25, 0.05],
            prob: 0.6
        }),

        coins: {
            basic: [3, 0],
            bonus: [5, 5],
            itemsProb: [0.7, 0.3],
            prob: 0.5
        },

        rubies: {
            basic: [0],
            bonus: [2, 2],
            prob: 0.35
        }
    }, {
        drresource: {
            family: true,
            basic: [1, 4, 6],
            bonus: [6, 6],
            itemsProb: [0.70, 0.25, 0.05],
            prob: 0.6
        },

        heroitem: ChestListTemplates.heroItems("drhero", {
            basic: [0, 5, 8],
            bonus: [7, 7],
            itemsProb: [0.7, 0.25, 0.05],
            prob: 0.6
        }),

        coins: {
            basic: [2, 3, 0],
            bonus: [6, 6],
            itemsProb: [0.7, 0.25, 0.05],
            prob: 0.6
        },

        rubies: {
            basic: [1, 0],
            bonus: [3, 3],
            itemsProb: [0.9, 0.1],
            prob: 0.35
        }
    }],

    drfreechest: [{
        drresource: {
            family: true,
            basic: [1, 1],
            bonus: [3, 3],
            itemsProb: [0.85, 0.15],
            prob: 0.35
        },

        heroitem: ChestListTemplates.heroItems("drhero", {
            basic: [3, 1],
            bonus: [4, 4],
            itemsProb: [0.7, 0.3],
            prob: 0.5
        }),

        coins: {
            basic: [0],
            bonus: [2, 2],
            prob: 0.5
        }
    }, {
        drresource: {
            family: true,
            basic: [2, 3],
            bonus: [3, 3],
            itemsProb: [0.85, 0.15],
            prob: 0.5
        },

        heroitem: ChestListTemplates.heroItems("drhero", {
            basic: [3, 5],
            bonus: [5, 5],
            itemsProb: [0.85, 0.15],
            prob: 0.4
        }),

        coins: {
            basic: [1, 0],
            bonus: [3, 3],
            itemsProb: [0.7, 0.3],
            prob: 0.5
        },

        rubies: {
            basic: [0],
            bonus: [3, 3],
            prob: 0.35
        }
    }, {
        drresource: {
            family: true,
            basic: [3, 3, 2],
            bonus: [3, 3],
            itemsProb: [0.85, 0.10, 0.05],
            prob: 0.5
        },

        heroitem: ChestListTemplates.heroItems("drhero", {
            basic: [2, 3, 4],
            bonus: [6, 6],
            itemsProb: [0.85, 0.10, 0.05],
            prob: 0.5
        }),

        coins: {
            basic: [2, 1, 0],
            bonus: [3, 3],
            itemsProb: [0.6, 0.3, 0.1],
            prob: 0.5
        },

        rubies: {
            basic: [1, 0],
            bonus: [3, 3],
            itemsProb: [0.9, 0.1],
            prob: 0.45
        }
    }],

    drsack: [{
        drfruit: {
            family: true,
            basic: [2, 1],
            itemsProb: [0.99, 0.01],
            bonus: [3, 3],
            prob: 0.35
        },
        energy: {
            basic: [0],
            bonus: [1, 1],
            prob: 0.3
        }
    }, {
        drfruit: {
            family: true,
            basic: [1, 1, 1],
            itemsProb: [0.79, 0.2, 0.01],
            bonus: [4, 4],
            prob: 0.4
        },
        worker: {
            basic: [0],
            bonus: [1, 1],
            prob: 0.3
        },
        energy: {
            basic: [0],
            bonus: [1, 1],
            prob: 0.5
        }
    }, {
        drfruit: {
            family: true,
            basic: [0, 2, 2],
            itemsProb: [0.4, 0.4, 0.2],
            bonus: [0, 2],
            prob: 0.4
        },
        worker: {
            basic: [0],
            bonus: [1, 1],
            prob: 0.5
        },
        coins: {
            basic: [0],
            bonus: [2, 2],
            prob: 0.6
        },
        energy: {
            basic: [0],
            bonus: [2, 2],
            prob: 0.5
        }
    }],

    searesourcechest: [{
        resource: {
            basic: [1],
            bonus: [1, 1],
            prob: 0.15
        },

        heroitem: ChestListTemplates.heroItems("seahero", {
            basic: [2],
            bonus: [3, 3],
            prob: 0.5
        })
    }, {
        resource: {
            basic: [0, 1],
            bonus: [2, 2],
            itemsProb: [0.85, 0.15],
            prob: 0.2
        },

        heroitem: ChestListTemplates.heroItems("seahero", {
            basic: [2, 2],
            bonus: [3, 3],
            itemsProb: [0.5, 0.5],
            prob: 0.45
        }),

        worker: {
            basic: [0],
            bonus: [2, 2],
            prob: 0.25
        }
    }, {
        resource: {
            basic: [2, 2],
            bonus: [1, 1],
            itemsProb: [0.5, 0.5],
            prob: 0.5
        },

        heroitem: ChestListTemplates.heroItems("seahero", {
            basic: [2, 2, 2],
            bonus: [3, 3],
            itemsProb: [0.3, 0.3, 0.4],
            prob: 0.7
        }),

        worker: {
            basic: [1],
            bonus: [2, 2],
            prob: 0.25
        },

        coins: {
            basic: [0],
            bonus: [2, 2],
            prob: 0.25
        }
    }],

    seasack: [{
        seafruit: {
            family: true,
            basic: [2, 1],
            itemsProb: [0.99, 0.01],
            bonus: [3, 3],
            prob: 0.35
        },
        energy: {
            basic: [0],
            bonus: [1, 1],
            prob: 0.3
        }
    }, {
        seafruit: {
            family: true,
            basic: [1, 1, 1],
            itemsProb: [0.79, 0.2, 0.01],
            bonus: [4, 4],
            prob: 0.4
        },
        worker: {
            basic: [0],
            bonus: [1, 1],
            prob: 0.3
        },
        energy: {
            basic: [0],
            bonus: [1, 1],
            prob: 0.5
        }
    }, {
        seafruit: {
            family: true,
            basic: [0, 2, 2],
            itemsProb: [0.4, 0.4, 0.2],
            bonus: [0, 2],
            prob: 0.4
        },
        worker: {
            basic: [0],
            bonus: [1, 1],
            prob: 0.5
        },
        coins: {
            basic: [0],
            bonus: [2, 2],
            prob: 0.6
        },
        energy: {
            basic: [0],
            bonus: [2, 2],
            prob: 0.5
        }
    }],

    seashopchest: [{
        searesource: {
            family: true,
            basic: [0, 1],
            bonus: [1, 1],
            itemsProb: [0.99, 0.01],
            prob: 0.2
        },
        heroitem: ChestListTemplates.heroItems("seahero", {
            basic: [0, 3],
            bonus: [1, 1],
            itemsProb: [0.99, 0.01],
            prob: 0.6
        })
    }, {
        searesource: {
            family: true,
            basic: [0, 3],
            bonus: [1, 1],
            itemsProb: [0.75, 0.25],
            prob: 0.4
        },
        heroitem: ChestListTemplates.heroItems("seahero", {
            basic: [0, 0, 3],
            bonus: [2, 2],
            itemsProb: [0.45, 0.45, 0.1],
            prob: 0.6
        }),
        worker: {
            basic: [0, 0],
            itemsProb: [0.5, 0.5],
            bonus: [1, 1],
            prob: 0.75
        }
    }, {
        searesource: {
            family: true,
            basic: [0, 0, 3],
            bonus: [2, 2],
            itemsProb: [0.45, 0.45, 0.1],
            prob: 0.5
        },
        heroitem: ChestListTemplates.heroItems("seahero", {
            basic: [0, 0, 7],
            bonus: [2, 2],
            itemsProb: [0.34, 0.33, 0.33],
            prob: 0.7
        }),
        worker: {
            basic: [0, 1],
            itemsProb: [0.5, 0.5],
            bonus: [1, 1],
            prob: 0.75
        },
        crystal: {
            basic: [0, 0],
            bonus: [1, 1],
            itemsProb: [0.5, 0.5],
            prob: 0.75
        }
    }],

    seafreechest: [{
        searesource: {
            family: true,
            basic: [1, 1],
            bonus: [3, 3],
            itemsProb: [0.85, 0.15],
            prob: 0.35
        },

        heroitem: ChestListTemplates.heroItems("seahero", {
            basic: [3, 1],
            bonus: [4, 4],
            itemsProb: [0.7, 0.3],
            prob: 0.5
        }),

        coins: {
            basic: [0],
            bonus: [2, 2],
            prob: 0.5
        }
    }, {
        searesource: {
            family: true,
            basic: [2, 3],
            bonus: [3, 3],
            itemsProb: [0.85, 0.15],
            prob: 0.5
        },

        heroitem: ChestListTemplates.heroItems("seahero", {
            basic: [3, 5],
            bonus: [5, 5],
            itemsProb: [0.85, 0.15],
            prob: 0.4
        }),

        coins: {
            basic: [1, 0],
            bonus: [3, 3],
            itemsProb: [0.7, 0.3],
            prob: 0.5
        },

        rubies: {
            basic: [0],
            bonus: [3, 3],
            prob: 0.35
        }
    }, {
        searesource: {
            family: true,
            basic: [3, 3, 2],
            bonus: [3, 3],
            itemsProb: [0.85, 0.10, 0.05],
            prob: 0.5
        },

        heroitem: ChestListTemplates.heroItems("seahero", {
            basic: [2, 3, 4],
            bonus: [6, 6],
            itemsProb: [0.85, 0.10, 0.05],
            prob: 0.5
        }),

        coins: {
            basic: [2, 1, 0],
            bonus: [3, 3],
            itemsProb: [0.6, 0.3, 0.1],
            prob: 0.5
        },

        rubies: {
            basic: [1, 0],
            bonus: [3, 3],
            itemsProb: [0.9, 0.1],
            prob: 0.45
        }
    }],

    rpfreechest: [
        {
            rpresource: {
                family: true,
                basic: [-1, 6],
                itemsProb: [0, 1],
                bonus: [0, 2]
            },
            coins: {
                basic: [0],
                bonus: [0, 1],
                prob: 0.1
            }
        }
    ],
    rpshopchest: [
        {
            rpresource: {
                family: true,
                basic: [-1, -1, -1, 6],
                itemsProb: [0, 0, 0, 1],
                bonus: [0, 2]
            },
            coins: {
                basic: [-1, -1, 0],
                bonus: [0, 1],
                prob: 0.1
            },
            rubies: {
                basic: [0],
                bonus: [0, 1],
                prob: 0.01
            }
        }
    ],
    eafreechest: [
        {
            earesource: {
                family: true,
                basic: [-1, 6],
                itemsProb: [0, 1],
                bonus: [0, 2]
            },
            coins: {
                basic: [0],
                bonus: [0, 1],
                prob: 0.1
            }
        }
    ],
    eashopchest: [
        {
            earesource: {
                family: true,
                basic: [-1, -1, -1, 6],
                itemsProb: [0, 0, 0, 1],
                bonus: [0, 2]
            },
            coins: {
                basic: [-1, -1, 0],
                bonus: [0, 1],
                prob: 0.1
            },
            rubies: {
                basic: [0],
                bonus: [0, 1],
                prob: 0.01
            }
        }
    ],
    clsack: [
        {
            clfruit: {
                family: true,
                basic: [8]
            }
        }
    ],
    clpawchest: [
        {
            clpet: {
                basic: [1, 2],
                family: { openPenalty: 0.5 },
                bonus: [1, 1],
                itemsProb: [0.5, 0.5],
                prob: 0.5
            },
            clpetrare: {
                basic: [1],
                family: { openPenalty: 0.5 },
                bonus: [1, 1],
                prob: 0.15
            },
            clpetlegend: {
                basic: [0],
                family: { openPenalty: 0.5 },
                bonus: [1, 1],
                prob: 0.5
            },
            crystal: {
                basic: [0],
                bonus: [1, 1],
                prob: 0.05
            }
        }],
    clpaychest: [
        {
            clpet: {
                basic: [5, 0],
                family: { openPenalty: 0.5 },
                bonus: [2, 2],
                itemsProb: [0.5, 0.5],
                prob: 0.5
            },
            clpetrare: {
                basic: [3, 0],
                family: { openPenalty: 0.5 },
                bonus: [2, 2],
                itemsProb: [0.65, 0.35],
                prob: 0.5
            },
            clpetlegend: {
                basic: [1],
                family: true
            },
            crystal: {
                basic: [0],
                bonus: [2, 2],
                prob: 0.3
            }
        },
        {
            clpet: {
                basic: [0, 5],
                family: { openPenalty: 0.5 },
                bonus: [2, 2],
                itemsProb: [0.5, 0.5],
                prob: 0.5
            },
            clpetrare: {
                basic: [1, 2],
                family: { openPenalty: 0.5 },
                bonus: [2, 2],
                itemsProb: [0.5, 0.5],
                prob: 0.5
            },
            clpetlegend: {
                basic: [2, 0],
                family: { openPenalty: 0.5 },
                bonus: [2, 2],
                itemsProb: [0.65, 0.35],
                prob: 0.5
            },
            crystal: {
                basic: [0, 0],
                bonus: [2, 2],
                prob: 0.30
            }
        },
        {
            clpet: {
                basic: [0, 2, 2],
                family: { openPenalty: 0.5 },
                bonus: [2, 2],
                itemsProb: [0.3, 0.35, 0.35],
                prob: 0.5
            },
            clpetrare: {
                basic: [0, 1, 2],
                family: { openPenalty: 0.5 },
                bonus: [2, 2],
                itemsProb: [0.3, 0.35, 0.35],
                prob: 0.5
            },
            clpetlegend: {
                basic: [0, 1, 1],
                family: { openPenalty: 0.5 },
                bonus: [2, 2],
                itemsProb: [0.35, 0.5, 0.15],
                prob: 0.5
            },
            crystal: {
                basic: [1, 0],
                bonus: [3, 3],
                prob: 0.30
            }
        }],
    clsimplechest: [
        {
            clpet: {
                basic: [1, 1],
                family: { openPenalty: 0.5 },
                bonus: [2, 2],
                itemsProb: [0.5, 0.5],
                prob: 0.5
            },
            clpetrare: {
                basic: [0],
                family: { openPenalty: 0.5 },
                bonus: [1, 1],
                prob: 0.5
            }
        },
        {
            clpet: {
                basic: [0, 0, 1],
                family: { openPenalty: 0.5 },
                bonus: [2, 2],
                itemsProb: [0.6, 0.3, 0.1],
                prob: 0.5
            },
            clpetrare: {
                basic: [1, 0],
                family: { openPenalty: 0.5 },
                bonus: [1, 1],
                itemsProb: [0.75, 0.25],
                prob: 0.5
            },
            crystal: {
                basic: [0],
                bonus: [1, 1],
                prob: 0.1
            }
        },
        {
            clpet: {
                basic: [0, 2, 1],
                family: { openPenalty: 0.5 },
                bonus: [2, 2],
                itemsProb: [0.65, 0.25, 0.1],
                prob: 0.5
            },
            clpetrare: {
                basic: [1, 1],
                family: true,
                bonus: [2, 2],
                itemsProb: [0.75, 0.25],
                prob: 0.5
            },
            clpetlegend: {
                basic: [2, 0],
                family: true,
                bonus: [1, 1],
                itemsProb: [0.75, 0.25],
                prob: 0.5
            },
            crystal: {
                basic: [0],
                bonus: [1, 1],
                prob: 0.20
            }
        }]
};

ChestListPrize.coinsplantchest = [
    Object.assign({}, ChestListPrize.treasure[0]),

    Object.assign({}, ChestListPrize.treasure[1]),

    Object.assign({
        coinstree: {
            basic: [0, 1]
        }
    }, ChestListPrize.treasure[2])
];

ChestListPrize.rubiesplantchest = [
    Object.assign({}, ChestListPrize.treasure[0]),

    Object.assign({}, ChestListPrize.treasure[1]),

    Object.assign({
        rubiesplant: {
            basic: [1]
        }
    }, ChestListPrize.treasure[2])
];

ChestListPrize.energyplantchest = [
    Object.assign({}, ChestListPrize.treasure[0]),

    Object.assign({}, ChestListPrize.treasure[1]),

    Object.assign({
        energytree: {
            basic: [0, 1]
        }
    }, ChestListPrize.treasure[2])
];

ChestListPrize.drwoodchest = ChestListTemplates.extendResourceChest("drwood", ChestListPrize.drresourcechest);
ChestListPrize.drstonechest = ChestListTemplates.extendResourceChest("drstone", ChestListPrize.drresourcechest);
ChestListPrize.drsapphirechest = ChestListTemplates.extendResourceChest("drsapphire", ChestListPrize.drresourcechest);
ChestListPrize.drsledchest = ChestListTemplates.extendResourceChest("drsled", ChestListPrize.drresourcechest);
ChestListPrize.drcastlechest = ChestListTemplates.extendResourceChest("drcastle", ChestListPrize.drresourcechest);

ChestListPrize.drwoodchest = ChestListTemplates.extendResourceChest("drwood", ChestListPrize.drresourcechest);
ChestListPrize.drstonechest = ChestListTemplates.extendResourceChest("drstone", ChestListPrize.drresourcechest);
ChestListPrize.drsapphirechest = ChestListTemplates.extendResourceChest("drsapphire", ChestListPrize.drresourcechest);
ChestListPrize.drsledchest = ChestListTemplates.extendResourceChest("drsled", ChestListPrize.drresourcechest);
ChestListPrize.drcastlechest = ChestListTemplates.extendResourceChest("drcastle", ChestListPrize.drresourcechest);
ChestListPrize.drpurplecrystalchest = ChestListTemplates.extendResourceChest("drpurplecrystal", ChestListPrize.drresourcechest);
ChestListPrize.drbamboochest = ChestListTemplates.extendResourceChest("drbamboo", ChestListPrize.drresourcechest);
ChestListPrize.drfeatherschest = ChestListTemplates.extendResourceChest("drfeathers", ChestListPrize.drresourcechest);

ChestListPrize.seacrabchest = ChestListTemplates.extendResourceChest("seacrab", ChestListPrize.searesourcechest);
ChestListPrize.seafisheschest = ChestListTemplates.extendResourceChest("seafishes", ChestListPrize.searesourcechest);
ChestListPrize.seasharkchest = ChestListTemplates.extendResourceChest("seashark", ChestListPrize.searesourcechest);
ChestListPrize.seaoctopuschest = ChestListTemplates.extendResourceChest("seaoctopus", ChestListPrize.searesourcechest);
ChestListPrize.seahorsechest = ChestListTemplates.extendResourceChest("seahorse", ChestListPrize.searesourcechest);
ChestListPrize.sealochnesschest = ChestListTemplates.extendResourceChest("sealochness", ChestListPrize.searesourcechest);
ChestListPrize.seaturtlechest = ChestListTemplates.extendResourceChest("seaturtle", ChestListPrize.searesourcechest);
ChestListPrize.seashrimpschest = ChestListTemplates.extendResourceChest("seashrimps", ChestListPrize.searesourcechest);

var ChestCreatePrize = {
    treasure: [undefined, { exp: 1 }, { exp: 2 }],
    herochest: [undefined, { exp: 1 }, { exp: 2 }],
    freechest: [undefined, { exp: 1 }, { exp: 2 }],
    dailytaskchest: [undefined, { exp: 1 }, { exp: 2 }],
    coinsplantchest: [undefined, { exp: 1 }, { exp: 2 }],
    rubiesplantchest: [undefined, { exp: 1 }, { exp: 2 }],
    energyplantchest: [undefined, { exp: 1 }, { exp: 2 }],
    woodchest: [undefined, { exp: 1 }, { exp: 3 }],
    resourcechest: [undefined, { exp: 5 }, { exp: 15 }],
    sacks: [undefined, { exp: 1 }, { exp: 2 }]
};

var ChestEnergyPrize = {
    treasure: [4, 12, 36],
    freechest: [1, 3, 9],
    herochest: [3, 9, 27],
    dailytaskchest: [15, 30, 60],
    coinsplantchest: [3, 9, 27],
    rubiesplantchest: [3, 9, 27],
    energyplantchest: [3, 9, 27],
    resourcechest: [3, 9, 27],
    sacks: [1, 3, 9],
    seasonchest: [1, 3, 9]
};

if (typeof cc === "undefined") {
    module.exports = {
        ChestListPrize: ChestListPrize,
        ChestCreatePrize: ChestCreatePrize,
        ChestEnergyPrize: ChestEnergyPrize
    };
}