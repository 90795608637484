/**
 * Created by vladislav on 25/10/2022
 */

Quests.InitMainConfig = function (location, questsConfig) {
    Object.keys(location.families).forEach(function (code) {
        var family = Families[code];

        if (["hero", "resource", "magicplant"].indexOf(family.type) === -1 && code !== "crystal") {
            return;
        }

        Unit.getAllStagesTargets(code).filter(function (unit) {
            var curStage = unit.stage - 1, notFirst = false;
            while (curStage >= 0) {
                if (!family.units[curStage].temporary && !family.units[curStage].deleted) {
                    notFirst = true;
                }
                curStage--;
            }

            if (!notFirst) {
                return false;
            }

            if (family.units[unit.stage].temporary || family.units[unit.stage].deleted) {
                return false;
            }

            if (unit.code === "dwarf" || questsConfig[Unit.GetKey(unit)]) {
                return false;
            }

            return true;
        }).forEach(function (unit) {
            var data = family.units[unit.stage];
            var isBuildable = data.buildable && cleverapps.parseInterval(data.buildable) > 0;

            var triggerStage = unit.stage - 1;
            while (family.units[triggerStage].deleted) {
                triggerStage--;
            }

            questsConfig[Unit.GetKey(unit)] = {
                trigger: {
                    unit: {
                        code: unit.code,
                        stage: triggerStage
                    }
                },
                dynamic: {
                    filter: function (options) {
                        if (options && options.create) {
                            var units = UnitsLibrary.ListByCode(unit.code);
                            var lastOpened = cleverapps.unitsLibrary.findLastOpenedUnit(units);
                            return !lastOpened || lastOpened.stage < unit.stage;
                        }

                        return true;
                    }
                },
                quest: {
                    type: isBuildable ? Map2d.BUILD : Map2d.SPAWN,
                    unit: unit
                }
            };

            if (family.type === "magicplant" && (unit.stage === 1 || unit.stage === 9)) {
                delete questsConfig[Unit.GetKey(unit)].trigger;
            }

            if (!isBuildable && family.type === "hero" && unit.stage === Unit.getAllStagesTargets(unit.code).pop().stage) {
                questsConfig[Unit.GetKey(unit)].trigger.unit.stage = 0;
            }
        });
    });

    if (questsConfig.crystal_1) {
        questsConfig.crystal_1.trigger = undefined;
    }
    if (questsConfig.crystal_2) {
        questsConfig.crystal_2.trigger = undefined;
    }
    if (questsConfig.crystal_3) {
        questsConfig.crystal_3.trigger = undefined;
    }
};

Quests.InitConfig = (function () {
    var initialized = {};

    return function (location) {
        if (initialized[location.locationId]) {
            return;
        }
        initialized[location.locationId] = true;

        var questsConfig = QuestsConfig[location.locationId];

        if (location.locationId === "main") {
            Quests.InitMainConfig(location, questsConfig);
        }

        Object.keys(questsConfig).forEach(function (id) {
            var config = questsConfig[id];
            config.quest.id = id;

            var questsData = bundles["quests_" + location.meta.units].jsons.quests_json.resolve().getJson();

            Quests.appendJsonDataToQuest(config, questsData[id]);
        });
    };
}());

Quests.appendJsonDataToQuest = function (config, data) {
    if (!data) {
        if (config.quest.trigger) {
            console.error("No data for quest '" + config.quest.i + "'");
        }
        return;
    }

    config.dialogue = data.dialogue;

    if (data.goal === undefined) {
        console.error("No goal specified for quest '" + config.quest.i + "'");
        return;
    }
    config.quest.goal = data.goal;

    if (data.prize === undefined) {
        console.error("No prize specified for quest '" + config.quest.i + "'");
        return;
    }
    config.quest.prize = data.prize;
};