/**
 * Created by Andrey Popov on 10.11.22
 */

var Climbable = function (unit) {
    UnitComponent.call(this, unit);
};

Climbable.prototype = Object.create(UnitComponent.prototype);
Climbable.prototype.constructor = Climbable;

Climbable.prototype.getActionInfo = function () {
    var trigger = Climbable.GetTrigger(this.unit);
    if (!trigger) {
        return;
    }

    return {
        title: {
            code: trigger.code,
            stage: trigger.stage,
            icon: true
        },
        buttons: {
            locate: {
                action: function () {
                    UnitLocator.find(Map2d.OPEN_UNIT, trigger);
                }
            }
        }
    };
};

Climbable.prototype.gotoNextStage = function (f) {
    f = f || function () {};

    if (this.climbed) {
        f();
        return;
    }
    this.climbed = true;
    this.unit.movable = false;

    var newUnit = new Unit({
        code: this.unit.code,
        stage: this.unit.stage + 1
    });

    var replace = function () {
        this.unit.takePrizes();
        this.unit.remove(true);

        newUnit.setPosition(this.unit.x, this.unit.y);
        Map2d.currentMap.onAddUnit(newUnit.x, newUnit.y, newUnit);
        Map2d.currentMap.onUnitAvailable(newUnit);
        Merge.currentMerge.counter.setTimeout(Map2d.mapEvent.bind(Merge.currentMerge, Map2d.SPAWN, { unit: newUnit }), 0);

        Map2d.currentMap.blockedGrounds.updateBlockedGrounds();
    }.bind(this);

    var view = this.view;

    if (view) {
        view.beforeStageUpdate();
        replace();
        view.updateStage(newUnit, function () {
            Climbable.CheckDependantClimbers(newUnit);
            f();
        }.bind(this));
    } else {
        replace();
        Climbable.CheckDependantClimbers(newUnit);
        f();
    }
};

Climbable.GetTrigger = function (unit) {
    var trigger = Climbable.TRIGGERS[cleverapps.meta.selectedLocationId()];
    return trigger && trigger[Unit.GetKey(unit)];
};

Climbable.CreateCinematic = function (unit) {
    var component = unit.findComponent(Climbable);
    if (!component) {
        return;
    }

    var trigger = Climbable.GetTrigger(unit);
    if (!trigger || !Map2d.currentMap.listAvailableUnits(trigger).length) {
        return;
    }

    return function (f) {
        component.gotoNextStage(f);
    };
};

Climbable.CheckDependantClimbers = function (newUnit) {
    var triggers = Climbable.TRIGGERS[cleverapps.meta.selectedLocationId()];
    if (!triggers) {
        return;
    }

    var triggerKey = Object.keys(triggers).filter(function (triggerKey) {
        return Unit.Equals(triggers[triggerKey], newUnit);
    })[0];

    if (!triggerKey) {
        return;
    }

    var dependant = Map2d.currentMap.listAvailableUnits(Unit.ParseKey(triggerKey));
    dependant.forEach(function (unitToChangeStage) {
        var climbable = unitToChangeStage.findComponent(Climbable);
        if (climbable) {
            climbable.gotoNextStage();
        }
    });
};

Climbable.TRIGGERS = {};