/**
 * Created by razial on 16.01.2025.
 */

var MergeLogic = function () {

};

MergeLogic.prototype.reset = function () {
    this.locations = {
        main: {
            slot: Meta.SLOT_MAIN
        }
    };
};

MergeLogic.prototype.isLocationAvailable = function (locationId) {
    return MergeLogic.findPageById(locationId) !== undefined;
};

MergeLogic.prototype.getNextLocation = function () {
    return this.listAvailableLocations().find(function (locationId) {
        return this.canUseSlot(locationId) && this.canStartLocation(locationId);
    }.bind(this));
};

MergeLogic.prototype.getMainLocation = function () {
    return "main";
};

MergeLogic.prototype.getLocationClass = function () {
    return MergeLocation;
};

MergeLogic.prototype.updateInfo = function (data) {
    data = data || {};

    this.reset();

    Object.keys(data.locations || {}).forEach(function (locationId) {
        this._setLocation(locationId, data.locations[locationId]);
    }.bind(this));
};

MergeLogic.prototype.getInfo = function () {
    return {
        locations: Object.assign({}, this.locations, {
            main: undefined
        })
    };
};

MergeLogic.prototype._setLocation = function (locationId, locationInfo) {
    this.locations[locationId] = locationInfo;

    if (MergeLogic.IsActive(locationId, locationInfo)) {
        cleverapps.playSession.set(cleverapps.EVENTS.LOCATION.ACTIVE, true, locationId);
    }
};

MergeLogic.prototype.listAvailableLocations = function () {
    return MergeLogic.PAGES.map(function (page) {
        return page.id;
    });
};

MergeLogic.prototype.listActiveLocations = function () {
    return Object.keys(this.locations).filter(function (locationId) {
        return MergeLogic.IsActive(locationId, this.locations[locationId]);
    }.bind(this));
};

MergeLogic.prototype.canRemoveSilently = function (locationId) {
    if (this.getLocationFinishTime(locationId) + MergeLogic.AUTO_REMOVE_TIMEOUT < Date.now()) {
        return true;
    }

    var page = MergeLogic.findPageById(locationId);
    var save = Merge3Adapter.LoadInfo(page.slot);
    return save.expedition !== locationId;
};

MergeLogic.prototype.canStartLocation = function (locationId) {
    var page = MergeLogic.findPageById(locationId);
    if (!page) {
        return false;
    }

    if (page.feature && !cleverapps.eventManager.isActive(page.feature)) {
        return false;
    }

    var locationInfo = this.locations[locationId];
    if (locationInfo && MergeLogic.IsFinished(locationId, locationInfo) && page.oneShot) {
        return false;
    }

    return !page.available || cleverapps.user.checkAvailable(page.available);
};

MergeLogic.prototype.canUseSlot = function (locationId) {
    var page = MergeLogic.findPageById(locationId);

    return Object.keys(this.locations).every(function (locationId) {
        var locationInfo = this.locations[locationId];
        return MergeLogic.IsFinished(locationId, locationInfo) || locationInfo.slot !== page.slot;
    }.bind(this));
};

MergeLogic.prototype.getLocationStartTime = function (locationId) {
    var locationInfo = this.locations[locationId];
    return locationInfo && locationInfo.started || 0;
};

MergeLogic.prototype.getLocationFinishTime = function (locationId) {
    var location = this.locations[locationId];
    if (!location) {
        return 0;
    }

    var page = MergeLogic.findPageById(locationId);

    if (page.feature) {
        var event = cleverapps.eventManager.getFeatureEvent(page.feature)
            || cleverapps.eventManager.getLastFeatureEvent(page.feature);
        return event ? event.finish.getTime() : 0;
    }

    if (!page.duration) {
        return 0;
    }

    return location.started + cleverapps.parseInterval(page.duration);
};

MergeLogic.prototype.getLocationTimeLeft = function (locationId) {
    return Math.max(0, this.getLocationFinishTime(locationId) - Date.now());
};

MergeLogic.prototype.isLocationPassed = function (locationId) {
    var page = MergeLogic.findPageById(locationId);
    var locationInfo = this.locations[locationId];
    return locationInfo && MergeLogic.IsFinished(locationId, locationInfo) && page.oneShot;
};

MergeLogic.prototype.getNextLocationFeatureEvent = function (locationId) {
    var page = MergeLogic.findPageById(locationId);
    return page.feature !== undefined && cleverapps.eventManager.getNextFeatureEvent(page.feature);
};

MergeLogic.prototype.isLocationCompleted = function (locationId) {
    var page = MergeLogic.findPageById(locationId);
    return page.id !== "main" && !page.permanent && this.getLocationTimeLeft(locationId) <= 0;
};

MergeLogic.prototype.isCompleted = function () {
    return false;
};

MergeLogic.prototype.startLocation = function (locationId) {
    var locationInfo = this.locations[locationId];

    if (MergeLogic.IsUnknown(locationId, locationInfo)) {
        cleverapps.throwAsync("try to replace unknown location " + locationId);
        return;
    }

    var page = MergeLogic.findPageById(locationId);

    Merge3Adapter.SaveInfo(page.slot, {});

    LivesHelper.Reset(page.slot);

    UnitsLibrary.resetLocationFamilies(locationId);

    if (page.missionType) {
        cleverapps.offerManager.clearForceByMission(page.missionType);
    }

    Landmarks.clearForce(locationId);

    this._setLocation(locationId, {
        version: page.version,
        slot: page.slot,
        started: Date.now()
    });

    cleverapps.meta.adapter.startLocation(locationId);

    cleverapps.travelBook.onNewLocationStarted(locationId);

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.LOCATION.STARTED + locationId + "_" + page.version);
};

MergeLogic.prototype.finishLocation = function (locationId) {
    var locationInfo = this.locations[locationId];
    if (MergeLogic.IsFinished(locationId, locationInfo)) {
        return;
    }

    var page = MergeLogic.findPageById(locationId);
    if (!page) {
        return;
    }

    this._setLocation(locationId, {
        version: locationInfo.version,
        finished: Date.now()
    });

    cleverapps.meta.adapter.finishLocation(locationId);

    cleverapps.travelBook.updatePages();

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.LOCATION.FINISHED + locationId + "_" + locationInfo.version);
};

MergeLogic.prototype.overrideLocationInfo = function (locationId, data) {
    var locationInfo = this.locations[locationId];

    Object.keys(data).forEach(function (key) {
        locationInfo[key] = data[key];
    });
};

MergeLogic.prototype.replaceOldLocation = function () {
    var locationId = Object.keys(this.locations).find(function (locationId) {
        var locationInfo = this.locations[locationId];
        return !MergeLogic.IsUnknown(locationId, locationInfo) && !MergeLogic.IsFinished(locationId, locationInfo) && MergeLogic.IsOld(locationId, locationInfo);
    }.bind(this));

    if (!locationId) {
        return;
    }

    if (!this.canStartLocation(locationId)) {
        cleverapps.meta.finishLocation(locationId);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.LOCATION.FINISHED_OLD + locationId);
        return;
    }

    cleverapps.focusManager.display({
        focus: "replaceOldLocation" + locationId,
        actions: [
            function (f) {
                new ReplaceOldMissionWindow(locationId);
                cleverapps.focusManager.onceNoWindowsListener = f;
            },

            function (f) {
                cleverapps.meta.finishLocation(locationId);
                cleverapps.meta.startLocation(locationId);
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.LOCATION.REPLACED_OLD + locationId);
                f();
            }
        ]
    });
};

MergeLogic.prototype.processChatMessage = function () {

};

MergeLogic.IsUnknown = function (locationId, locationInfo) {
    var page = MergeLogic.findPageById(locationId);
    return !page || locationInfo && page.version < locationInfo.version;
};

MergeLogic.IsOld = function (locationId, locationInfo) {
    var page = MergeLogic.findPageById(locationId);
    return page && locationInfo && page.version > locationInfo.version;
};

MergeLogic.IsFinished = function (locationId, locationInfo) {
    return locationInfo && locationInfo.finished !== undefined;
};

MergeLogic.IsActive = function (locationId, locationInfo) {
    return !MergeLogic.IsUnknown(locationId, locationInfo) && !MergeLogic.IsFinished(locationId, locationInfo) && !MergeLogic.IsOld(locationId, locationInfo);
};

MergeLogic.AUTO_REMOVE_TIMEOUT = cleverapps.parseInterval("14 days");
