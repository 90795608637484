/**
 * Created by Vladislav on 18.09.2024.
 */

cleverapps.InitConfigByType["merge"] = function () {
    Families.initialize();
};

cleverapps.InitByType["merge"] = function () {
    cleverapps.gameLevel = new GameLevel();

    Pocket.initialize();
    Landmarks.initialize();
    CustomerRecipe.initialize();

    if (typeof StopHustlemergeFeatures !== "undefined") {
        StopHustlemergeFeatures();
    }

    if (typeof StopHeartsFeatures !== "undefined") {
        StopHeartsFeatures();
    }

    if (typeof StopGardenFeatures !== "undefined") {
        StopGardenFeatures();
    }

    if (["mergecraft", "wondermerge", "fairy"].indexOf(cleverapps.config.name) !== -1) {
        cleverapps.growthFund = new GrowthFund();
    }

    if (typeof UnitSavers !== "undefined") {
        cleverapps.unitSavers = new UnitSavers();
    }

    if (typeof FogSavers !== "undefined") {
        cleverapps.fogSavers = new FogSavers();
    }

    cleverapps.unitsShop = new UnitsShop();
};