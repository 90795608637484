/**
 * Created by r4zi4l on 29.11.2021
 */

var MissionTreeView = function (missionTree, unitView) {
    this.missionTree = missionTree;
    this.unitView = unitView;

    cleverapps.aims.registerTarget("mission" + missionTree.missionType, unitView.sprite, {
        priority: 1,
        filter: this.unitView.createListener(function () {
            return missionTree.isCurrentTree();
        })
    });

    this.restoreState();
};

MissionTreeView.prototype.clearAnimations = function () {
    if (this.onCreateAction && !this.onCreateAction.isDone()) {
        this.unitView.stopAction(this.onCreateAction);
    }
    if (this.onProgressAction && !this.onProgressAction.isDone()) {
        this.progress.stopAction(this.onProgressAction);
    }
};

MissionTreeView.prototype.restoreState = function (animated) {
    this.createGuideButton();
    this.createProgress();

    if (this.progress) {
        this.progress.setPercentage(this.missionTree.calcPercent(), {
            animated: animated
        });
    }
};

MissionTreeView.prototype.createGuideButton = function () {
    var needsHelp = this.missionTree.needsHelp();

    if (needsHelp && !this.guideButton) {
        this.guideButton = new cleverapps.UI.HelpButton({
            callback: this.showGuideWindow.bind(this)
        });
        this.guideButton.setPositionRound(this.unitView.width * 0.84, this.unitView.height * 0.73);
        this.unitView.addChild(this.guideButton);
    }

    if (!needsHelp && this.guideButton) {
        this.guideButton.removeFromParent();
        this.guideButton = undefined;
    }
};

MissionTreeView.prototype.createProgress = function () {
    if (this.progress || !this.missionTree.needsProgress() || !this.missionTree.calcPercent() || cleverapps.gameModes.hideTankProgress) {
        return;
    }

    var styles = cleverapps.styles.MissionTreeView.progress;

    this.progress = new ScaledProgressBar({
        type: ScaledProgressBar.Types.blue_small
    });
    this.progress.setAnchorPoint(0.5, 0.5);
    this.progress.setPositionRound(styles);
    this.progress.setLength(styles.width);
    this.unitView.addChild(this.progress);
};

MissionTreeView.prototype.onUpgrade = function (oldUnitView) {
    oldUnitView.onRemove();

    if (this.progress) {
        this.progress.setScale(0);
        this.onProgressAction = this.progress.runAction(new cc.ScaleTo(0.5, 1).easing(cc.easeBackOut()));
    }

    cleverapps.bundleLoader.ensureSoundsLoaded(bundles.merge.urls.battlepasstree_remove_effect);
    var duration = 0.5;
    oldUnitView.unitView.sprite.runAction(StandartAnimations.upgrade(oldUnitView.unitView, this.unitView));

    cleverapps.scenes.getRunningScene().runAction(new cc.Sequence(
        new cc.PlaySound(bundles.merge.urls.unit_desturction_effect),
        new cc.DelayTime(duration),
        new cc.PlaySound(bundles.merge.urls.battlepasstree_remove_effect)
    ));
};

MissionTreeView.prototype.onRemove = function () {
    if (this.progress) {
        this.progress.removeFromParent();
    }
    if (this.guideButton) {
        this.guideButton.removeFromParent();
    }
};

MissionTreeView.prototype.showGuideWindow = function () {
    var name = cleverapps.skins.getSlot("skinName") + "_multipass_guidewindow";
    cleverapps.focusManager.display({
        focus: "MissionTreeGuideWindow",
        action: function (f) {
            new GuideWindow({
                name: name,
                bundle: bundles[name]
            });
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};

cleverapps.styles.MissionTreeView = {
    progress: {
        width: 84,
        x: { align: "center" },
        y: { align: "bottom", dy: -15 }
    }
};
