/**
 * Created by r4zi4l on 25.01.2021
 */

var Harvested = function (adapter) {
    cleverapps.EventEmitter.call(this);

    this.adapter = adapter;

    this.config = Harvested.CONFIG[Merge.currentMerge.location.locationId] || {};

    this.level = 0;
    this.items = {};

    this.load();
};

Harvested.prototype = Object.create(cleverapps.EventEmitter.prototype);
Harvested.prototype.constructor = Harvested;

Harvested.prototype.load = function () {
    var stored = this.adapter.load("harvested") || {};
    if (stored.items) {
        this.level = stored.level;
        this.items = stored.items;
    } else {
        this.level = 0;
        this.items = stored;
    }
};

Harvested.prototype.save = function () {
    var stored = this.items;

    if (this.level) {
        stored = {
            level: this.level,
            items: this.items
        };
    }

    this.adapter.save("harvested", stored);
};

Harvested.prototype.onChange = function () {
    this.trigger("onChangeItems");

    if (Map2d.currentMap) {
        Map2d.currentMap.customers.onRecipesUpdated();
    }

    cleverapps.toolbar.resetByType(ToolbarItem.ORDERS);
    cleverapps.sideBar.resetByClassName(WarehouseIcon);
};

Harvested.prototype.get = function (code) {
    return this.items[code] || 0;
};

Harvested.prototype.add = function (code, value, silent) {
    this.items[code] = (this.items[code] || 0) + value;
    this.save();
    if (!silent) {
        this.onChange();
    }
};

Harvested.prototype.canTake = function (code, value) {
    return this.items[code] && this.items[code] >= value;
};

Harvested.prototype.isIngredientAvailable = function (ingredient) {
    return Boolean(this.config.resources && this.config.resources[ingredient]);
};

Harvested.prototype.canTakeList = function (ingredients) {
    for (var code in ingredients) {
        if (!this.canTake(code, ingredients[code])) {
            return false;
        }
    }
    return true;
};

Harvested.prototype.take = function (code, value) {
    if (this.canTake(code, value)) {
        this.items[code] -= value;
        this.save();
        this.onChange();
        return true;
    }
    return false;
};

Harvested.prototype.takeList = function (ingredients) {
    if (this.canTakeList(ingredients)) {
        for (var code in ingredients) {
            this.items[code] -= ingredients[code];
        }
        this.save();
        this.onChange();
        return true;
    }
    return false;
};

Harvested.prototype.calcMissingIngredients = function (ingredients) {
    var result = {};

    for (var code in ingredients) {
        var amount = ingredients[code] - this.get(code);
        if (amount > 0) {
            result[code] = amount;
        }
    }
    return result;
};

Harvested.prototype.getIngredientIcon = function (ingredientCode) {
    var icon;

    var data = this.config.resources && this.config.resources[ingredientCode];
    if (data && data.icon) {
        icon = new cc.Sprite(data.icon);
    } else {
        var stage;
        if (Families[ingredientCode].type === "herofood") {
            stage = Families[ingredientCode].units.length - 1;
        } else {
            stage = Merge.currentMerge.location.locationId === "main" ? 4 : 0;
        }

        icon = UnitView.getUnitImage({
            code: ingredientCode,
            stage: stage
        }, { preferStatic: true });
    }

    return icon;
};

Harvested.prototype.getIngredientSmallIcon = function (ingredient) {
    var icon;

    var data = this.config.resources && this.config.resources[ingredient];
    if (data && data.icon) {
        icon = new cc.Sprite(data.icon);
    } else {
        icon = this.getIngredientIcon(ingredient);
    }

    cleverapps.UI.fitToBox(icon, cleverapps.styles.Reward.smallIcon);

    return icon;
};

Harvested.prototype.getIngredientName = function (ingredient) {
    var data = this.config.resources && this.config.resources[ingredient];
    if (data) {
        return Messages.get("Ingredient." + ingredient + ".name");
    }

    return cleverapps.unitsLibrary.getUnitName({
        code: ingredient,
        stage: Merge.currentMerge.location.locationId === "main" ? 4 : 0
    });
};

Harvested.prototype.listResources = function () {
    return Object.keys(this.items).filter(function (ingredient) {
        return !this.config.resources || this.config.resources[ingredient];
    }.bind(this)).map(function (ingredients) {
        return {
            ingredient: ingredients,
            amount: this.items[ingredients] || 0
        };
    }.bind(this));
};

Harvested.prototype.getItemsAmount = function () {
    return Object.values(this.items).reduce(function (result, amount) {
        return result + amount;
    }, 0);
};

Harvested.prototype.getCapacity = function () {
    return (this.config.capacity || 0) + this.level * 20;
};

Harvested.prototype.getExchangeMinimum = function () {
    return this.config.exchangeMinimum || 0;
};

Harvested.prototype.isFull = function () {
    return this.getItemsAmount() >= this.getCapacity();
};

Harvested.prototype.isAlmostFull = function () {
    return this.getItemsAmount() / this.getCapacity() >= 0.9;
};

Harvested.prototype.upgradeCapacity = function () {
    this.level += 1;
    this.save();
    this.onChange();
};

Harvested.prototype.getUpgradePrice = function () {
    return this.config.price || 0;
};

Harvested.prototype.needsToShowTip = function () {
    this.wantsToShowTip = true;

    Merge.currentMerge.counter.setTimeout(function () {}, 0);
};

Harvested.prototype.showHint = function () {
    if (!this.wantsToShowTip || cleverapps.focusManager.isFocused()) {
        return;
    }

    cleverapps.focusManager.display({
        focus: "warehouseGuide",
        actions: [
            function (f) {
                cleverapps.timeouts.setTimeout(f, 500);
            },

            function (f) {
                new WarehouseWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            },

            function (f) {
                this.wantsToShowTip = false;
                f();
            }.bind(this)
        ]
    });
};

Harvested.CONFIG = {

};
