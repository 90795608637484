/**
 * Created by razial on 11.03.2025.
 */

var FakeMap2dAdapter = function () {

};

FakeMap2dAdapter.prototype.load = function () {

};

FakeMap2dAdapter.prototype.save = function () {

};

FakeMap2dAdapter.prototype.remove = function () {

};
