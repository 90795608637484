/**
 * Created by Denis Kuzin on 16 february 2022
 */

var ClansTopRow = ClansRow.extend({
    ctor: function (row, options) {
        this._super(row, options);

        cleverapps.UI.onClick(this, function () {
            new ClanInformationWindow(row.data.clan);
        }, {
            interactiveScale: false
        });
    },

    createPlace: function () {
        var styles = cleverapps.styles.ClansTopRow;
        var place = this.row.data.place;

        var placeNode = new cc.Node();
        placeNode.setAnchorPoint(0.5, 0.5);

        var content;
        var badgeIcon = bundles.table.frames["badge_" + place];
        if (badgeIcon) {
            content = new cc.Sprite(badgeIcon);
        } else {
            content = this.createPlaceText(place);
        }

        placeNode.setContentSize2(styles.place.width, cleverapps.styles.ClansRow.content.height);

        placeNode.addChild(content);
        content.setPositionRound(placeNode.width / 2, placeNode.height / 2);

        return placeNode;
    },

    createPlaceText: function (place) {
        var styles = cleverapps.styles.ClansTopRow;

        var font = cleverapps.styles.FONTS.CLANS_ROW_PLACE_TEXT;
        var text = cleverapps.UI.generateImageText(place, font);

        var backgroundFrame = this.row.isPlayer() ? bundles.clan_table.frames.clan_amount_bg_you_png : bundles.clan_table.frames.clan_amount_bg_png;

        if (!styles.place.background || !backgroundFrame) {
            return text;
        }

        var background = new cc.Scale9Sprite(backgroundFrame);
        background.setContentSize2(styles.place.background);

        text.setPositionRound(background.width / 2, background.height / 2);
        background.addChild(text);

        return background;
    },

    generateAmount: function (frame, amount, width) {
        var styles = cleverapps.styles.ClansTopRow;

        var icon = new cc.Sprite(bundles.tablerow_icons.frames[frame]);
        icon.setScale(0.9);

        var amountText = cleverapps.UI.generateImageText(amount, cleverapps.styles.FONTS.CLANS_ROW_SCORE_TEXT);
        amountText.setAnchorPoint(0, 0.5);
        amountText.fitTo(width);

        var amountWrap = new cc.Node();
        amountWrap.setAnchorPoint(0.5, 0.5);
        amountWrap.setContentSize2(width, amountText.height);
        amountWrap.addChild(amountText);
        amountText.setPositionRound(0, amountText.height / 2);

        return new cleverapps.Layout([icon, amountWrap], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.iconMargin
        });
    },

    createScore: function () {
        var styles = cleverapps.styles.ClansTopRow.score;

        var items = [];
        items.push(this.generateAmount("alliances_people", this.row.data.clan.membersCount, styles.text.membersWidth));
        items.push(this.generateAmount("alliances_crown_gold", this.row.data.clan.rating, styles.text.ratingWidth));

        var content = new cleverapps.Layout(items, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });
        content.setPositionRound(content.width / 2, content.height / 2);

        var dataBg = new cc.Scale9Sprite(this.row.isPlayer() ? bundles.clan_table.frames.clan_amount_bg_you_png : bundles.clan_table.frames.clan_amount_bg_png);
        dataBg.setContentSize2(content.width, content.height);
        dataBg.addChild(content);

        var wrap = new cc.Node();
        wrap.setAnchorPoint(0.5, 0.5);
        wrap.setContentSize(dataBg.width + styles.offsetRight, dataBg.height);
        wrap.addChild(dataBg);
        dataBg.setPositionRound(wrap.width / 2 - styles.offsetRight / 2, wrap.height / 2);

        return wrap;
    }
});

cleverapps.styles.ClansTopRow = {
    place: {
        width: 100
    },

    score: {
        iconMargin: 4,
        margin: 10,
        offsetRight: 0,

        padding: {
            y: 5,
            x: 15
        },

        text: {
            membersWidth: 65,
            ratingWidth: 120
        }
    }
};