/**
 * Created by ivan on 11.04.18.
 */

var UnitsLibraryToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.UNITS_LIBRARY);

    this.enable();
};

UnitsLibraryToolbarItem.prototype = Object.create(ToolbarItem.prototype);
UnitsLibraryToolbarItem.prototype.constructor = UnitsLibraryToolbarItem;

UnitsLibraryToolbarItem.prototype.resetState = function () {
    if (cleverapps.unitsLibrary.hasAnyFresh(cleverapps.unitsLibrary.listTabCodes(cleverapps.meta.selectedLocationId()))) {
        this.mark();
    } else {
        this.unmark();
    }
};

UnitsLibraryToolbarItem.prototype.isVisible = function () {
    return Merge.currentMerge && (!Merge.currentMerge.isMainGame() || cleverapps.gameLevel.getLevel() >= 3);
};

UnitsLibraryToolbarItem.prototype.onClick = function () {
    cleverapps.focusManager.display({
        control: ["MenuBarCoinsItem"],
        focus: "UnitsLibraryIconClicked",
        action: function (f) {
            new UnitsLibraryWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }
    });
};