/**
 * Created by evgenijsenkevic on 17/2/25
 */

var FriendBalloonTutorial = function () {
    return new TutorialScenario(FriendBalloonTutorial.NAME, {
        listSteps: FriendBalloonTutorial.listSteps,
        isAvailable: function () {
            return !cleverapps.forces.isShown(Forces.FRIEND_BALLOON.id)
                && Merge.currentMerge && Merge.currentMerge.friendPixelsPlanner.isAvailable() && Map2d.currentMap.countEmptySlots() > 0;
        }
    });
};

FriendBalloonTutorial.listSteps = function () {
    var pixel, prizeUnit;

    var steps = [{
        onStart: function () {
            pixel = Merge.currentMerge.friendPixelsPlanner.createTutorialPixel();
            pixel.setFlying(false);

            Map2d.currentMap.focusOnTarget(pixel.getView(), {
                allowScrollWithFocus: true,
                callback: function () {
                    this.finish();
                }.bind(this)
            });
        },

        onFinish: function () {
            Merge.currentMerge.counter.trigger();
        }
    }, {
        onStart: function () {
            this.force = cleverapps.forces.create(pixel.getView(), Forces.FRIEND_BALLOON);

            cleverapps.eventBus.on("mapEvent", function (type, options) {
                if (type === Map2d.SPAWN && Unit.Equals(options.unit, PixelsSwarmConfig[PixelsSwarm.FRIEND].reward[0].units[0])) {
                    prizeUnit = options.unit;
                    this.finish();
                }
            }.bind(this), this);
        },

        onFinish: function () {
            Merge.currentMerge.counter.trigger();
        }
    }, {
        onStart: function () {
            Map2d.currentMap.focusOnUnit(prizeUnit, {
                allowScrollWithFocus: true,
                callback: function () {
                    this.finish();
                }.bind(this)
            });
        },

        onFinish: function () {
            Merge.currentMerge.counter.trigger();
        }
    }, {
        onStart: function () {
            cleverapps.focusManager.showControlsWhileFocused("toolbar");

            var friendBalloonItem = cleverapps.toolbar.getItem(ToolbarItem.FRIENDS_LIST_REQUESTS.type);

            this.force = cleverapps.forces.create(friendBalloonItem.onGetView(), Forces.FRIEND_BALLOON_SEND_GIFT, {
                importantNodes: [cleverapps.scenes.getRunningScene().downToolBarControl],
                styles: { shadow: false }
            });
            cleverapps.tutorial.on("send_gift_for_friends", this.finish.bind(this), this);
        },

        onFinish: function () {
            Merge.currentMerge.counter.trigger();
        }
    }];

    return steps.map(function (step) {
        return new TutorialStep(step);
    });
};

FriendBalloonTutorial.NAME = "friendballoontutorial";