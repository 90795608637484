/**
 * Created by spepa on 24.10.2022
 */

var LandmarkDonor = function (unit) {
    UnitComponent.call(this, unit);
};

LandmarkDonor.prototype = Object.create(UnitComponent.prototype);
LandmarkDonor.prototype.constructor = LandmarkDonor;

LandmarkDonor.prototype.handleClick = function () {
    var landmark = Merge.currentMerge.landmarks.findDonorLandmark(this.unit);
    if (landmark && cleverapps.unitsLibrary.isOpened(landmark)) {
        var data = this.unit.getData();
        if (data.landmarkDonor.finishHint) {
            cleverapps.centerHint.createTextHint(data.landmarkDonor.finishHint);
            this.unit.squeeze();
            return true;
        }
    }
};

LandmarkDonor.CreateCinematic = function (unit) {
    var component = unit.findComponent(LandmarkDonor);
    if (!component) {
        return;
    }

    var landmark = Merge.currentMerge.landmarks.findDonorLandmark(unit);

    if (landmark && landmark.feature && unit.stage === 0) {
        return [
            function (f) {
                new GuideWindow(Merge.currentMerge.landmarks.getGuideWindowOptions(landmark));
                cleverapps.focusManager.onceNoWindowsListener = f;
            },
            function (f) {
                new LandMarkDonorWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        ];
    }

    return function (f) {
        Merge.currentMerge.landmarks.increasePendingProgress(unit);

        if (landmark && Merge.currentMerge.landmarks.canGiveLandmark(landmark)) {
            new LandMarkDonorWindow(landmark);
            cleverapps.focusManager.onceNoWindowsListener = f;
            return;
        }

        f();
    };
};
