/**
 * Created by spepa on 24.10.2022
 */

var LandmarkDonorIcon = function () {
    SideBarIcon.call(this, {
        animation: bundles.sidebar.jsons.landmarkdonor_icon_json,
        resetOnFreeFocus: true
    });

    this.resetState();
};

LandmarkDonorIcon.prototype = Object.create(SideBarIcon.prototype);
LandmarkDonorIcon.prototype.constructor = LandmarkDonorIcon;

LandmarkDonorIcon.prototype.resetState = function () {
    this.available = false;

    if (!cleverapps.environment.isMainScene()) {
        return;
    }

    var landmark = this.findWithProgress();
    if (landmark) {
        this.available = true;
        this.setAttention(Merge.currentMerge.landmarks.getPendingProgress(landmark));
        this.setLeftTime(Merge.currentMerge.landmarks.getTimeLeft(landmark));
    }
};

LandmarkDonorIcon.prototype.findWithProgress = function () {
    var landmarks = Merge.currentMerge && Merge.currentMerge.landmarks && Merge.currentMerge.landmarks.listActiveLandmarks() || [];

    for (var i = 0; i < landmarks.length; ++i) {
        var landmark = landmarks[i];

        if (Merge.currentMerge.landmarks.getPendingProgress(landmark)) {
            return landmark;
        }
    }

    return landmarks[0];
};

LandmarkDonorIcon.prototype.onPressed = function () {
    cleverapps.focusManager.display({
        focus: "LandmarkDonorIconClicked",
        action: function (f) {
            new LandMarkDonorWindow(this.findWithProgress());
            cleverapps.focusManager.onceNoWindowsListener = f;
        }.bind(this)
    });
};

LandmarkDonorIcon.prototype.getForce = function () {
    return Landmarks.getForce(cleverapps.meta.selectedLocationId());
};
