/**
 * Created by andrey on 14.01.2021.
 */

var MergeTutorial = function () {
    cleverapps.EventEmitter.call(this);

    this.fresh = [];

    cleverapps.eventBus.on("mapEvent", this.onMapEvent.bind(this), this);
    cleverapps.windows.on("openWindow", this.onOpenWindow.bind(this), this);
    cleverapps.windows.on("showUpWindow", this.onWindowShown.bind(this), this);
    cleverapps.windows.on("closeWindows", this.onCloseWindows.bind(this), this);

    this.onAfterResize = function () {};
};

MergeTutorial.prototype = Object.create(cleverapps.EventEmitter.prototype);
MergeTutorial.prototype.constructor = MergeTutorial;

MergeTutorial.prototype.isActive = function () {
    return this.runningTutorial;
};

MergeTutorial.prototype.showTutorial = function (tutorial, callback) {
    if (cleverapps.gameModes.skipTutorials) {
        callback();
        return;
    }

    var steps = tutorial.steps;

    if (tutorial.replayed) {
        steps = this.filterReplayedSteps(steps);
    }

    var actions = steps.map(function (step, index) {
        return function (f, stop) {
            if (!this.finished) {
                this.showTutorialStep(step, index, f);
            } else {
                stop();
            }
        }.bind(this);
    }.bind(this));

    if (cleverapps.focusManager.focus === "mergeTutorial") {
        cleverapps.throwAsync("Trying to show a tutorial when another tutorial is already running, current: " + this.runningTutorialId + ", step: " + this.runningIndex + ", new: " + tutorial.id);

        callback();

        return;
    }

    cleverapps.focusManager.display({
        stack: true,
        focus: "mergeTutorial",
        actions: [
            function (f) {
                this.finished = false;
                this.runningTutorial = steps;
                this.runningTutorialId = tutorial.id;
                Merge.currentMerge.advice.stopHint();
                InfoView.CloseInfo(undefined, true);
                f();
            }.bind(this),

            function (f) {
                cleverapps.focusManager.setEventNodes([cleverapps.scenes.getRunningScene()]);

                var player = new ActionPlayer(actions);
                player.onComplete(f);
                player.play();
            },

            function (f) {
                cleverapps.focusManager.hideControlsWhileFocused("map2d");

                delete this.runningTutorial;
                delete this.runningTutorialId;
                delete this.finished;

                this.trigger("finishTutorial");

                f();
                callback();
            }.bind(this)
        ]
    });
};

MergeTutorial.prototype.filterReplayedSteps = function (steps) {
    var advice = Merge.currentMerge.advice;

    for (var i = steps.length - 1; i >= 0; --i) {
        var step = steps[i];
        advice.setLogic(step.type, step);
        if (advice.hasHints() && (!step.targets || advice.findMove())) {
            return steps.slice(i);
        }
    }
    return steps;
};

MergeTutorial.prototype.showTutorialStep = function (step, index, f) {
    var type = MergeTutorial.Types[step.type] || {};

    this.step = step = cleverapps.clone(Object.assign({}, type, step), true);
    if (step.targets) {
        step.targets = cleverapps.toArray(step.targets).map(function (target) {
            if (target.recipes) {
                var unit = Map2d.currentMap.customers.listCustomers().find(function (customer) {
                    return customer.recipe && target.recipes.indexOf(customer.recipe.code) !== -1;
                }).unit;
                return { code: unit.code, stage: unit.stage };
            }
            return target;
        });
    }

    this.runningIndex = index;
    this.onceStepCallback = cleverapps.once(f);

    this.onceFinishTutorialStep = cleverapps.once(this.finishTutorialStep.bind(this));

    var visibleControls = (type.controls || ["map2d"]).concat(step.controls || []);
    var hiddenControls = cleverapps.focusManager.controls.filter(function (control) {
        return visibleControls.indexOf(control) === -1;
    });
    cleverapps.focusManager.hideControlsWhileFocused(hiddenControls);
    cleverapps.focusManager.showControlsWhileFocused(visibleControls);

    var merge = Merge.currentMerge;
    merge.advice.setLogic(step.type, step);

    var waitMove = step.wait || MergeTutorial.WAIT_MOVE;

    var callback = cleverapps.once(function () {
        this.clearFinishAllTimeout();

        var move = merge.advice.findMove();
        if (merge.advice.hasHints() && (!step.targets || move)) {
            this.trigger("showTutorialStep", step, move);
        } else if (step.type === Map2d.FORCE) {
            var force = cleverapps.clone(step.force, true);
            force.text = step.text;
            force.person = typeof step.person === "function" && step.targets ? step.person(step.targets[0]) : step.person;

            cleverapps.forces.onceForceClosed = this.runOnceFinishTutorialStep.bind(this);
            cleverapps.forces.create(undefined, force);
        } else if (step.type === Map2d.CLOSE_WINDOWS) {
            cleverapps.windows.onceTutorialNoWindowsListener = this.runOnceFinishTutorialStep.bind(this);
            this.trigger("showTutorialStep", step, {});
        } else {
            this.runOnceFinishTutorialStep();
        }
    }.bind(this));

    if (merge.advice.hasHints() && (step.wait || (!step.targets || merge.advice.findMove()))) {
        this.finishAllTimeout = setTimeout(this.finishAll.bind(this), cleverapps.parseInterval(waitMove));
        this.onShowHintCallback = callback;
        this.launchHint();
    } else {
        callback();
    }
};

MergeTutorial.prototype.launchHint = function () {
    Merge.currentMerge.advice.startHint({
        arrow: this.step.arrow || { delay: 10 },
        highlight: this.step.highlight,
        finger: {
            delay: 5,
            repeatDelay: 2
        }
    });
};

MergeTutorial.prototype.onOpenWindow = function () {
    if ([Map2d.BUY_FREE_UNIT, Map2d.ENTER_UNITS_SHOP].includes(this.getCurrentStepType()) && cleverapps.windows.currentWindow() instanceof UnitsShopWindow) {
        this.step.unitsShopWindowOpened = true;
    }
};

MergeTutorial.prototype.onWindowShown = function () {
    this.trigger("showUpWindow");
};

MergeTutorial.prototype.onCloseWindows = function () {
    if ([Map2d.BUY_FREE_UNIT, Map2d.ENTER_UNITS_SHOP].includes(this.getCurrentStepType()) && this.step.unitsShopWindowOpened) {
        this.finishAll();
    }
    this.trigger("closeWindows");
};

MergeTutorial.prototype.onShowHint = function () {
    if (this.isActive() && this.onShowHintCallback) {
        this.onShowHintCallback();
    }
};

MergeTutorial.prototype.onMapEvent = function (type, options) {
    if (!this.isActive() || this.stepFinished || !this.step) {
        return;
    }

    var types = this.step.types || [this.step.type];
    if (types.indexOf(type) === -1) {
        return;
    }

    if (this.step.allMoves && Merge.currentMerge.advice.findMove()) {
        return;
    }

    if (this.step.affected && options.affected && !this.checkTargets(options.affected)) {
        return;
    }

    this.wantNextStep();
};

MergeTutorial.prototype.wantNextStep = function () {
    this.stepFinished = true;

    if (!Merge.currentMerge.counter.isActive()) {
        this.runOnceFinishTutorialStep();
    } else {
        this.readyForNextStep = true;
    }
};

MergeTutorial.prototype.nextStep = function () {
    if (this.readyForNextStep) {
        this.runOnceFinishTutorialStep();
    }

    this.readyForNextStep = false;
};

MergeTutorial.prototype.triggerBuildTutorial = function (options) {
    if (!options.unit || cleverapps.unitsLibrary.isOpened(options.unit) || this.isActive()) {
        return;
    }

    if (options.unit.code === "wood" && options.unit.stage === 2 && !Map2d.currentMap.workers.findAssigned(options.unit)) {
        this.triggerTutorial(MergeTutorials.build_wood_2);
    }
};

MergeTutorial.prototype.triggerTutorial = function (tutorial) {
    if (tutorial && this.runningTutorial !== tutorial && this.fresh.indexOf(tutorial) === -1) {
        this.fresh.push(tutorial);
        Merge.currentMerge.counter.trigger();
    }
};

MergeTutorial.prototype.processFresh = function () {
    if (!this.fresh.length || cleverapps.focusManager.isFocused() || Map2d.currentMap.dragging) {
        return;
    }

    var tutorial = this.fresh.shift();

    cleverapps.focusManager.display({
        focus: "StartTutorial",
        action: function (f) {
            this.showTutorial(tutorial, f);
        }.bind(this)
    });
};

MergeTutorial.prototype.destructor = function () {
    this.clearFinishAllTimeout();
    runCleaners(this);
};

MergeTutorial.prototype.clearFinishAllTimeout = function () {
    if (this.finishAllTimeout) {
        clearTimeout(this.finishAllTimeout);
        delete this.finishAllTimeout;
    }
};

MergeTutorial.prototype.runOnceFinishTutorialStep = function () {
    if (this.onceFinishTutorialStep) {
        var callback = this.onceFinishTutorialStep;
        delete this.onceFinishTutorialStep;
        callback();
    }
};

MergeTutorial.prototype.finishAll = function () {
    this.finished = true;
    this.runOnceFinishTutorialStep();
};

MergeTutorial.prototype.isFirstStep = function () {
    return this.runningIndex === 0;
};

MergeTutorial.prototype.isLastStep = function () {
    return this.runningIndex === this.runningTutorial.length - 1;
};

MergeTutorial.prototype.getCurrentStepType = function () {
    return this.step && this.step.type;
};

MergeTutorial.prototype.finishTutorialStep = function () {
    Merge.currentMerge.advice.stopHint();

    this.logTutorialStep();

    if (this.step.waitAfter) {
        Merge.currentMerge.counter.setTimeout(this.runNextStep.bind(this), this.step.waitAfter);
        return;
    }

    if (this.finished || this.isLastStep()) {
        this.runNextStep();
    } else {
        Merge.currentMerge.counter.setTimeout(this.runNextStep.bind(this), 300);
    }
};

MergeTutorial.prototype.logTutorialStep = function () {
    if (!MergeTutorial.LOG_TUTORIALS.includes(this.runningTutorialId)) {
        return;
    }

    var step = this.runningIndex;
    for (var i = 0; i < MergeTutorial.LOG_TUTORIALS.length; i++) {
        var id = MergeTutorial.LOG_TUTORIALS[i];

        if (id === this.runningTutorialId) {
            break;
        }

        step += MergeTutorials[id].steps.length;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.TUTORIAL_STEP + step);
};

MergeTutorial.prototype.runNextStep = function () {
    delete this.step;
    delete this.stepFinished;
    delete this.runningIndex;
    delete this.onShowHintCallback;

    this.trigger("finishTutorialStep");

    this.clearFinishAllTimeout();

    if (this.onceStepCallback) {
        var onceStepCallback = this.onceStepCallback;
        delete this.onceStepCallback;
        onceStepCallback();
    }
};

MergeTutorial.prototype.checkTargets = function (units) {
    return this.isActive() && this.step && this.step.targets
        && cleverapps.intersect(this.step.targets, units, Unit.GetKey).length;
};

MergeTutorial.prototype.checkPreferCell = function (unit) {
    return this.step
    && this.step.preferCell
    && (unit.x === this.step.preferCell.x && unit.y === this.step.preferCell.y);
};

MergeTutorial.prototype.disableHandleClick = function (unit) {
    return this.isActive() && (!this.checkTargets(unit) && !this.checkPreferCell(unit));
};

MergeTutorial.prototype.checkTypes = function (types) {
    types = cleverapps.toArray(types);
    return this.isActive() && types.includes(this.getCurrentStepType());
};

MergeTutorial.prototype.isInfoAvailable = function (unit) {
    if (!this.isActive()) {
        return true;
    }

    var move = Merge.currentMerge.advice.findMove();

    return move && move.cells[0].x === unit.x && move.cells[0].y === unit.y;
};

MergeTutorial.prototype.afterResize = function () {
    if (this.finished === false) {
        this.onAfterResize();
    }
};

MergeTutorial.prototype.getTargetCells = function () {
    if (!this.step || !this.step.region) {
        return [];
    }
    var region = Map2d.currentMap.regions[this.step.region];
    return (region && region.positions) || [];
};

MergeTutorial.prototype.getId = function () {
    return this.runningTutorialId;
};

MergeTutorial.prototype.getStepTargets = function () {
    if (this.step) {
        return this.step.targets;
    }
};

MergeTutorial.WAIT_MOVE = "10 seconds";

MergeTutorial.LOG_TUTORIALS = [
    "dwarf",
    "wheat_3",
    "story_wheat_3",
    "greeting_wands_0",
    "wood_1",
    "wood_2"
];
