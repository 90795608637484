/**
 * Created by r4zi4l on 17.09.2021
 */

var OrdersWindow = CleverappsWindow.extend({
    ctor: function (options) {
        this.orders = new Orders(options);
        this._super.apply(this, arguments);
    },

    onWindowLoaded: function () {
        var styles = cleverapps.styles.OrdersWindow;

        this._super({
            name: "OrdersWindow",
            title: cleverapps.config.ui === "heroes" ? undefined : "OrdersWindow.title",
            noPadding: styles.noPadding,
            closeButton: true,
            foreground: cleverapps.config.ui === "riddles" && bundles.windows.frames.window_foreground_png,
            content: this.createContent(),
            noBorder: ["mergecraft", "hustlemerge"].includes(cleverapps.config.name),
            styles: styles
        });

        this.tabs.activateTab(0);

        this.orders.on("orderClaimed", this.onOrderClaimed.bind(this), this);
        this.orders.on("makerSelected", this.updatePerson.bind(this), this);
    },

    onShow: function () {
        this.orders.scrollToSelectedOrderByMaker();
    },

    getPerson: function () {
        return ["wooden", "heroes"].indexOf(cleverapps.config.ui) !== -1 && (this.orders.getCurrentMaker() && this.orders.getCurrentMaker().unit.code || "dwarf");
    },

    getPersonBundles: function () {
        var heroBundles = [];
        cleverapps.unitsLibrary.listAvailableHeroes().forEach(function (hero) {
            var person = cleverapps.persons.getRole(hero.code);
            if (person && person.bundle) {
                heroBundles.push(person.bundle);
            }
        });
        return heroBundles;
    },

    createTabs: function () {
        return {
            0: {
                icon: bundles.windows.frames.tab_icon_orders,
                generator: this.createTab.bind(this, OrdersTab)
            },
            1: {
                icon: bundles.windows.frames.tab_icon_ingredients,
                generator: this.createTab.bind(this, IngredientsTab)
            }
        };
    },

    createTab: function (Tab) {
        var content = new Tab(this.orders);
        this[Tab] = content;
        this.content.addChild(content);
        return content;
    },

    createContent: function () {
        var styles = cleverapps.styles.OrdersWindow;

        var content = this.content = new cc.Node();
        content.setAnchorPoint(0.5, 0.5);
        content.setContentSize2(styles);

        if (bundles.orderswindow.frames.ow_line) {
            for (var i = 0; i < 12; ++i) {
                var line = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.ow_line, cleverapps.UI.Scale9Rect.TwoPixelXY);
                line.setContentSize2(content.width - styles.lines.padding.x * 2, line.height);
                line.setPositionRound(content.width / 2, content.height / 14 * (i + 2));
                content.addChild(line);
            }
        }

        var tabs = this.tabs = new Tabs(this.createTabs(), styles.tabs);
        tabs.setAnchorPoint(0.5, 0.5);
        tabs.setPositionRound(styles.tabs);
        if (cleverapps.config.ui === "heroes") {
            tabs.setLocalZOrder(1);
        }
        content.addChild(tabs);

        if (bundles.orderswindow.frames.ow_roof) {
            var roof = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.ow_roof, cleverapps.UI.Scale9Rect.TwoPixelXY);
            roof.setContentSize2(styles.roof.width, roof.height);
            roof.setPositionRound(styles.roof);
            content.addChild(roof);

            if (bundles.orderswindow.frames.ow_roof_title) {
                var roofTitle = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.ow_roof_title, cleverapps.UI.Scale9Rect.TwoPixelXY);
                roofTitle.setContentSize2(styles.roof.title.width, roofTitle.height);
                roofTitle.setPositionRound(styles.roof.title);
                roof.addChild(roofTitle);

                var roofTitleText = cleverapps.UI.generateOnlyText("OrdersWindow.title", cleverapps.styles.FONTS.ORDERS_WINDOW_TITLE);
                roofTitleText.setAnchorPoint(0.5, 0.5);
                roofTitleText.setPositionRound(roofTitle.width / 2, roofTitle.height / 2);
                roofTitleText.fitTo(0.7 * roofTitle.width, roofTitle.height);
                roofTitle.addChild(roofTitleText);
            }

            var width = new cc.Sprite(bundles.orderswindow.frames.ow_marquise1).width;
            for (var x = styles.roof.marquise.x, j = 0; j < 14; ++j, x += width) {
                if (cleverapps.config.ui === "heroes" && j === 13) {
                    continue;
                }
                var marquise = new cc.Sprite(j % 2 === 0 ? bundles.orderswindow.frames.ow_marquise1 : bundles.orderswindow.frames.ow_marquise2);
                marquise.setAnchorPoint(0, 1);
                marquise.setPositionRound(x, styles.roof.marquise.y);
                roof.addChild(marquise);
            }
        }

        return content;
    },

    updatePerson: function (maker) {
        if (this.persons) {
            this.persons.setLeftPerson(maker.unit.code || "dwarf");
        }
    },

    onOrderClaimed: function (maker) {
        this.readyMaker = maker.unit;
        this.close();
    },

    beforeCloseAnimation: function (callback) {
        if (!this.readyMaker) {
            callback();
            return;
        }

        this.hideSelf();
        Map2d.currentMap.focusOnUnit(this.readyMaker, {
            allowScrollWithFocus: true,
            delay: 1,
            action: function () {
                this.readyMaker.handleClick();
            }.bind(this),
            callback: callback
        });
    },

    listBundles: function () {
        return ["main"];
    },

    close: function () {
        this._super();
        this.orders.destructor();
    }
});

cleverapps.styles.OrdersWindow = {
    width: 1280,
    height: 880,

    noPadding: true,

    roof: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 0 },
        width: 1428,

        marquise: {
            x: 25,
            y: 108
        }
    },

    lines: {
        padding: {
            x: 35
        }
    },

    tabs: {
        x: { align: "left", dx: 40 },
        y: { align: "top", dy: 90 },
        direction: cleverapps.UI.HORIZONTAL,
        width: 160,
        height: 100
    }
};
