/**
 * Created by andrey on 22.01.2021.
 */

var MergeAdviceView = function (advice, scene) {
    this.advice = advice;

    this.advice.on("showHint", this.showHint.bind(this), scene);
    this.advice.on("hideHint", this.hideHint.bind(this), scene);
};

MergeAdviceView.prototype.findAdviceTarget = function (parent, targets) {
    targets = cleverapps.toArray(targets);

    var children = cleverapps.toArray(parent);
    while (children.length > 0) {
        var node = children.shift();

        if (node.adviceTarget && targets.some(function (target) {
            return target === node.adviceTarget;
        })) {
            return node;
        }

        children = children.concat(node.children);

        if (node.additionalViews) {
            Object.keys(node.additionalViews).forEach(function (type) {
                children.push(node.additionalViews[type]);
            });
        }
    }
};

MergeAdviceView.prototype.parseMove = function (move) {
    var params = {};
    var node;

    if (move.window) {
        var window = cleverapps.windows.currentWindow();
        if (window) {
            node = this.findAdviceTarget(window, move.window);
            if (!node) {
                return params;
            }
        }
    }

    if (move.fogView) {
        var fog = Map2d.currentMap.getFog(move.cells[0].x, move.cells[0].y);
        var fogView = fog && fog.fogBlock.onGetView();
        if (fogView) {
            node = this.findAdviceTarget(fogView, move.fogView);
        }
    }

    if (move.unitView) {
        var units = Map2d.currentMap.getMapView().listTiles(Map2d.LAYER_UNITS, move.cells[0].x, move.cells[0].y);
        if (units) {
            node = this.findAdviceTarget(units, move.unitView);
        }
    }

    if (node) {
        if (move.resources) {
            params.finger = { dragTargets: [node].concat(move.resources), getPayload: node.createAdviceIcon && node.createAdviceIcon.bind(node) };
        } else {
            params.finger = { clickTarget: node };
        }
        return params;
    }

    if (move.arrow) {
        params.arrow = { cell: move.arrow };
    }
    if (move.fingerDrag) {
        params.finger = { dragTargets: move.fingerDrag };
    }
    if (move.highlight) {
        params.highlight = { cells: move.highlight };
    } else if (move.mergeCluster) {
        params.highlightMerge = { cells: move.mergeCluster };
    }
    if (move.outline) {
        params.outline = { cell: move.outline };
    }

    return params;
};

MergeAdviceView.prototype.showHint = function (move, options) {
    options = options || {};
    var moveParams = this.parseMove(move);

    this.showArrow(moveParams, options.arrow);
    this.showFinger(moveParams, options.finger);
    if (options.highlight !== false) {
        this.showHighlight(moveParams);
    }
    this.showHighlightMerge(moveParams);
    this.showOutline(moveParams);
};

MergeAdviceView.prototype.showArrow = function (moveParams, options) {
    options = options || {};
    this.removeArrow();
    if (moveParams.arrow) {
        moveParams.arrow.delay = options.delay;
        this.createArrow(moveParams.arrow);
    }
};

MergeAdviceView.prototype.showFinger = function (moveParams, options) {
    options = options || {};
    FingerView.remove(this.hintFinger);
    delete this.hintFinger;
    if (!moveParams.finger) {
        return;
    }

    if (moveParams.finger.clickTarget) {
        this.hintFinger = FingerView.hintTap(moveParams.finger.clickTarget, { repeatDelay: options.repeatDelay });
    }
    if (moveParams.finger.dragTargets) {
        if (moveParams.finger.getPayload || moveParams.finger.dragTargets.length > 2) {
            this.hintFinger = FingerView.hintDrag(moveParams.finger.dragTargets, {
                getPayload: moveParams.finger.getPayload,
                repeatDelay: options.repeatDelay,
                delay: options.delay
            });
        } else if (moveParams.finger.dragTargets.length === 2) {
            this.hintFinger = FingerView.hintSwipe(
                moveParams.finger.dragTargets[0],
                moveParams.finger.dragTargets[1],
                {
                    repeatDelay: options.repeatDelay,
                    delay: options.delay
                }
            );
        } else if (moveParams.finger.dragTargets.length === 1) {
            this.hintFinger = FingerView.hintTap(
                moveParams.finger.dragTargets[0],
                {
                    repeatDelay: options.repeatDelay,
                    delay: options.delay
                }
            );
        }
    }
};

MergeAdviceView.prototype.showHighlight = function (moveParams) {
    if (this.highlightUnits) {
        this.highlightUnits.forEach(function (unit) {
            unit.unhighlight();
        });
        delete this.highlightUnits;
    }

    if (moveParams.highlight) {
        this.highlightUnits = moveParams.highlight.cells.map(function (cell) {
            return Map2d.currentMap.getUnit(cell.x, cell.y);
        }).filter(function (unit) {
            if (unit) {
                unit.highlight();
                return true;
            }
            return false;
        });
    }
};

MergeAdviceView.prototype.showHighlightMerge = function (moveParams) {
    if (this.mergeHintUnit) {
        this.mergeHintUnit.unhighlightMerge();
        delete this.mergeHintUnit;
    }

    if (moveParams.highlightMerge) {
        var initiator = moveParams.highlightMerge.cells[0];
        var unit = this.mergeHintUnit = Map2d.currentMap.getUnit(initiator.x, initiator.y);
        unit.highlightMerge([moveParams.highlightMerge.cells]);
    }
};

MergeAdviceView.prototype.createArrow = function (params) {
    var cell = params.cell;
    var styles = cleverapps.styles.MergeAdviceView.straightArrow;

    var unit = Map2d.currentMap.getUnit(cell.x, cell.y);
    var unitView = unit && unit.onGetView();
    if (unit && unit.prizes || unitView && unitView.getAdditionalView("ingredients")) {
        styles = cleverapps.styles.MergeAdviceView.skewArrow;
    }

    var arrow = this.arrow = new cc.Sprite(bundles.merge_animations.frames.tutorial_arrow);
    Map2d.currentMap.getMapView().animations.addChild(arrow);

    var pos = Map2d.currentMap.getMapView().getUnitCenterPos(cell.x, cell.y);
    arrow.setPosition(pos.x + styles.offsetX, pos.y + styles.offsetY);
    arrow.setRotation(styles.rotation);

    var launch = function () {
        arrow.runAction(new cc.RepeatForever(
            new cc.Sequence(
                new cc.MoveBy(0.5, -styles.deltaX, styles.deltaY).easing(cc.easeInOut(1.7)),
                new cc.MoveBy(0.5, styles.deltaX, -styles.deltaY).easing(cc.easeInOut(1.7))
            )
        ));
    };

    arrow.setVisible(false);
    arrow.runAction(new cc.Sequence(
        new cc.DelayTime(params.delay || 0),
        new cc.Show(),
        new cc.CallFunc(launch)
    ));
};

MergeAdviceView.prototype.showOutline = function (params) {
    this.removeOutline();

    if (params.outline) {
        var cell = params.outline.cell;
        var unit = Map2d.currentMap.getUnit(cell.x, cell.y);
        var unitView = unit && unit.onGetView();
        if (unitView) {
            this.outlineUnit = unit;
            unitView.outline();
        }
    }
};

MergeAdviceView.prototype.hideHint = function () {
    FingerView.remove(this.hintFinger);
    this.hintFinger = undefined;

    if (this.mergeHintUnit) {
        this.mergeHintUnit.unhighlightMerge();
        delete this.mergeHintUnit;
    }

    if (this.highlightUnits) {
        this.highlightUnits.forEach(function (unit) {
            unit.unhighlight();
        });
        delete this.highlightUnits;
    }

    this.removeArrow();
    this.removeOutline();
};

MergeAdviceView.prototype.removeArrow = function (silent) {
    if (this.arrow) {
        if (silent) {
            this.arrow.removeFromParent();
            this.arrow = undefined;
        } else {
            this.arrow.runAction(new cc.Sequence(
                new cc.FadeOut(0.2),
                new cc.RemoveSelf()
            ));
            this.arrow = undefined;
        }
    }
};

MergeAdviceView.prototype.removeOutline = function () {
    if (this.outlineUnit && this.outlineUnit.onGetView()) {
        this.outlineUnit.onGetView().outline(true);
    }
};

cleverapps.styles.MergeAdviceView = {
    straightArrow: {
        rotation: 0,
        offsetX: -5,
        offsetY: 120,
        deltaX: 0,
        deltaY: 60
    },

    skewArrow: {
        rotation: -60,
        offsetX: -110,
        offsetY: 70,
        deltaX: 50,
        deltaY: 29
    }
};
