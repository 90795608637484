/**
 * Created by andrey on 17.09.2024
 */

Map2d.unitsVisible = true;

Map2d.setUnitsVisible = function (unitsVisible) {
    Map2d.unitsVisible = unitsVisible;

    Map2d.updateMap();
};

Map2d.resetChessLayerVisible = function () {
    Map2d.chessLayerVisible = !!Map2dChessGroundView.IsEnabled();
};

Map2d.setChessLayerVisible = function (chessLayerVisible) {
    Map2d.chessLayerVisible = chessLayerVisible;

    Map2d.updateMap();
};

Map2d.starredChessLayerVisible = false;

Map2d.setStarredChessLayerVisible = function (starredChessLayerVisible) {
    Map2d.starredChessLayerVisible = starredChessLayerVisible;

    Map2d.updateMap();
};

Map2d.decoratorsVisible = true;

Map2d.setDecoratorsVisible = function (decoratorsVisible) {
    Map2d.decoratorsVisible = decoratorsVisible;

    Map2d.updateMap();
};

Map2d.updateMap = function () {
    var map2d = Map2d.currentMap;

    if (!map2d) {
        return;
    }

    var visitRect = map2d.visitRect;

    for (y = 0; y < map2d.getHeight(); y++) {
        for (x = 0; x < map2d.getWidth(); x++) {
            map2d.removeTiles(x, y);
        }
    }

    map2d.setVisitRect(cc.rect(0, 0, 1, 1));
    map2d.setVisitRect(visitRect);
};

Map2d.showLevels = function () {
    var map2d = Map2d.currentMap;
    var mapView = map2d.onDiscoverMapView();
    var movingNode = cleverapps.scenes.getMovingNode(mapView);
    if (mapView.levelsLables) {
        mapView.levelsLables.forEach(function (label) {
            label.removeFromParent();
        });
    }

    mapView.levelsLables = [];

    cc.iterateIsoRect(map2d.visibleBoxRect, function (x, y) {
        var level = map2d.calcGroundLevel(x, y);
        var tile = mapView.getCellLayerTile(Map2d.LAYER_GROUND, x, y);
        if (tile) {
            var label = cleverapps.UI.generateOnlyText(level, cleverapps.styles.FONTS.WHITE_TEXT);
            label.setPositionRound({ align: "center" }, { align: "center" });
            mapView.levelsLables.push(label);
            tile.addChild(label);
            label.replaceParentSamePlace(movingNode);
        }
    });
};
