/**
 * Created by Reda on 13.02.2025
 */

var FogLockView = cc.Node.extend({
    ctor: function (condition) {
        this._super();
        this.condition = condition;
        this.style = cleverapps.skins.getSlot("fogBlocker") || cleverapps.styles.FogLockView;
        this.setContentSize2(this.style);

        this.createLock();

        this.setCascadeColorEnabled(true);
        this.setCascadeOpacityEnabled(true);
    },

    createLock: function () {
        var lock = new cleverapps.Spine(bundles.fog.jsons.fog_blocker_json);
        lock.setAnimation(0, "idle", true);
        lock.setPositionRound(this.style.lock);
        this.addChild(lock);
        this.lock = lock;

        var iconSlot;
        var levelSlot;
        if (cleverapps.config.debugMode && cleverapps.config.name === "hustlemerge") {
            iconSlot = lock.getSlot("slot_icon");
            levelSlot = lock.getSlot("slot_level");
        }

        var frame = this.condition.hero && bundles.unit_icons.frames["small_icon_" + this.condition.hero];
        if (frame) {
            var heroIcon = new cc.Sprite(frame);
            if (iconSlot) {
                iconSlot.addChild(heroIcon);
            } else {
                heroIcon.setPositionRound(this.style.icon);
                this.addChild(heroIcon);
            }
        }

        if (this.condition.level) {
            var levelText = cleverapps.UI.generateImageText(this.condition.level, cleverapps.styles.FONTS.FOG_BLOCKER_TEXT);
            if (levelSlot) {
                levelSlot.addChild(levelText);
            } else {
                levelText.setPositionRound(this.style.text.x, this.style.text.y);
                levelText.fitTo(this.style.content.width, this.style.content.height);
                this.addChild(levelText);
            }
        }

        frame = this.condition.familyLastUnit && cleverapps.skins.getSlot("unitIcon", this.condition.familyLastUnit);
        if (frame) {
            var castle = new cc.Sprite(frame);
            if (iconSlot) {
                iconSlot.addChild(castle);
            } else {
                castle.setPositionRound(this.style.icon);
                this.addChild(castle);
            }
        }

        frame = this.condition.unit && cleverapps.skins.getSlot("unitIcon", this.condition.unit);
        if (frame) {
            var unit = new cc.Sprite(frame);
            if (iconSlot) {
                iconSlot.addChild(unit);
            } else {
                unit.setPositionRound(this.style.icon);
                this.addChild(unit);
            }
        }
    },

    checkCondition: function (condition) {
        var unitCompare = this.condition.unit && condition.unit ? this.condition.unit.code === condition.unit.code && this.condition.unit.stage === condition.unit.stage : this.condition.unit === condition.unit;
        var familyLastUnitCompare = this.condition.familyLastUnit && condition.familyLastUnit ? this.condition.familyLastUnit.code === condition.familyLastUnit.code && this.condition.familyLastUnit.stage === condition.familyLastUnit.stage : this.condition.familyLastUnit === condition.familyLastUnit;
        return unitCompare && familyLastUnitCompare
            && condition.level === this.condition.level;
    },

    openAnimation: function (newParent) {
        this.replaceParentSamePlace(newParent);
        this.runAction(new cc.Sequence(
            new cc.CallFunc(function () {
                this.lock.replaceParentSamePlace(newParent);
                this.lock.setLocalZOrder(-1);
                this.lock.setAnimation(0, "open", false);
                this.lock.setCompleteListenerRemove();
            }.bind(this)),
            new cc.Spawn(
                new cc.JumpBy(0.2, 30, 0, 20, 1),
                new cc.RotateBy(0.2, 80).easing(cc.easeIn(1))
            ),
            new cc.RemoveSelf()
        ));
    }
});

cleverapps.styles.FogLockView = {
    width: 100,
    height: 100,
    margin: 25,

    content: {
        width: 68,
        height: 68
    },
    text: {
        x: { align: "center", dx: 5 },
        y: { align: "center", dy: -28 }
    },
    icon: {
        x: { align: "center", dx: 5 },
        y: { align: "center", dy: -28 }
    },
    cloud: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -39 }
    },
    lock: {
        x: { align: "center" },
        y: { align: "center" }
    }
}