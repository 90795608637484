/**
 * Created by r4zi4l on 05.04.2024
 */

RewardTypes.wands = {
    controls: "MenuBarWandsItem",
    flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,

    getIcon: function () {
        return cleverapps.skins.getSlot("reward_wand_png") || bundles.reward_icons.frames.reward_wand_png;
    },

    getSmallIcon: function () {
        return cleverapps.skins.getSlot("reward_wand_small_png") || bundles.reward_icons.frames.reward_wand_small_png;
    },

    handler: function (value) {
        Merge.currentMerge.wands += value;
        Merge.currentMerge.storeSave();
        return function () {
            Map2d.currentMap.fogs.setWands(Merge.currentMerge.wands);
            Merge.currentMerge.onChangeWandsListener();
        };
    }
};

RewardTypes.worker = {
    controls: "MenuBarWorkersItem",

    getIcon: function () {
        return cleverapps.skins.getSlot("reward_worker_png") || bundles.reward_icons.frames.reward_worker_png;
    },

    getSmallIcon: function () {
        return bundles.reward_icons.frames.worker_small_png;
    },

    handler: function (value) {
        if (value.subscription) {
            Map2d.currentMap.workers.addSubscriptionWorker(true);
        } else {
            Map2d.currentMap.workers.addBonusWorkerPeriod(value.time, true);
        }

        return function () {
            Map2d.currentMap.workers.onChangeTotalAmount(value.amount);
        };
    }
};

RewardTypes.pawsPoints = {
    flyingAnimation: Reward.JUMP_COLLECT_ANIMATION,

    getIcon: function () {
        return cleverapps.skins.getSlot("paw_png") || bundles.reward_icons.frames.paw_png;
    },

    handler: function (value) {
        return function () {
            if (Merge.currentMerge.pawBox) {
                Merge.currentMerge.pawBox.addPoints(value);
            }
        };
    }
};

RewardTypes.unit = {
    parse: function (value, options) {
        return RewardTypes.units.parse(value, options);
    }
};

RewardTypes.units = {
    bundle: function () {
        return Families.listLazyBundles(this.value);
    },

    parse: function (value, options) {
        return cleverapps.toArray(value).map(function (unit) {
            return new Reward("units", unit, options);
        });
    },

    handler: function (value) {
        if (Families[value.code].type === "landmark") {
            Merge.currentMerge.landmarks.openLandmark(value);
            this.spawnedUnits = [];
            return function () {};
        }

        var centerCell = Map2d.currentMap.getScreenCenterCell();
        var units = [];

        for (var i = value.amount || 1; i > 0; --i) {
            units.push({
                code: value.code,
                stage: value.stage,
                pointsValue: value.pointsValue
            });
        }

        if (this.options.toMainWorld) {
            var pocket = new Pocket();
            pocket.addUnits(units);
            return function () {
                cleverapps.toolbar.resetByType(ToolbarItem.TRAVEL_BOOK);
            };
        }

        var spawnedUnits = [];

        units.forEach(function (unit) {
            var slot = Map2d.currentMap.findEmptySlot(centerCell.x, centerCell.y, unit);
            if (!slot) {
                Merge.currentMerge.pocket.addUnits(unit);
                return;
            }

            unit = new Unit(unit);
            unit.setPosition(slot.x, slot.y);
            Map2d.currentMap.add(Map2d.LAYER_UNITS, slot.x, slot.y, unit);
            spawnedUnits.push(unit);
        });

        if (!this.spawnedUnits) {
            this.spawnedUnits = [];
        }

        if (spawnedUnits.length) {
            this.spawnedUnits = this.spawnedUnits.concat(spawnedUnits);
        }

        return function () {};
    }
};
