/**
 * Created by andrey on 21.07.2023
 */

var Map2dBorderView = cc.Node.extend({
    ctor: function (borderTile) {
        this._super();

        if (Map2d.currentMap.calcGroundLevel(borderTile.x, borderTile.y)) {
            this.layer = Map2d.LAYER_GROUND;
        }

        borderTile.borders.forEach(function (border) {
            this.addChild(ViewReader.createTopYAnchorElement(border));
        }.bind(this));
    }
});